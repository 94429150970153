<template>
  <div>
    <a 
    @click="expandOnClick(terminals.id)"
    class="tran-card-link" data-toggle="collapse" :href="'#rpDetailCollapse'+index" role="button" aria-expanded="false" :aria-controls="'rpDetailCollapse'+index">
    <div class="tran-card mb-3">
        <div :class="expandCard == terminals.id ? 'tran-card-header active-bg-card' : 'tran-card-header'">
            <div>
                <div class="tran-title mb-2">${{amountNumberFormatter(parseFloat(terminals.total_amount).toFixed(2)) }}</div>
                <button @click="showModal(terminals.expected_clearance_date)" :class="expandCard == terminals.id ? 'tran-penidng-date-active mb-2' : 'tran-penidng-date mb-2'">
                  <svg style="margin-right: 5px;" width="20" fill="#149240" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                  viewBox="0 0 490 490" xml:space="preserve"><g><g><g><path d="M245,490C109.9,490,0,380.1,0,245S109.9,0,245,0s245,109.9,245,245S380.1,490,245,490z M245,62C144.1,62,62,144.1,62,245s82.1,183,183,183s183-82.1,183-183S345.9,62,245,62z"/></g><g><g><circle cx="241.3" cy="159.2" r="29.1"/></g><g><polygon points="285.1,359.9 270.4,359.9 219.6,359.9 204.9,359.9 204.9,321 219.6,321 219.6,254.8 205.1,254.8 205.1,215.9 
                  219.6,215.9 263.1,215.9 270.4,215.9 270.4,321 285.1,321"/></g></g></g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g>
                  </svg>
                  <span v-if="terminals.scheduled_posting_date != null">Expected to fully clear by: <span style="color: #149240;">{{ terminals.expected_clearance_date }}</span></span>
                  <span v-else>Prepayment Pending</span>
                </button>
                <div class="tran-store-name mb-0">{{ terminals.store_name }}</div>
            </div>
            <hr v-if="isRewardUsed(terminals.reward_point_used)" class="my-2">
            <div v-if="isRewardUsed(terminals.reward_point_used)" class="row justify-content-between align-items-center mx-0 tran-point-card">
              <div div class="title mb-2 d-flex align-items-center">
                <span>${{ parseFloat(terminals.consumer_bank_posting_amount).toFixed(2) }}</span>
              </div>
              <div class="value">Pending With Bank</div>
            </div>
            <div v-if="isRewardUsed(terminals.reward_point_used)" class="row justify-content-between align-items-center mx-0 tran-point-card">
                <div class="title mb-2 d-flex align-items-center">
                    <svg class="mr-2" width="30" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M58.0327 44.4525C60.969 38.2154 59.7673 32.2515 59.4272 30.6694C59.0417 28.8825 58.0667 24.66 54.5183 20.8699C52.8282 19.0889 50.821 17.6413 48.6004 16.6018C45.6075 15.2019 43.0567 14.9287 41.6622 14.7921C35.9144 14.223 31.1643 15.9075 28.5114 17.114C29.8031 15.8716 31.2794 14.8387 32.8875 14.0523C34.3086 13.3704 35.8149 12.8842 37.3656 12.6069C39.9046 12.1197 42.5076 12.0697 45.0633 12.4589C46.2423 12.641 51.1626 13.4946 56.0714 17.3188C63.225 22.8844 66.1613 30.6125 64.4947 39.5016C61.9553 53.1026 48.9632 61.0697 35.7897 57.5641C31.9125 56.5284 27.8312 54.0814 26.0173 51.6912C26.108 51.6912 26.2101 51.6685 26.2667 51.7026C26.7202 51.9985 27.1624 52.3172 27.6272 52.6131C34.9962 57.2568 42.5465 57.291 50.0515 53.1367C51.0019 52.6094 51.9047 52.0001 52.7497 51.3156C56.0034 48.6751 57.4885 45.6021 58.0327 44.4525Z" fill="black"/>
                    <path d="M65.1182 45.2722C65.0389 45.7502 64.9028 46.4331 64.6874 47.2412C63.1229 53.023 59.5518 56.6765 58.0213 58.2016C52.4209 63.79 45.9022 65.4062 42.9206 66.1119C36.7873 67.5573 31.8331 66.9427 30.0532 66.6582C23.6479 65.6338 19.2492 63.0388 17.9001 62.1852C15.1477 60.4502 12.6808 58.2961 10.5878 55.8001C8.14327 52.8969 6.22821 49.5834 4.93068 46.012C4.60191 45.1014 3.35485 41.5276 3.03741 36.6335C2.74266 31.9671 3.44554 28.4843 3.6156 27.6762C4.17003 25.0362 5.06567 22.4803 6.27977 20.0733C6.91463 18.8441 10.1456 12.8346 17.2879 8.35027C18.9431 7.30316 23.2738 4.84474 29.407 3.91145C31.0282 3.66105 45.0066 1.72618 55.0171 10.5242C59.6539 14.5988 62.386 20.0505 62.8168 20.9269C63.4801 22.2806 64.0557 23.6759 64.54 25.104C64.3813 24.8877 63.2023 23.226 63.1683 23.1805C58.8149 16.7044 53.7587 13.6541 53.7587 13.6541C51.8468 12.5265 49.7911 11.665 47.6481 11.0932C40.8346 9.24941 35.0981 10.8884 33.7377 11.2981C25.7565 13.6996 21.0291 20.0847 20.757 20.4603C15.9388 27.1299 16.0408 34.0954 16.1315 36.0303C16.4376 42.5406 19.2718 47.1387 20.5642 48.9939C20.6663 49.1191 20.8137 49.3126 20.995 49.5403C21.1311 49.7224 21.2671 49.9045 21.4032 50.0866C24.7816 54.5482 29.2596 57.6553 34.2365 59.2032C37.9454 60.3419 41.8646 60.6138 45.6942 59.9984C49.5239 59.3829 53.1626 57.8962 56.3321 55.6522C59.121 53.6718 60.8102 51.6117 61.8532 50.337C63.2363 48.6525 64.2453 46.9908 64.5741 46.3306C64.6194 46.251 65.0956 45.2722 65.1182 45.2722Z" fill="#007EE5"/>
                    <path d="M28.7061 45.6C22.7202 45.6 19.7273 41.6278 19.7273 35.5387C19.7273 29.2447 22.879 25.4546 29.0462 25.4546C31.427 25.4546 33.3429 26.0237 34.8167 27.2301C36.1998 28.4707 36.9707 30.1552 37.1634 32.2949H34.76C33.8984 32.2949 33.2749 31.8966 32.9008 31.1112C32.2432 29.7113 30.9508 28.9943 29.0576 28.9943C25.3617 28.9943 23.7179 31.612 23.7179 35.5501C23.7179 39.3743 25.2937 42.0717 28.9329 42.0717C31.427 42.0717 32.8554 40.6945 33.2862 38.532H37.1521C36.8007 43.0392 33.6377 45.6 28.7061 45.6Z" fill="black"/>
                    <path d="M49.3373 25.2747H42.5465C41.4015 25.2747 40.4945 26.2762 40.4945 27.4258V45.3063H44.5758V38.17H49.632C53.634 38.17 55.7766 35.6661 55.7766 31.7166C55.7766 27.551 53.5773 25.2747 49.3373 25.2747ZM48.8725 34.5507H44.8932V28.746H49.0765C50.9811 28.746 51.9561 29.7135 51.9561 31.6597C51.9674 33.606 50.9471 34.5848 48.8725 34.5507H48.8725Z" fill="#007EE5"/>
                    </svg>
                    <span>{{ pointNumberFormatter(terminals.reward_point_used) }} {{ parseInt(terminals.reward_point_used) > 1 ? 'pts' : 'pt'}}</span>
                </div>
                <div class="value" style="color: #007EE5;">Paid With Points</div>
            </div>
            
            <div class="tran-store-logo">
              <img v-bind:src="terminals.store_logo" v-if="terminals.store_logo != null" alt="" style="width:100%; height: 90%;">
            </div>
        </div>
        <div class="px-3"><hr class="mt-0 mb-1"></div>
        <ul class="tran-timing py-2 px-3">
          <li class="d-flex justify-content-between">
              <div class="d-flex align-items-center">
                  <svg
                  class="mr-1"
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  viewBox="0 0 90 90"
                  xml:space="preserve"
                  fill="#7f7f7f"
                  width="30px"
                  >
                  <path
                      d="M63,25h-2v-2c0-1.1-0.9-2-2-2s-2,0.9-2,2v2H33v-2c0-1.1-0.9-2-2-2s-2,0.9-2,2v2h-2c-3.3,0-6,2.7-6,6v32c0,3.3,2.7,6,6,6h36
                  c3.3,0,6-2.7,6-6V31C69,27.7,66.3,25,63,25z M65,63c0,1.1-0.9,2-2,2H27c-1.1,0-2-0.9-2-2V31c0-1.1,0.9-2,2-2h2v2c0,1.1,0.9,2,2,2
              c1.1,0,2-0.9,2-2v-2h24v2c0,1.1,0.9,2,2,2s2-0.9,2-2v-2h2c1.1,0,2,0.9,2,2V63z"
                  />
                  <path
                      d="M59,39H31c-1.1,0-2,0.9-2,2s0.9,2,2,2h28c1.1,0,2-0.9,2-2S60.1,39,59,39z"
                  />
                  </svg> 
                  <span>{{ transactiontime(index) }}</span>
              </div>
              <svg :style="expandCard == terminals.id ? 'display: none' : 'display: block'" fill="#7f7f7f" width="12px" height="12px" viewBox="0 0 12 12" enable-background="new 0 0 12 12" id="Слой_1" version="1.1" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><polygon fill="#a7a7a7s" points="3.8535156,12 3.1464844,11.2929688 8.4394531,6 3.1464844,0.7070313 3.8535156,0 9.8535156,6 "/></svg>
              
              
              <svg :style="expandCard == terminals.id ? 'display: block' : 'display: none'" width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M18.5303 9.46967C18.8232 9.76256 18.8232 10.2374 18.5303 10.5303L12.5303 16.5303C12.2374 16.8232 11.7626 16.8232 11.4697 16.5303L5.46967 10.5303C5.17678 10.2374 5.17678 9.76256 5.46967 9.46967C5.76256 9.17678 6.23744 9.17678 6.53033 9.46967L12 14.9393L17.4697 9.46967C17.7626 9.17678 18.2374 9.17678 18.5303 9.46967Z" fill="#149240"/>
              </svg>
          </li>
        </ul>

        <div class="collapse w-100" :id="'rpDetailCollapse'+index">
            <div class="tran-card-body w-100 text-left">
                <div class="tran-detail-list mb-3">
                  <div class="tran-detail-list-title mb-2 px-1">Transaction Modification History</div>
                  <div class="row mb-1 px-1">
                    <div class="col-auto col-md-auto tran-detail-value">Original Amount:</div>
                    <div class="col-auto col-md-auto tran-detail-value">${{ amountNumberFormatter(parseFloat(terminals.original_amount).toFixed(2)) }}</div>
                  </div>
                  <div class="row mb-1 px-1" v-if="terminals.modified_amount != null" v-for="(list,i) in terminals.modification_list">
                    <div class="col-auto col-md-auto tran-detail-value">{{list.name}}:</div>
                    <div class="col-auto col-md-auto tran-detail-value">${{amountNumberFormatter(list.amount)}}</div>
                  </div>
                </div>
                <div v-if="isRewardUsed(terminals.reward_point_used)" class="row justify-content-between tran-detail-point mb-2 px-1">
                    <span class="title" style="color: #007EE5;">Final Paid by Points</span>
                    <span class="value" style="color: #007EE5;">${{ terminals.reward_amount_used }}</span>
                </div>
                <div class="row justify-content-between tran-detail mb-2 px-1">
                    <span class="title">Pending with Bank</span>
                    <span class="value">${{ amountNumberFormatter(parseFloat(terminals.consumer_bank_posting_amount).toFixed(2)) }}</span>
                </div>
                <div class="row justify-content-between tran-detail-total mb-3 px-3">
                    <span class="title">FINAL TOTAL</span>
                    <span class="value">${{ amountNumberFormatter(parseFloat(terminals.total_amount).toFixed(2)) }}</span>
                </div>
                <div class="row mx-0 py-2">
                    <a class="anchor d-flex align-items-center mr-4" :href="'tel:'+terminals.store_contact_no">
                        <span class="tran-detail-icon-avatar mr-1">
                        <svg
                            version="1.1"
                            id="Layer_1"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            x="0px"
                            y="0px"
                            viewBox="0 0 100 125"
                            xml:space="preserve"
                            fill="#ffffff"
                            width="10px"
                        >
                            <path
                            d="M92.8,70.6L81.4,59.1c-2-2.1-4.3-3.1-6.8-3.1s-4.9,1.1-6.9,3.1L61,65.8c-0.6-0.3-1.2-0.6-1.8-0.9c-0.8-0.4-1.5-0.7-2.1-1.1
                            c-6.2-3.9-11.8-9.1-17.2-15.7c-2.7-3.4-4.5-6.2-5.8-9.2c1.8-1.6,3.4-3.3,5-4.9c0.6-0.6,1.2-1.2,1.8-1.8c2.1-2.1,3.2-4.5,3.2-7
                            s-1.1-4.9-3.2-7l-5.7-5.7c-0.7-0.7-1.3-1.3-1.9-2c-1.3-1.3-2.6-2.6-3.9-3.8c-2-2-4.3-3-6.8-3s-4.8,1-6.9,3L8.6,14
                            c-2.6,2.6-4.1,5.8-4.4,9.5c-0.4,4.6,0.5,9.4,2.7,15.3c3.3,9.1,8.4,17.5,15.9,26.5c9.1,10.8,20,19.4,32.5,25.4
                            c4.8,2.3,11.1,4.9,18.2,5.4c0.4,0,0.9,0,1.3,0c4.7,0,8.7-1.7,11.8-5.1l0.1-0.1c1.1-1.3,2.3-2.5,3.7-3.8c0.9-0.9,1.8-1.8,2.7-2.7
                            C97,80.2,97,74.7,92.8,70.6z M89.2,81.1c-0.8,0.9-1.6,1.7-2.5,2.5c-1.3,1.3-2.7,2.6-4,4.1c-2.2,2.3-4.7,3.4-8.1,3.4
                            c-0.3,0-0.7,0-1,0c-6.2-0.4-12-2.8-16.4-4.9C45.4,80.5,35,72.4,26.4,62.1c-7.1-8.5-11.9-16.5-15-25C9.5,31.9,8.7,27.8,9,23.9
                            c0.2-2.5,1.2-4.6,3-6.4l7.1-7.1c1.1-1,2.3-1.6,3.4-1.6s2.3,0.5,3.3,1.6c1.3,1.2,2.5,2.4,3.8,3.7c0.7,0.7,1.3,1.3,2,2l5.7,5.7
                            c1.2,1.2,1.7,2.3,1.7,3.4s-0.6,2.3-1.7,3.4c-0.6,0.6-1.2,1.2-1.8,1.8c-1.8,1.8-3.4,3.5-5.2,5.1l-0.1,0.1c-1.6,1.6-1.4,3.2-1,4.4
                            c0,0.1,0,0.1,0.1,0.2c1.5,3.5,3.5,6.9,6.7,10.9c5.7,7.1,11.8,12.5,18.4,16.8c0.8,0.5,1.7,1,2.5,1.4s1.5,0.7,2.1,1.1
                            c0.1,0,0.1,0.1,0.2,0.1c0.7,0.3,1.3,0.5,1.9,0.5c1.6,0,2.6-1,2.9-1.4l7.1-7.1c1.1-1.1,2.2-1.6,3.4-1.6c1.4,0,2.5,0.9,3.3,1.6
                            L89.3,74C92.1,76.9,90.8,79.4,89.2,81.1z"
                            />
                        </svg>
                        </span>
                        <label class="tran-detail-store mb-0">{{ terminals.store_contact_no }}</label>
                    </a>
                    <a class="anchor d-flex align-items-center" :href="terminals.store_wesite">
                        <span class="tran-detail-icon-avatar mr-1">
                          <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                          width="10" fill="#fff" viewBox="0 0 360.39 360.389" style="enable-background:new 0 0 360.39 360.389;"
                          xml:space="preserve">
                          <g>
                          <path d="M301.123,173.306L118.995,3.265c-3.242-3.038-7.891-4.049-12.105-2.639c-4.211,1.404-7.317,5.006-8.086,9.389
                          L55.602,255.404c-0.854,4.847,1.306,9.717,5.464,12.341c4.152,2.625,9.49,2.465,13.491-0.378l59.968-42.699l47.219,127.448
                          c2.127,6.336,8.984,9.765,15.332,7.648l62.087-20.771c6.342-2.116,9.771-8.984,7.648-15.327l-47.228-127.43l73.596-1.975
                          c4.905-0.13,9.262-3.215,11.006-7.802C305.929,181.85,304.717,176.654,301.123,173.306z M202.591,172.455
                          c-3.842,0.109-7.4,2.004-9.599,5.163c-2.205,3.147-2.778,7.146-1.561,10.781l48.557,131.467l-39.111,13.075l-48.56-131.455
                          c-1.224-3.647-4.093-6.502-7.743-7.679c-3.656-1.188-7.643-0.585-10.772,1.649l-49.216,35.027l34.206-194.281l144.19,134.636
                          L202.591,172.455z"/></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g>
                          </svg>
                        </span>
                        <label class="tran-detail-store mb-0">{{ terminals.store_wesite }}</label>
                    </a>
                </div>
            </div>
        </div>
    </div>
    </a>


    <b-modal
    ref="pending-reason-modal"
    hide-footer
    v-b-modal.modal-center
    modal-backdrop
    hide-header
    id="pending-reason-modal"
    centered
    title="BootstrapVue"
    >
    <div class="color">
      <div class="pending-reason-text">
        CanPay transactions debit your account in 2 banking days after purchase and fully clear your Purchase Power within 3-4 banking days
      </div>
      <div class="row justify-content-center">
        <button
        type="button"
        class="mx-auto modal-okay-btn"
        style="height: 55px!important; width: 170px;"
        @click="hideModal('pending-reason-modal')"
        >
          <label class="purchasepower-modal-ok-label">OK</label>
        </button>
      </div>
    </div>
  </b-modal>
  </div>
</template>
<script>
import moment from "moment";
export default {
  name: "TransactionDetailCard",
  data() {
    return {
      expandCard: '',
      clearence_days: '',
      clearence_banking_days: '',
      add_banking_days: 2,
    };
  },
  props: {
    terminalTypes: {
      type: Array,
      default: []
    },
    terminals: {
      type: Object,
      default: {}
    },
    index: {
      type: Number,
      default: null
    }
  },
  created() {
  },
  methods: {
    showModal(date) {
      var today = new Date();
      date = new Date(date);
      var diffDays = Math.ceil(parseInt((date - today) / (24 * 3600 * 1000)));
      this.clearence_days = diffDays;
      this.clearence_banking_days = diffDays + this.add_banking_days;
      this.$refs["pending-reason-modal"].show();
    },
    hideModal(modal) {
      this.$refs[modal].hide();
    },
    expandOnClick(id){
      let self = this;
      if(self.expandCard == id){
        self.expandCard = '';
      }else{
        if(self.expandCard == ''){
          self.expandCard = id
        }else{
          self.expandCard = '';
        }
      }
    },
    transactionamount(data) {
      if (parseFloat(this.terminalTypes[data].tip_amount) !== 0.0 && this.terminalTypes[data].tip_amount != null  ) {
        this.tip_amount = (
          parseFloat(this.terminalTypes[data].amount) +
          parseFloat(this.terminalTypes[data].tip_amount)
        ).toFixed(2);
        console.log(this.terminalTypes[data].tip_amount);
        return this.tip_amount;
      } else {
        this.tip_amount = parseFloat(this.terminalTypes[data].amount).toFixed(
          2
        );
        
        return this.tip_amount;
      }
    },
    transactiontime(data) {
      return moment
        .utc(this.terminalTypes[data].transaction_time)
        .local()
        .format(" DD MMM YYYY | hh:m A");
    },
    isRewardUsed(point){
      if(point){
        if(point == '' || point == '0.00'){
          return false
        }
        return true
      }else{
        return false
      }
    },
    totalAmount(rewardAmount, bankPostingAmount){
      if(rewardAmount){
        return parseFloat(parseFloat(rewardAmount) + parseFloat(bankPostingAmount)).toFixed(2)
      }else{
        return parseFloat(bankPostingAmount).toFixed(2)
      }
    }
  },
  mounted() {
  },
};
</script>

<style>
  .tran-penidng-date{
    display: inline-block;
    background-color: #e3e3e3;
    padding: 6px 10px;
    border-radius: 50px;
    border: 0;
    color: black;
  }
  .tran-penidng-date-active{
    display: inline-block;
    background-color: #ffffff;
    padding: 6px 10px;
    border-radius: 50px;
    border: 0;
    color: black;
  }
  .tran-penidng-date span{
    font-size: 14px;
    font-weight: 600;
  }
  .tran-penidng-date-active span{
    font-size: 14px;
    font-weight: 600;
  }
  .tran-detail-list-title{
    font-weight: bold;
    font-size: 16px;
  }
  .tran-detail-value{
    font-size: 14px;
    font-weight: 600;
  }
  #pending-reason-modal___BV_modal_body_{
    background: white!important;
    border-radius: 12px;
    padding: 50px 35px 35px 35px;
  }
  .pending-reason-text{
    font-weight: 600;
    text-align: center;
    font-size: 18px;
    line-height: 21px;
    margin-bottom: 35px;
  }
  .modal-okay-btn{
    background: #149240;
    width: 100px;
    height: auto!important;
    border: 0;
    padding: 10px 10px;
    border-radius: 6px;
  }
  .modal-okay-btn label{
    margin-bottom: 0!important;
  }
  #pending-reason-modal{
    background: #00000040!important;
  }
</style>