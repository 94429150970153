<template>
<div>
  <div v-if="isLoading">
   <CanPayLoader/>
  </div>
  <div class="container">
    <div class="btn-gap mt-2">
      <strong style="color: #ffffff;">Cannaplan Account Number: {{ sponsor_account_no }}</strong>
    </div>
    <div class="row mt-4">
      <div class="col-md-4 mb-2">
        <div class="form-group">
          <input
            class="start-date form-control"
            placeholder="Start Date"
            id="start-date"
            onkeydown="return false"
            autocomplete="off"
            />
        </div>
      </div>
      <div class="col-md-4 mb-2">
        <div class="form-group">
          <input
            class="end-date form-control"
            placeholder="End Date"
            id="end-date"
            onkeydown="return false"
            autocomplete="off"
            />
        </div>
      </div>
      <div class="col-md-4 mb-3">
        <button
            type="button"
            class="btn-black btn-search-sponsor-history my-0 pay-now-btn"
            @click="transactionHistory"
          >
            Search
          </button>
      </div>
    </div>
    <div
    class="btn-gap mt-2"
    v-if="terminalTypes.length"
    >
      <div
      v-for="(terminals, index) in terminalTypes"
      :key="index"
      >
        <transaction-detail-card
        v-bind:terminal-types="terminalTypes"
        v-bind:terminals="terminals"
        v-bind:index="index"
        />
      </div>
    </div>

    <div
      class="one mx-auto history-div"
      v-if="!terminalTypes.length && this.showloader"
    >
      <DefaultLoader :color="'#fffff'" />
      <br />
      <h3 style="color: #ffffff">Loading</h3>
    </div>
    <div
      class="one mx-auto history-div"
      v-if="!terminalTypes.length && !this.showloader"
    >
      <span class="success-text">No history to display.</span>
      <br />
      <span v-if="show_error" class="success-sub-text">
        {{error_message}}
      </span>
      <span v-else class="success-sub-text">
        Your sponsor account history will show here once you make a purchase with sponsor points.
      </span>
    </div>
    <div class="row mt-4" v-if="!showloader">
      <div class="col-md-12 text-center mb-3">
        <button
            type="button"
            class="btn-back-sponsor direct-link-btn"
            @click="$router.push('/linkabenefit')"
          >
            Back
          </button>
      </div>
    </div>
  </div>
</div>
</template>
<script>
import api from "../../api/transactiondetails.js";
import pay from "../../api/payment.js";
import { DefaultLoader } from "vue-spinners-css";
import tip from "../Payment/Tip.vue";
import TransactionDetailCard from "../TransactionDetailCard.vue";
import moment from "moment";
import { saveAs } from "file-saver";
import Loading from "vue-loading-overlay";
import CanPayLoader from "../CustomLoader/CanPayLoader.vue"
export default {
  name: "TransactionDetails",
  data() {
    return {
      showloader: false,
      transactiondetails: {},
      setupterminalModel: {
        type: "",
        transaction_type_id: "",
        terminal_name: "",
        tip_amount: "",
        searchKeyword: "0.01",
      },
      terminalTypes: [],
      approve_label: "",
      isLoading: false,
      fullPage: true,
      sponsor_link_id: '',
      from_date: "",
      to_date: "",
      sponsor_account_no: localStorage.getItem('sponsor_account_no'),
      show_error: false,
      error_message: 'Please refine your search citeria'
    };
  },
  watch: {
    tipamount: function (newval, oldval) {
      if (this.tipamount.length > 0) {
        this.disableButton = true;
      }
    },
    customTipAmount: function (newval, oldval) {
      this.tipamount = this.customTipAmount;
    },
  },
  created() {
    var self = this;
    // get sponor link id
    const urlParams = new URLSearchParams(window.location.search);
    const encryptedId = urlParams.get('sponsor');
    console.log("encryptedId: " + encryptedId);
    self.sponsor_link_id = atob(encryptedId);
    console.log('decryptedId: ' + self.sponsor_link_id);
    
    self.transactionHistory();
  },
  components: {
    DefaultLoader,
    "enter-tip": tip,
    Loading,
    'transaction-detail-card': TransactionDetailCard,
    CanPayLoader
  },

  methods: {
    transactionamount(data) {
      if (parseFloat(this.terminalTypes[data].tip_amount) !== 0.0 && this.terminalTypes[data].tip_amount != null  ) {
        this.tip_amount = (
          parseFloat(this.terminalTypes[data].amount) +
          parseFloat(this.terminalTypes[data].tip_amount)
        ).toFixed(2);
        console.log(this.terminalTypes[data].tip_amount);
        return this.tip_amount;
      } else {
        this.tip_amount = parseFloat(this.terminalTypes[data].amount).toFixed(
          2
        );
        
        return this.tip_amount;
      }
    },
    transactiontime(data) {
      return moment
        .utc(this.terminalTypes[data].transaction_time)
        .local()
        .format(" DD MMM, YYYY | hh:m A");
    },
    transactionHistory: function () {
      this.showloader = true;
      var self = this;
      self.show_error = false;
      self.start_date = $("#start-date").val();
      self.end_date = $("#end-date").val();
      if(self.start_date == ''){
        self.terminalTypes = [];
        self.showloader = false;
        self.show_error = true;
        return false;
      }
      if(self.end_date == ''){
        self.terminalTypes = [];
        self.showloader = false;
        self.show_error = true;
        return false;
      }
      
      if (
        moment(self.start_date).format("YYYY-MM-DD") >
        moment().format("YYYY-MM-DD")
      ) {
        self.terminalTypes = [];
        self.showloader = false;
        self.show_error = true;
        return false;
      }
      if (
        moment(self.end_date).format("YYYY-MM-DD") >
        moment().format("YYYY-MM-DD")
      ) {
        self.terminalTypes = [];
        self.showloader = false;
        self.show_error = true;
        return false;
      }
      if (
        moment(self.start_date).format("YYYY-MM-DD") >
        moment(self.end_date).format("YYYY-MM-DD")
      ) {
        self.terminalTypes = [];
        self.showloader = false;
        self.show_error = true;
        return false;
      }
      var request = {
        sponsor_link_id: self.sponsor_link_id,
        from_date: self.start_date,
        to_date: self.end_date
      };
      api
        .transactionHistory(request)
        .then(function (response) {
          if (response.code == 200) {
            self.terminalTypes = response.data;
            self.showloader = false;
          } else {
            if(response.message){
              alert(response.message);
            }
            self.showloader = false;
          }
        })
        .catch(function (error) {
          self.showloader = false;
        });
    },
    setDatepicker() {
      $("#start-date").datepicker({
        format: "mm/dd/yyyy",
        autoclose: true,
        todayHighlight: true,
        startDate: new Date('2021-07-01'),
      });
      $("#end-date").datepicker({
        format: "mm/dd/yyyy",
        autoclose: true,
        todayHighlight: true,
      });
      $("#start-date").datepicker("setDate", "-30d");
      $("#end-date").datepicker("setDate", new Date());
    },
  },
  mounted() {
    var self = this;
    self.setDatepicker();
    self.start_date = $("#start-date").val();
    self.end_date = $("#end-date").val();
    var element = document.getElementsByClassName("content-wrap");
    if (element[0]) {
      element[0].style.setProperty("background-color", "#149240");
    }

    self.$root.$emit("changeWhiteBackground", [
      false,
      false,
      "SponsorHistoryHeader",
    ]);

    
  },
  beforeDestroy() {
    this.$root.$off("search-keyword");
  },
};
</script>
<style>
  #tip-not-allowed-modal___BV_modal_body_ {
    background-color: #ffffff;
    border-radius: 12px;
    margin: 10px;
  }

.min-h{
    min-height: calc(100vh - 115px)!important;
}

.tran-card-link, .tran-card-link:hover{
    text-decoration: none;
    color: unset;
}
.tran-card{
    background: white;
    border-radius: 7px;
    text-align: left;
}

.tran-card-header{
    position: relative;
    padding: 15px 18px;
    border-radius: 7px;
}
.tran-store-logo{
    position: absolute;
    width: 80px;
    top: 10px;
    right: 10px;
}
.tran-title{
    font-weight: 600;
    font-size: 25px;
}
.tran-store-name{
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 15px;
}

.tran-point-card{
    padding: 7px 0;
}
.tran-point-card .title{
    margin: 0!important;
    padding: 0;
}
.tran-point-card .title span{
   font-size: 22px;
  font-weight: 800;
}
.tran-point-card .value{
  font-size: 14px;
  font-weight: 800;
}

.tran-timing{
    list-style: none;
    margin: 0;
    padding: 0;
    color: #9b9595;
    font-size: 13px;
    font-weight: 600;
}
.tran-timing li{
    display: flex;
    align-items: center;
}

.tran-card-body{
  padding: 10px 15px;
}

.tran-detail{
    align-items: center;
    margin: 0;
}
.tran-detail .title{
    font-weight: 700;
    font-size: 17px;
    margin: 0;
}
.tran-detail .value{
    font-weight: 700;
    font-size: 18px;
    margin: 0;
}

.tran-detail-point{
    align-items: center;
    margin: 0;
    color: #149240;
}
.tran-detail-point .title{
    font-weight: 700;
    font-size: 16px;
    margin: 0;
}
.tran-detail-point .value{
    font-weight: 700;
    font-size: 18px;
    margin: 0;
}

.tran-detail-total{
    align-items: center;
    background: #e3e3e3;
    padding: 13px 15px;
}
.tran-detail-total .title{
  font-weight: 700;
    font-size: 22px;
    margin: 0;
}
.tran-detail-total .value{
    font-weight: 700;
    font-size: 23px;
    margin: 0;
}
.tran-detail-icon-avatar{
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-color: #118037 !important;
    display: flex;
    justify-content: center;
    align-items: center;
}
.tran-detail-store{
  font-size: 13px;
    font-weight: 600;
}
.active-bg-card{
    background-color: #e3e3e3 !important;
    border-radius: 7px 7px 0 0;
}
.btn-search-sponsor-history {
    height: 50px !important;
    border-radius: 6px !important;
    width: 100% !important;
    border-color: transparent !important;
    background-color: #000000 !important;
    font-family: "Montserrat";
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: white;
    cursor: pointer;
}
.btn-back-sponsor {
    height: 50px !important;
    border-radius: 6px !important;
    width: 100% !important;
    border-color: white !important;
    background-color: transparent !important;
    font-family: "Montserrat";
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    color: #ffffff;
    cursor: pointer;
    border: 1px solid white;
}
</style>
