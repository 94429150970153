<template>
<div>
  <div v-if="isLoading">
    <CanPayLoader/>
  </div>
  <div class="container">
    <div class="row profile-image-margin">
      <div class="col-12 text-center d-flex align-items-center justify-content-center">
        <span class="user-block d-flex align-items-center justify-content-center">
          <img
            src="../../assets/images/user.png"
            class="rounded m-0"
          />
        </span>
      </div>
    </div>

    <div v-if="!isLoading">
      <!-- consumer name row -->
      <div class="row name-text-margin">
        <div class="col-12">
          <span class="text-center">
            <label class="welcome-text-style"
              >{{ first_name }} {{ middle_name }} {{ last_name }}</label
            >
          </span>
        </div>
      </div>
      <!-- bank account update section -->
      <div class="row all-text-margin mb-3">
        <div class="col-2 col-md-auto">
          <div class="dark-icon-box">
            <svg
              class="svg-icon-position"
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 1024 1280"
              style="enable-background: new 0 0 1024 1280"
              xml:space="preserve"
              height="28"
              width="28"
              fill="#ffffff"
            >
              <g>
                <g>
                  <g>
                    <path
                      d="M904.2,390.1c-7,0-14,0-21,0c-19,0-37.9,0-56.9,0c-28.2,0-56.3,0-84.5,0c-34.2,0-68.4,0-102.7,0c-37.6,0-75.3,0-112.9,0
				c-38,0-76,0-114,0c-35.3,0-70.6,0-105.8,0c-29.9,0-59.9,0-89.8,0c-21.4,0-42.9,0-64.3,0c-10.2,0-20.4-0.3-30.6,0
				c-0.4,0-0.9,0-1.3,0c6.7,6.7,13.3,13.3,20,20c0-28.2,0-56.4,0-84.6c-3.3,5.8-6.6,11.5-9.9,17.3c12.9-6.7,25.8-13.5,38.7-20.2
				c31-16.1,62-32.3,92.9-48.4c37.3-19.5,74.7-38.9,112-58.4c32.5-17,65-33.9,97.6-50.9c15.6-8.2,31.7-15.8,47.1-24.5
				c0.2-0.1,0.5-0.2,0.7-0.4c-6.7,0-13.5,0-20.2,0c13.1,6.7,26.1,13.4,39.2,20.1c31.5,16.2,63,32.3,94.5,48.5
				c38,19.5,76,39,114,58.5c33,16.9,65.9,33.8,98.9,50.7c15.9,8.1,31.6,17.1,47.8,24.5c0.2,0.1,0.4,0.2,0.7,0.3
				c-3.3-5.8-6.6-11.5-9.9-17.3c0,28.2,0,56.4,0,84.6c0,10.5,9.2,20.5,20,20s20-8.8,20-20c0-28.2,0-56.4,0-84.6
				c0-6.7-3.7-14.1-9.9-17.3c-13.1-6.7-26.1-13.4-39.2-20.1c-31.2-16-62.5-32.1-93.7-48.1c-37.8-19.4-75.7-38.8-113.5-58.3
				c-33-16.9-66-33.9-99-50.8c-16.3-8.4-32.4-17-48.9-25.1c-7.1-3.5-14-3.9-21.3-0.3c-1.5,0.7-2.9,1.5-4.4,2.3
				c-7.7,4-15.4,8-23.1,12c-29.1,15.2-58.2,30.3-87.3,45.5c-37.7,19.6-75.3,39.3-113,58.9c-34,17.7-68.1,35.5-102.1,53.2
				c-18.7,9.8-37.5,19.5-56.2,29.3c-0.9,0.4-1.7,0.9-2.6,1.3c-6.2,3.2-9.9,10.5-9.9,17.3c0,28.2,0,56.4,0,84.6c0,10.8,9.2,20,20,20
				c7,0,14,0,21,0c19,0,37.9,0,56.9,0c28.2,0,56.3,0,84.5,0c34.2,0,68.4,0,102.7,0c37.6,0,75.3,0,112.9,0c38,0,76,0,114,0
				c35.3,0,70.6,0,105.8,0c29.9,0,59.9,0,89.8,0c21.4,0,42.9,0,64.3,0c10.2,0,20.4,0.2,30.6,0c0.4,0,0.9,0,1.3,0
				c10.5,0,20.5-9.2,20-20C923.7,399.3,915.4,390.1,904.2,390.1z"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <path
                      d="M924,881.1c-7.4,0-14.8,0-22.1,0c-20,0-40,0-59.9,0c-29.5,0-59,0-88.6,0c-36,0-72,0-108,0c-39.6,0-79.2,0-118.8,0
				c-39.8,0-79.6,0-119.5,0c-37.1,0-74.3,0-111.4,0c-31.4,0-62.8,0-94.2,0c-22.7,0-45.3,0-68,0c-10.7,0-21.4-0.2-32.1,0
				c-0.5,0-0.9,0-1.4,0c-10.5,0-20.5,9.2-20,20s8.8,20,20,20c7.4,0,14.8,0,22.1,0c20,0,40,0,59.9,0c29.5,0,59,0,88.6,0
				c36,0,72,0,108,0c39.6,0,79.2,0,118.8,0c39.8,0,79.6,0,119.5,0c37.1,0,74.3,0,111.4,0c31.4,0,62.8,0,94.2,0c22.7,0,45.3,0,68,0
				c10.7,0,21.4,0.2,32.1,0c0.5,0,0.9,0,1.4,0c10.5,0,20.5-9.2,20-20C943.5,890.3,935.2,881.1,924,881.1L924,881.1z"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <path
                      d="M391.3,510.1c0,12.9,0,25.9,0,38.8c0,31.1,0,62.2,0,93.3c0,37.6,0,75.3,0,112.9c0,32.6,0,65.1,0,97.7
				c0,15.9-0.4,31.8,0,47.7c0,0.2,0,0.4,0,0.7c0,10.5,9.2,20.5,20,20s20-8.8,20-20c0-12.9,0-25.9,0-38.8c0-31.1,0-62.2,0-93.3
				c0-37.6,0-75.3,0-112.9c0-32.6,0-65.1,0-97.7c0-15.9,0.4-31.8,0-47.7c0-0.2,0-0.4,0-0.7c0-10.5-9.2-20.5-20-20
				C400.5,490.6,391.3,498.9,391.3,510.1L391.3,510.1z"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <path
                      d="M230,901.1c0-12.9,0-25.9,0-38.8c0-31.1,0-62.2,0-93.3c0-37.6,0-75.3,0-112.9c0-32.6,0-65.1,0-97.7
				c0-15.9,0.4-31.8,0-47.7c0-0.2,0-0.4,0-0.7c0-10.5-9.2-20.5-20-20s-20,8.8-20,20c0,12.9,0,25.9,0,38.8c0,31.1,0,62.2,0,93.3
				c0,37.6,0,75.3,0,112.9c0,32.6,0,65.1,0,97.7c0,15.9-0.4,31.8,0,47.7c0,0.2,0,0.4,0,0.7c0,10.5,9.2,20.5,20,20
				C220.8,920.7,230,912.4,230,901.1L230,901.1z"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <path
                      d="M794,510.1c0,12.9,0,25.9,0,38.8c0,31.1,0,62.2,0,93.3c0,37.6,0,75.3,0,112.9c0,32.6,0,65.1,0,97.7
				c0,15.9-0.4,31.8,0,47.7c0,0.2,0,0.4,0,0.7c0,10.5,9.2,20.5,20,20s20-8.8,20-20c0-12.9,0-25.9,0-38.8c0-31.1,0-62.2,0-93.3
				c0-37.6,0-75.3,0-112.9c0-32.6,0-65.1,0-97.7c0-15.9,0.4-31.8,0-47.7c0-0.2,0-0.4,0-0.7c0-10.5-9.2-20.5-20-20
				C803.2,490.6,794,498.9,794,510.1L794,510.1z"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <path
                      d="M592.7,510.1c0,12.9,0,25.9,0,38.8c0,31.1,0,62.2,0,93.3c0,37.6,0,75.3,0,112.9c0,32.6,0,65.1,0,97.7
				c0,15.9-0.4,31.8,0,47.7c0,0.2,0,0.4,0,0.7c0,10.5,9.2,20.5,20,20s20-8.8,20-20c0-12.9,0-25.9,0-38.8c0-31.1,0-62.2,0-93.3
				c0-37.6,0-75.3,0-112.9c0-32.6,0-65.1,0-97.7c0-15.9,0.4-31.8,0-47.7c0-0.2,0-0.4,0-0.7c0-10.5-9.2-20.5-20-20
				C601.8,490.6,592.7,498.9,592.7,510.1L592.7,510.1z"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <path
                      d="M537.9,286.4c0,1.1-0.1,2.3-0.1,3.5c0,2.9,1.1-5,0.1-0.6c-0.4,1.7-0.7,3.5-1.3,5.2c-0.1,0.4-0.3,0.8-0.4,1.3
				c-0.6,1.6-0.6,1.7,0.1,0.1c0.2-0.4,0.3-0.8,0.5-1.2c-0.4,0.8-0.7,1.6-1.1,2.4c-0.2,0.4-3,5.7-3.4,5.6c-0.2-0.1,2.8-3.2,0.7-1
				c-0.7,0.8-1.4,1.6-2.2,2.4c-0.4,0.4-3.7,4.1-4.3,3.9c-0.2-0.1,3.6-2.4,0.9-0.8c-0.8,0.4-1.5,0.9-2.2,1.4c-0.9,0.6-1.9,1-2.9,1.5
				c-0.6,0.3-1.2,0.6-1.8,0.8c2.6-1.1,3.1-1.3,1.7-0.8c-1.9,0.2-3.9,1.2-5.8,1.6c0.1,0-2.6,0.6-2.7,0.5c0.3,0.4,4.7-0.4,0.5-0.3
				c-1.7,0.1-4.6-0.6-6.2-0.1c2.4-0.7,3.5,0.7,1.3,0.2c-1.3-0.3-2.6-0.5-3.9-0.9c-1-0.3-2.2-0.9-3.2-1c-2.6-0.3,4.2,2.2,0.5,0.3
				c-1.5-0.8-3.1-1.6-4.6-2.5c-0.4-0.2-0.7-0.5-1.1-0.7c-1.4-1-1.4-1-0.1,0c0.3,0.3,0.7,0.5,1,0.8c-0.7-0.5-1.3-1.1-2-1.7
				c-1.6-1.4-3.1-3-4.5-4.6c-2.6-2.8,0.9,0.9,0.7,1c-0.1,0.1-1.6-2.4-1.8-2.7c-0.2-0.4-3.1-4.7-2.7-5.2c0.2-0.3,1.4,4.2,0.6,1.1
				c-0.3-1.1-0.7-2.1-0.9-3.2c-0.3-1.1-0.5-2.2-0.7-3.3c-0.9-3.9,0,4.2,0.1-0.1c0-1.8,0-3.7,0-5.6c0.1-4.1-0.1,1.4-0.3,1.2
				c-0.2-0.2,0.5-2.9,0.6-3.3c0.1-0.3,1.3-5.8,1.7-5.7c0.2,0-1.9,3.9-0.5,1.1c0.6-1.2,1.2-2.3,1.8-3.5c0.5-0.9,1.4-1.8,1.7-2.7
				c0.9-2.4-3.2,3.5-0.4,0.4c1.2-1.3,2.3-2.6,3.6-3.8c0.8-0.7,1.7-1.4,2.4-2.1c1.8-1.9-4.1,2.5-0.4,0.4c1.9-1.1,3.7-2.2,5.7-3.2
				c3.4-1.8-1.1,0.6-1.1,0.5c0-0.2,2.8-0.9,3.1-1c1.9-0.6,4-0.7,5.9-1.3c-4.2,1.2-2.8,0.3-1.2,0.3c1.2,0,2.3-0.1,3.5-0.1
				c0.4,0,3.4,0,3.5,0.2c-0.1-0.3-4.9-1,0,0.1c0.5,0.1,6.4,1.4,6.4,1.9c0,0.2-3.9-1.9-1.1-0.5c1.2,0.6,2.3,1.2,3.5,1.8
				c0.7,0.4,1.5,1,2.2,1.4c3.6,2-2.7-2.6,0.1,0c1.8,1.6,3.4,3.3,5.1,5c2.8,3-0.4-0.5-0.3-0.5c0.2-0.1,2,3.1,2.1,3.3
				c0.4,0.8,0.9,1.5,1.3,2.3c0.3,0.6,0.6,1.2,0.9,1.8c0.6,1.4,0.4,0.8-0.8-1.7c0.5,0,1.7,5.9,1.9,6.4c0.1,0.4,0.2,0.9,0.3,1.3
				c0.3,1.8,0.3,1.8,0,0c-0.2-1.8-0.3-1.7-0.1,0.1C537.9,284.6,537.9,285.5,537.9,286.4c0.2,10.5,9.1,20.5,20,20
				c10.7-0.5,20.2-8.8,20-20c-0.4-28.1-18-52.4-44.3-62c-24.6-8.9-54.5-0.7-71,19.5c-17.6,21.5-21.1,52-6.5,76.1
				c14.6,24.2,42.4,35.4,69.8,30.5c30.3-5.5,51.6-34.1,52.1-64.1c0.2-10.5-9.3-20.5-20-20C546.9,266.9,538,275.2,537.9,286.4z"
                    />
                  </g>
                </g>
              </g>
            </svg>
          </div>
        </div>
        <div v-if="!no_active_bank_account" class="col-6 left-padding align-self-center">
          <span class="left-span">
            <label class="edit-profile-text-style">
              {{ bank_name }} - {{ account_no }}
              <br />
              {{ account_type }}
            </label>
          </span>
        </div>
        <div v-else class="col-6 left-padding align-self-center">
          <span class="left-span">
            <label  class="edit-profile-text-style">
              No active bank account
            </label>
          </span>
        </div>
        <div class="col-4 align-self-center">
          <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            height="20"
            width="20"
            viewBox="0 0 73 73.1"
            style="enable-background: new 0 0 73 73.1"
            xml:space="preserve"
            class="float-right"
            fill="#ffffff"
            @click="changeBankAccount"
          >
            <path
              d="M69.2,7.7l-3.9-3.9c-5.1-5.1-13.3-5.1-18.4,0l-41,41c-0.4,0.4-0.7,0.9-0.8,1.5l-5,23.1C-0.3,71,0.8,72.6,2.4,73
	c0.4,0.1,0.9,0.1,1.3,0l23.1-5c0.6-0.1,1.1-0.4,1.5-0.8l41-41C74.3,21.1,74.3,12.8,69.2,7.7L69.2,7.7z M12.3,46.9l33.1-33.1
	l13.8,13.8L26.1,60.7L12.3,46.9z M9.8,52.9l10.3,10.3L6.9,66.1L9.8,52.9z M64.9,21.9l-1.4,1.5L49.6,9.5L51.1,8
	c2.7-2.7,7.2-2.7,9.9,0l0,0l3.9,3.9C67.7,14.7,67.7,19.1,64.9,21.9C65,21.8,65,21.9,64.9,21.9L64.9,21.9z"
            />
          </svg>
        </div>
      </div>
      <!-- reset quick access pin -->
      <div class="row all-text-margin mb-3">
        <div class="col-2 col-md-auto">
          <div class="dark-icon-box">
            <svg
              class="svg-icon-position"
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 100 125"
              style="enable-background: new 0 0 100 125"
              xml:space="preserve"
              height="40"
              width="40"
              fill="#ffffff"
            >
              <path
                d="M77.1,81.4H38.5c-4.8,0-8.6-3.9-8.6-8.6v-6.7c0-4.8,3.9-8.6,8.6-8.6h38.6c4.8,0,8.6,3.9,8.6,8.6v6.7
    C85.8,77.5,81.9,81.4,77.1,81.4z M38.5,62c-2.3,0-4.1,1.9-4.1,4.1v6.7c0,2.3,1.9,4.1,4.1,4.1h38.6c2.3,0,4.1-1.9,4.1-4.1v-6.7
    c0-2.3-1.9-4.1-4.1-4.1H38.5z"
              />
              <circle cx="43" cy="69.4" r="3.1" />
              <circle cx="52.5" cy="69.4" r="3.1" />
              <circle cx="62" cy="69.4" r="3.1" />
              <path
                d="M71.6,66.3c1.7,0,3.1,1.4,3.1,3.1c0,1.7-1.4,3.1-3.1,3.1c-1.7,0-3.1-1.4-3.1-3.1C68.4,67.7,69.8,66.3,71.6,66.3z"
              />
              <path
                d="M43.4,43.5c-1.2,0-2.3-1-2.3-2.3v-9.9c0-4.5-3.7-8.2-8.2-8.2s-8.2,3.7-8.2,8.2v9.9c0,1.2-1,2.3-2.3,2.3s-2.3-1-2.3-2.3v-9.9
    c0-7,5.7-12.7,12.7-12.7s12.7,5.7,12.7,12.7v9.9C45.7,42.5,44.7,43.5,43.4,43.5z"
              />
              <path
                d="M25.5,72.4h-4.8c-3.6,0-6.5-2.9-6.5-6.5V45.5c0-3.6,2.9-6.5,6.5-6.5h25.8c3.6,0,6.5,2.9,6.5,6.5v11.4c0,1.2-1,2.3-2.3,2.3
    s-2.3-1-2.3-2.3V45.5c0-1.1-0.9-2-2-2H20.7c-1.1,0-2,0.9-2,2v20.4c0,1.1,0.9,2,2,2h4.8c1.2,0,2.3,1,2.3,2.3S26.8,72.4,25.5,72.4z"
              />
            </svg>
          </div>
        </div>
        <div class="col-6 left-padding align-self-center">
          <span class="left-span">
            <label class="edit-profile-text-style">
              Reset Quick Access PIN
            </label>
          </span>
        </div>
        <div class="col-4 align-self-center">
          <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            height="20"
            width="20"
            viewBox="0 0 73 73.1"
            style="enable-background: new 0 0 73 73.1"
            xml:space="preserve"
            class="float-right"
            fill="#ffffff"
            @click="resetPIN()"
          >
            <path
              d="M69.2,7.7l-3.9-3.9c-5.1-5.1-13.3-5.1-18.4,0l-41,41c-0.4,0.4-0.7,0.9-0.8,1.5l-5,23.1C-0.3,71,0.8,72.6,2.4,73
	c0.4,0.1,0.9,0.1,1.3,0l23.1-5c0.6-0.1,1.1-0.4,1.5-0.8l41-41C74.3,21.1,74.3,12.8,69.2,7.7L69.2,7.7z M12.3,46.9l33.1-33.1
	l13.8,13.8L26.1,60.7L12.3,46.9z M9.8,52.9l10.3,10.3L6.9,66.1L9.8,52.9z M64.9,21.9l-1.4,1.5L49.6,9.5L51.1,8
	c2.7-2.7,7.2-2.7,9.9,0l0,0l3.9,3.9C67.7,14.7,67.7,19.1,64.9,21.9C65,21.8,65,21.9,64.9,21.9L64.9,21.9z"
            />
          </svg>
        </div>
      </div>
      <!-- email row -->
      <div class="row all-text-margin mb-3">
        <div class="col-2 col-md-auto">
          <div class="dark-icon-box">
            <svg
              class="svg-icon-position"
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 32 40"
              style="enable-background: new 0 0 32 40"
              xml:space="preserve"
              height="28"
              width="28"
              fill="#ffffff"
            >
              <g>
                <g>
                  <path
                    d="M30,28H2c-0.6,0-1-0.4-1-1V5c0-0.6,0.4-1,1-1h28c0.6,0,1,0.4,1,1v22C31,27.6,30.6,28,30,28z M3,26h26V6H3V26z"
                  />
                </g>
                <g>
                  <path
                    d="M16,20.4c-0.2,0-0.4-0.1-0.5-0.2l-14-9C1.2,11.1,1,10.7,1,10.4V5c0-0.6,0.4-1,1-1h28c0.6,0,1,0.4,1,1v5.4
			c0,0.3-0.2,0.7-0.5,0.8l-14,9C16.4,20.4,16.2,20.4,16,20.4z M3,9.9l13,8.4l13-8.4V6H3V9.9z"
                  />
                </g>
              </g>
            </svg>
          </div>
        </div>
        <div class="col-6 left-padding align-self-center">
          <span class="left-span">
            <label class="edit-profile-text-style">
              {{ email }}
            </label>
          </span>
        </div>
        <div class="col-4 align-self-center">
          <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            height="20"
            width="20"
            viewBox="0 0 73 73.1"
            style="enable-background: new 0 0 73 73.1"
            xml:space="preserve"
            class="float-right"
            fill="#ffffff"
            @click="clickchange('email')"
          >
            <path
              d="M69.2,7.7l-3.9-3.9c-5.1-5.1-13.3-5.1-18.4,0l-41,41c-0.4,0.4-0.7,0.9-0.8,1.5l-5,23.1C-0.3,71,0.8,72.6,2.4,73
	c0.4,0.1,0.9,0.1,1.3,0l23.1-5c0.6-0.1,1.1-0.4,1.5-0.8l41-41C74.3,21.1,74.3,12.8,69.2,7.7L69.2,7.7z M12.3,46.9l33.1-33.1
	l13.8,13.8L26.1,60.7L12.3,46.9z M9.8,52.9l10.3,10.3L6.9,66.1L9.8,52.9z M64.9,21.9l-1.4,1.5L49.6,9.5L51.1,8
	c2.7-2.7,7.2-2.7,9.9,0l0,0l3.9,3.9C67.7,14.7,67.7,19.1,64.9,21.9C65,21.8,65,21.9,64.9,21.9L64.9,21.9z"
            />
          </svg>
        </div>
      </div>

      <!-- phone number row -->
      <div class="row all-text-margin mb-3">
        <div class="col-2 col-md-auto">
          <div class="dark-icon-box">
            <svg
              class="svg-icon-position"
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 72 90"
              style="enable-background: new 0 0 72 90"
              xml:space="preserve"
              height="28"
              width="28"
              fill="#ffffff"
            >
              >
              <path
                d="M48,70H24c-5.5,0-10-4.5-10-10V12c0-5.5,4.5-10,10-10h24c5.5,0,10,4.5,10,10v48C58,65.5,53.5,70,48,70z M24,6
	c-3.3,0-6,2.7-6,6v48c0,3.3,2.7,6,6,6h24c3.3,0,6-2.7,6-6V12c0-3.3-2.7-6-6-6H24z"
              />
              <path
                d="M40,14h-8c-1.1,0-2-0.9-2-2s0.9-2,2-2h8c1.1,0,2,0.9,2,2S41.1,14,40,14z"
              />
              <circle cx="36" cy="56.4" r="4" />
            </svg>
          </div>
        </div>
        <div class="col-6 left-padding align-self-center">
          <span class="left-span">
            <label class="edit-profile-text-style">
              {{ phone_no }}
            </label>
          </span>
        </div>
        <div class="col-4 align-self-center">
          <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            height="20"
            width="20"
            viewBox="0 0 73 73.1"
            style="enable-background: new 0 0 73 73.1"
            xml:space="preserve"
            class="float-right"
            fill="#ffffff"
            @click="clickchange(updatePhone)"
          >
            <path
              d="M69.2,7.7l-3.9-3.9c-5.1-5.1-13.3-5.1-18.4,0l-41,41c-0.4,0.4-0.7,0.9-0.8,1.5l-5,23.1C-0.3,71,0.8,72.6,2.4,73
	c0.4,0.1,0.9,0.1,1.3,0l23.1-5c0.6-0.1,1.1-0.4,1.5-0.8l41-41C74.3,21.1,74.3,12.8,69.2,7.7L69.2,7.7z M12.3,46.9l33.1-33.1
	l13.8,13.8L26.1,60.7L12.3,46.9z M9.8,52.9l10.3,10.3L6.9,66.1L9.8,52.9z M64.9,21.9l-1.4,1.5L49.6,9.5L51.1,8
	c2.7-2.7,7.2-2.7,9.9,0l0,0l3.9,3.9C67.7,14.7,67.7,19.1,64.9,21.9C65,21.8,65,21.9,64.9,21.9L64.9,21.9z"
            />
          </svg>
        </div>
      </div>

      <!-- address row -->
      <div class="row all-text-margin mb-3">
        <div class="col-2 col-md-auto">
          <div class="dark-icon-box">
            <svg
              class="svg-icon-position"
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 291 488.8"
              style="enable-background: new 0 0 291 488.8"
              xml:space="preserve"
              height="28"
              width="28"
              fill="#ffffff"
            >
              <g>
                <path
                  d="M146,72c40,0,73,33,73,74c0,40-33,73-73,73c-41,0-74-33-74-73C72,105,105,72,146,72z M146,93c-29,0-53,24-53,53
		c0,28,24,52,53,52c28,0,52-24,52-52C198,117,174,93,146,93z"
                />
                <path
                  d="M146,21C77,21,21,77,21,146c0,19,7,40,16,57c21,46,48,91,74,134l13,21c10,16,33,16,43,0l11-19c26-43,53-87,74-131
		c9-19,18-41,18-62C270,77,214,21,146,21z M146,0c80,0,145,65,145,146c0,24-9,49-20,71c-22,45-48,90-75,133l-11,19
		c-18,29-61,29-79,0l-13-21c-26-44-54-90-76-136c-9-20-17-44-17-66C0,65,65,0,146,0z"
                />
              </g>
            </svg>
          </div>
        </div>
        <div class="col-6 left-padding align-self-center">
          <span class="left-span">
            <label class="edit-profile-text-style">
              {{ full_address }}
            </label>
          </span>
        </div>
        <div class="col-4 align-self-center" >
          <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            height="20"
            width="20"
            viewBox="0 0 73 73.1"
            style="enable-background: new 0 0 73 73.1"
            xml:space="preserve"
            class="float-right"
            fill="#ffffff"
            @click="checkAddressUpdate()"
          >
            <path
              d="M69.2,7.7l-3.9-3.9c-5.1-5.1-13.3-5.1-18.4,0l-41,41c-0.4,0.4-0.7,0.9-0.8,1.5l-5,23.1C-0.3,71,0.8,72.6,2.4,73
	c0.4,0.1,0.9,0.1,1.3,0l23.1-5c0.6-0.1,1.1-0.4,1.5-0.8l41-41C74.3,21.1,74.3,12.8,69.2,7.7L69.2,7.7z M12.3,46.9l33.1-33.1
	l13.8,13.8L26.1,60.7L12.3,46.9z M9.8,52.9l10.3,10.3L6.9,66.1L9.8,52.9z M64.9,21.9l-1.4,1.5L49.6,9.5L51.1,8
	c2.7-2.7,7.2-2.7,9.9,0l0,0l3.9,3.9C67.7,14.7,67.7,19.1,64.9,21.9C65,21.8,65,21.9,64.9,21.9L64.9,21.9z"
            />
          </svg>
        </div>
        <!-- <div class="col-12 text-left pl-4">
          <small v-if="can_update_address == true && (existing_user == 1 && is_date_of_birth_update == 0)">*Please update your date of birth before updating your address</small>
        </div> -->
      </div>

      <!-- apt number row -->
      <div class="row all-text-margin mb-3">
        <div class="col-2 col-md-auto">
          <div class="dark-icon-box">
            <svg
              class="svg-icon-position"
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              height="28"
              width="28"
              viewBox="0 0 100 125"
              style="enable-background: new 0 0 100 125"
              xml:space="preserve"
              fill="#ffffff"
            >
              <title>Straight_Line</title>
              <path
                d="M25,87.5c-1.4,0-2.5-1.1-2.5-2.5V15c0-1.4,1.1-2.5,2.5-2.5s2.5,1.1,2.5,2.5v70C27.5,86.4,26.4,87.5,25,87.5z"
              />
              <path
                d="M75,17.5H25c-1.4,0-2.5-1.1-2.5-2.5s1.1-2.5,2.5-2.5h50c1.4,0,2.5,1.1,2.5,2.5S76.4,17.5,75,17.5z"
              />
              <path
                d="M75,87.5c-1.4,0-2.5-1.1-2.5-2.5V15c0-1.4,1.1-2.5,2.5-2.5s2.5,1.1,2.5,2.5v70C77.5,86.4,76.4,87.5,75,87.5z"
              />
              <path
                d="M75,87.5H25c-1.4,0-2.5-1.1-2.5-2.5s1.1-2.5,2.5-2.5h50c1.4,0,2.5,1.1,2.5,2.5S76.4,87.5,75,87.5z"
              />
              <path
                d="M35,87.5c-1.4,0-2.5-1.1-2.5-2.5V65c0-1.4,1.1-2.5,2.5-2.5s2.5,1.1,2.5,2.5v20C37.5,86.4,36.4,87.5,35,87.5z"
              />
              <path
                d="M45,67.5H35c-1.4,0-2.5-1.1-2.5-2.5c0-1.4,1.1-2.5,2.5-2.5h10c1.4,0,2.5,1.1,2.5,2.5C47.5,66.4,46.4,67.5,45,67.5z"
              />
              <path
                d="M45,87.5c-1.4,0-2.5-1.1-2.5-2.5V65c0-1.4,1.1-2.5,2.5-2.5s2.5,1.1,2.5,2.5v20C47.5,86.4,46.4,87.5,45,87.5z"
              />
              <path
                d="M45,27.5H35c-1.4,0-2.5-1.1-2.5-2.5s1.1-2.5,2.5-2.5h10c1.4,0,2.5,1.1,2.5,2.5S46.4,27.5,45,27.5z"
              />
              <path
                d="M45,37.5c-1.4,0-2.5-1.1-2.5-2.5V25c0-1.4,1.1-2.5,2.5-2.5s2.5,1.1,2.5,2.5v10C47.5,36.4,46.4,37.5,45,37.5z"
              />
              <path
                d="M45,37.5H35c-1.4,0-2.5-1.1-2.5-2.5s1.1-2.5,2.5-2.5h10c1.4,0,2.5,1.1,2.5,2.5S46.4,37.5,45,37.5z"
              />
              <path
                d="M35,37.5c-1.4,0-2.5-1.1-2.5-2.5V25c0-1.4,1.1-2.5,2.5-2.5s2.5,1.1,2.5,2.5v10C37.5,36.4,36.4,37.5,35,37.5z"
              />
              <path
                d="M65,27.5H55c-1.4,0-2.5-1.1-2.5-2.5s1.1-2.5,2.5-2.5h10c1.4,0,2.5,1.1,2.5,2.5S66.4,27.5,65,27.5z"
              />
              <path
                d="M65,37.5c-1.4,0-2.5-1.1-2.5-2.5V25c0-1.4,1.1-2.5,2.5-2.5c1.4,0,2.5,1.1,2.5,2.5v10C67.5,36.4,66.4,37.5,65,37.5z"
              />
              <path
                d="M65,37.5H55c-1.4,0-2.5-1.1-2.5-2.5s1.1-2.5,2.5-2.5h10c1.4,0,2.5,1.1,2.5,2.5S66.4,37.5,65,37.5z"
              />
              <path
                d="M55,37.5c-1.4,0-2.5-1.1-2.5-2.5V25c0-1.4,1.1-2.5,2.5-2.5s2.5,1.1,2.5,2.5v10C57.5,36.4,56.4,37.5,55,37.5z"
              />
              <path
                d="M45,47.5H35c-1.4,0-2.5-1.1-2.5-2.5s1.1-2.5,2.5-2.5h10c1.4,0,2.5,1.1,2.5,2.5S46.4,47.5,45,47.5z"
              />
              <path
                d="M45,57.5c-1.4,0-2.5-1.1-2.5-2.5V45c0-1.4,1.1-2.5,2.5-2.5s2.5,1.1,2.5,2.5v10C47.5,56.4,46.4,57.5,45,57.5z"
              />
              <path
                d="M45,57.5H35c-1.4,0-2.5-1.1-2.5-2.5s1.1-2.5,2.5-2.5h10c1.4,0,2.5,1.1,2.5,2.5S46.4,57.5,45,57.5z"
              />
              <path
                d="M35,57.5c-1.4,0-2.5-1.1-2.5-2.5V45c0-1.4,1.1-2.5,2.5-2.5s2.5,1.1,2.5,2.5v10C37.5,56.4,36.4,57.5,35,57.5z"
              />
              <path
                d="M65,47.5H55c-1.4,0-2.5-1.1-2.5-2.5s1.1-2.5,2.5-2.5h10c1.4,0,2.5,1.1,2.5,2.5S66.4,47.5,65,47.5z"
              />
              <path
                d="M65,57.5c-1.4,0-2.5-1.1-2.5-2.5V45c0-1.4,1.1-2.5,2.5-2.5c1.4,0,2.5,1.1,2.5,2.5v10C67.5,56.4,66.4,57.5,65,57.5z"
              />
              <path
                d="M65,57.5H55c-1.4,0-2.5-1.1-2.5-2.5s1.1-2.5,2.5-2.5h10c1.4,0,2.5,1.1,2.5,2.5S66.4,57.5,65,57.5z"
              />
              <path
                d="M55,57.5c-1.4,0-2.5-1.1-2.5-2.5V45c0-1.4,1.1-2.5,2.5-2.5s2.5,1.1,2.5,2.5v10C57.5,56.4,56.4,57.5,55,57.5z"
              />
              <path
                d="M65,67.5H55c-1.4,0-2.5-1.1-2.5-2.5c0-1.4,1.1-2.5,2.5-2.5h10c1.4,0,2.5,1.1,2.5,2.5C67.5,66.4,66.4,67.5,65,67.5z"
              />
              <path
                d="M65,77.5c-1.4,0-2.5-1.1-2.5-2.5V65c0-1.4,1.1-2.5,2.5-2.5c1.4,0,2.5,1.1,2.5,2.5v10C67.5,76.4,66.4,77.5,65,77.5z"
              />
              <path
                d="M65,77.5H55c-1.4,0-2.5-1.1-2.5-2.5s1.1-2.5,2.5-2.5h10c1.4,0,2.5,1.1,2.5,2.5S66.4,77.5,65,77.5z"
              />
              <path
                d="M55,77.5c-1.4,0-2.5-1.1-2.5-2.5V65c0-1.4,1.1-2.5,2.5-2.5s2.5,1.1,2.5,2.5v10C57.5,76.4,56.4,77.5,55,77.5z"
              />
            </svg>
          </div>
        </div>
        <div class="col-6 left-padding align-self-center">
          <span class="left-span">
            <label v-if="!show_apt_input" class="edit-profile-text-style">
              {{ apt_number }}
            </label>
            <input
              v-else
              ref="apt_input"
              v-model="apt_number"
              class="edit-profile-text-style form-control edit-apt-number"
              placeholder="Apt or Unit #"
            />
          </span>
        </div>
        <div class="col-4 align-self-center">
          <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            height="20"
            width="20"
            viewBox="0 0 73 73.1"
            style="enable-background: new 0 0 73 73.1"
            xml:space="preserve"
            class="float-right"
            fill="#ffffff"
            v-if="!show_apt_input"
            @click="showAptInput()"
          >
            <path
              d="M69.2,7.7l-3.9-3.9c-5.1-5.1-13.3-5.1-18.4,0l-41,41c-0.4,0.4-0.7,0.9-0.8,1.5l-5,23.1C-0.3,71,0.8,72.6,2.4,73
	c0.4,0.1,0.9,0.1,1.3,0l23.1-5c0.6-0.1,1.1-0.4,1.5-0.8l41-41C74.3,21.1,74.3,12.8,69.2,7.7L69.2,7.7z M12.3,46.9l33.1-33.1
	l13.8,13.8L26.1,60.7L12.3,46.9z M9.8,52.9l10.3,10.3L6.9,66.1L9.8,52.9z M64.9,21.9l-1.4,1.5L49.6,9.5L51.1,8
	c2.7-2.7,7.2-2.7,9.9,0l0,0l3.9,3.9C67.7,14.7,67.7,19.1,64.9,21.9C65,21.8,65,21.9,64.9,21.9L64.9,21.9z"
            />
          </svg>
          <button
            v-else
            type="button"
            class="btn-update"
            @click="saveDetails()"
          >
            Update
          </button>
        </div>
      </div>

      <!-- DOB row -->
      <div class="row all-text-margin mb-3 mb-4">
        <div class="col-2 col-md-auto">
          <div class="dark-icon-box">
            <svg
              class="svg-icon-position"
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              style="margin-left: 1px"
              viewBox="0 0 58 58"
              enable-background="new 0 0 58 58"
              xml:space="preserve"
              height="28"
              width="28"
            >
              <g>
                <path
                  fill="none"
                  stroke="#ffffff"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-miterlimit="10"
                  d="
		M46.707,37.266H11.239v-9.4c0-2.778,2.252-5.03,5.031-5.03h25.406c2.779,0,5.031,2.252,5.031,5.03V37.266z"
                />
                <path
                  fill="none"
                  stroke="#ffffff"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-miterlimit="10"
                  d="
		M8.477,37.266c0,2.6,2.651,4.707,5.922,4.707h29.201c3.271,0,5.923-2.107,5.923-4.707H8.477z"
                />
                <path
                  fill="none"
                  stroke="#ffffff"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-miterlimit="10"
                  d="
		M11.239,31.033c0,0,5.28,2.045,9.898-0.748c1.084-0.656,2.479-0.549,3.426,0.293c1.532,1.363,4.248,3.018,8.386,2.981
		c0.932-0.008,1.799-0.493,2.324-1.263c1.625-2.376,5.865-7.243,11.434-3.473"
                />
                <path
                  fill="none"
                  stroke="#ffffff"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-miterlimit="10"
                  d="
		M29.525,16.733h-1.104c-1.114,0-2.018,0.903-2.018,2.017v4.085h5.14V18.75C31.543,17.636,30.64,16.733,29.525,16.733z"
                />
                <path
                  fill="none"
                  stroke="#ffffff"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-miterlimit="10"
                  d="
		M28.729,4.428c0,0-1.587,2.874-2.101,5.184c-0.356,1.6,0.487,3.277,2.056,3.752l0.045,0.014c0,0,0.076,0.006,0.205,0.002
		c1.981-0.055,3.116-2.286,2.069-3.967C30.265,8.229,29.215,6.329,28.729,4.428z"
                />

                <line
                  fill="none"
                  stroke="#ffffff"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-miterlimit="10"
                  x1="28.973"
                  y1="11.54"
                  x2="28.973"
                  y2="16.733"
                />
              </g>
            </svg>
          </div>
        </div>
        <div
          class="col-8 left-padding align-self-center"
          v-if="
            (existing_user == 1 && is_date_of_birth_update == 1) ||
            existing_user == 0
          "
        >
          <span class="left-span">
            <label class="edit-profile-text-style">{{ date_of_birth }}</label>
          </span>
        </div>
        <div
          class="col-8 align-middle"
          v-if="existing_user == 1 && is_date_of_birth_update == 0"
        >
          <button
            type="button"
            class="btn-update"
            style="margin-left: -3.65rem !important; height: 100%!important;"
            @click="clickEditDateOfBirth"
          >
            Update
          </button>
        </div>
      </div>
    </div>
    <!-- Start date of birth  modal -->
    <div>
      <b-modal
        ref="input-date-of-birth-modal"
        hide-footer
        v-b-modal.modal-center
        modal-backdrop
        hide-header
        title="Update Date Of Birth"
        id="input-date-of-birth-modal"
        centered
      >
        <div class="color">
          <div class="purchaserpower-modal-text">
            <div class="row mb-4">
              <div class="col-12 remove-padding text-center">
                <span class="bl-heading">Update Date Of Birth</span>
              </div>
            </div>
            <div></div>
            <!-- date of birth row -->
            <div class="row dob-row">
              <!-- element month -->
              <div class="col-5">
                <div class="btn-group">
                  <button
                    id="btn-month"
                    class="btn-reg-dropdown-style border border-dark"
                    aria-haspopup="false"
                    aria-expanded="false"
                    @click="openMonthModal"
                  >
                    <div class="row">
                      <div class="col-6 align-self-center">
                        <span class="text-month font-email float-left">{{
                          dob_month
                        }}</span>
                      </div>
                      <div class="col-6 pr-4">
                        <span class="float-right">
                          <svg
                            version="1.1"
                            id="Layer_1"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            x="0px"
                            y="0px"
                            width="10"
                            height="10"
                            viewBox="0 0 56 48.5"
                            style="enable-background: new 0 0 56 48.5"
                            xml:space="preserve"
                          >
                            <path
                              id="Polygon_1"
                              d="M28,48.5L0,0l56,0L28,48.5z"
                            />
                          </svg>
                        </span>
                      </div>
                    </div>
                  </button>
                </div>
              </div>
              <!-- element day -->
              <div class="col-3" style="padding-left: unset">
                <input
                  v-model="dob_day"
                  class="
                    form-control
                    btn-reg-dropdown-style
                    day-placeholder
                    text-center
                    border border-dark
                  "
                  placeholder="Day"
                  v-on:keyup="dobValidation($event)"
                  @focus="removeattribute($event)"
                  v-on:blur="addZero($event, 'Day')"
                  type="number"
                  pattern="[0-9]*"
                  maxlength="2"
                />
              </div>
              <!-- element year -->
              <div class="col-4" style="padding-left: unset">
                <input
                  v-model="dob_year"
                  type="number"
                  pattern="[0-9]*"
                  class="
                    form-control
                    btn-reg-dropdown-style
                    day-placeholder
                    text-center
                    border border-dark
                  "
                  placeholder="Year"
                  v-on:keyup="isNumber($event)"
                  v-on:blur="checkValidYear($event, 'Year')"
                  @focus="removeattribute($event)"
                  maxlength="4"
                />
              </div>
            </div>

            <br />
            <br />

            <div class="text-center">
              <button
                type="button"
                class="mx-auto col-10 offset-1 btn-black"
                style="height: 60px"
                @click="hideDateOfBirtModal"
              >
                <label class="purchasepower-modal-ok-label">OK</label>
              </button>
            </div>
          </div>
        </div>
      </b-modal>
    </div>
    <!-- End date of birth  modal -->

    <!-- Month dropdown modal -->
    <div>
      <b-modal
        ref="month-modal"
        hide-footer
        hide-header
        size="sm"
        centered
        variant="primary"
        id="month-modal"
      >
        <div>
          <center>
            <div
              v-for="month in months"
              class="row suffix-option"
              @click="setMonth(month)"
              :key="month.key"
            >
              {{ month.title }}
            </div>
          </center>
        </div>
      </b-modal>
    </div>
    <!-- End of Month dropdown modal -->

    <!-- MODAL FOR VALIDATION ERROR MESSAGES -->
    <b-modal
      ref="validation-modal"
      hide-footer
      v-b-modal.modal-center
      modal-backdrop
      hide-header
      id="validation-modal"
      centered
    >
      <div class="color">
        <div class="purchaserpower-modal-text">
          <div class="d-block text-center">
            <label class="purchasepower-def-label">
              {{ error_message }}
            </label>
          </div>
          <br />
          <br />
          <div class="text-center">
            <button
              type="button"
              class="mx-auto col-10 offset-1 btn-black"
              style="height: 60px"
              @click="hidevalidationModal"
            >
              <span class="purchasepower-modal-ok-label">OK</span>
            </button>
          </div>
        </div>
      </div>
    </b-modal>

    <!-- bank account unlink modal alert -->
    <b-modal
      ref="delink-modal"
      hide-footer
      no-close-on-backdrop
      modal-backdrop
      hide-header
      id="delink-modal"
      centered
    >
      <div class="color">
        <div class="col-12 text-center">
          <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width="120"
            height="120"
            viewBox="0 0 100 125"
            style="enable-background: new 0 0 100 125"
            xml:space="preserve"
            fill="#e14343"
          >
            <path
              d="M96.2,47.5l-22-38c-0.4-0.6-1-1-1.7-1h-44c-0.7,0-1.4,0.4-1.7,1l-22,37.9c-0.4,0.6-0.4,1.4,0,2l22,38.1c0.4,0.6,1,1,1.7,1
	h44c0.7,0,1.4-0.4,1.7-1l22-38C96.6,48.9,96.6,48.1,96.2,47.5z M71.3,84.5H29.7L8.8,48.4l20.8-35.9h41.7l20.8,36L71.3,84.5z
	 M50.5,60.5c1.1,0,2-0.9,2-2v-30c0-1.1-0.9-2-2-2c-1.1,0-2,0.9-2,2v30C48.5,59.6,49.4,60.5,50.5,60.5z M48.4,66.4
	c-0.6,0.6-0.9,1.3-0.9,2.1c0,0.8,0.3,1.6,0.9,2.1s1.3,0.9,2.1,0.9c0.8,0,1.6-0.3,2.1-0.9c0.6-0.6,0.9-1.3,0.9-2.1
	c0-0.8-0.3-1.6-0.9-2.1C51.5,65.3,49.5,65.3,48.4,66.4z"
            />
          </svg>
        </div>
        <div class="purchaserpower-modal-text">
          <div class="d-block text-center">
            <label class="purchasepower-def-label">
              The connection to your bank/credit union has disconnected. Please relink your banking to continue.
            </label>
          </div>
          <br />
          <br />
          <div class="row">
            <div class="col-12 text-center">
              <button
                type="button"
                class="mx-auto col-10 offset-1 btn-black"
                style="height: 60px"
                @click="changeBankAccount()"
              >
                <span class="purchasepower-modal-ok-label"
                  >Add Primary Account</span
                >
              </button>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <!-- bank account unlink modal alert -->
    <b-modal
      ref="reset-pin-modal"
      hide-footer
      no-close-on-backdrop
      modal-backdrop
      hide-header
      id="reset-pin-modal"
      centered
    >
      <div class="color">
        <div class="row">
          <div class="col-2"></div>
          <div class="col-8 text-center">
            <label class="success-text-onboarding">
              Reset your 4-digit
              <br />Quick Access PIN
            </label>
          </div>
          <div class="col-2"></div>
        </div>
        <!-- existing pin row -->
        <div class="row mt-3">
          <div class="col-1"></div>
          <div class="col-10 text-center">
            <label class="success-description-label"
              >Please enter current 4 digit PIN</label
            >
          </div>
          <div class="col-1"></div>
        </div>
        <!-- pin row -->
        <div class="row">
          <div class="col-1"></div>
          <div id="curpin_div" class="col-10 text-center m-l-7">
            <input
              id="curpin1"
              autocomplete="nope"
              class="
                form-control
                ssn-text ssn-input-box-padding
                margin-pin-asterisk
                quickaccesspin-input-box-color
              "
              v-on:keyup="isNumber($event)"
              type="text"
              maxlength="1"
              inputmode="numeric"
            />
            <input
              id="curpin2"
              autocomplete="nope"
              class="
                form-control
                ssn-text ssn-input-box-padding
                margin-pin-asterisk
                quickaccesspin-input-box-color
              "
              v-on:keyup="isNumber($event)"
              type="text"
              maxlength="1"
              inputmode="numeric"
            />
            <input
              id="curpin3"
              autocomplete="nope"
              class="
                form-control
                ssn-text ssn-input-box-padding
                margin-pin-asterisk
                quickaccesspin-input-box-color
              "
              v-on:keyup="isNumber($event)"
              type="text"
              maxlength="1"
              inputmode="numeric"
            />
            <input
              id="curpin4"
              autocomplete="nope"
              class="
                form-control
                ssn-text ssn-input-box-padding
                margin-pin-asterisk
                quickaccesspin-input-box-color
              "
              v-on:keyup="isNumber($event)"
              type="text"
              maxlength="1"
              inputmode="numeric"
            /><span
              class="input-group-addon"
              style="vertical-align: super"
              v-on:click="switchCurrentVisibility"
            >
              <a>
                <i
                  class="fa fa-eye-slash eye-icon onboarding-color-black"
                  aria-hidden="true"
                  v-if="!curshow"
                ></i>
                <i
                  class="fa fa-eye eye-icon onboarding-color-black"
                  aria-hidden="true"
                  v-if="curshow"
                ></i>
              </a>
            </span>
          </div>
          <div id="curpin_view_div" class="col-10 text-center m-l-7 visibility">
            <input
              id="curpin1_view"
              v-model="curpin1"
              class="
                form-control
                ssn-text ssn-input-box-padding
                quickaccesspin-input-box-color
              "
              v-on:keyup="isNumber($event)"
              type="text"
              maxlength="1"
              inputmode="numeric"
              readonly
            />
            <input
              id="curpin2_view"
              v-model="curpin2"
              class="
                form-control
                ssn-text ssn-input-box-padding
                quickaccesspin-input-box-color
              "
              v-on:keyup="isNumber($event)"
              type="text"
              maxlength="1"
              inputmode="numeric"
              readonly
            />
            <input
              id="curpin3_view"
              v-model="curpin3"
              class="
                form-control
                ssn-text ssn-input-box-padding
                quickaccesspin-input-box-color
              "
              type="text"
              maxlength="1"
              inputmode="numeric"
              readonly
            />
            <input
              id="curpin4_view"
              v-model="curpin4"
              class="
                form-control
                ssn-text ssn-input-box-padding
                quickaccesspin-input-box-color
              "
              type="text"
              maxlength="1"
              inputmode="numeric"
              readonly
            />
            <span class="input-group-addon" style="vertical-align: super">
              <a v-on:click="switchCurrentVisibility">
                <i
                  class="fa fa-eye-slash eye-icon onboarding-color-black"
                  aria-hidden="true"
                  v-if="!curshow"
                ></i>
                <i
                  class="fa fa-eye eye-icon onboarding-color-black"
                  aria-hidden="true"
                  v-if="curshow"
                ></i>
              </a>
            </span>
          </div>
          <div
            class="
              col-1
              eye-icon-div
              d-flex
              align-items-center
              justify-content-center
            "
          ></div>
          <input type="hidden" id="curpin_current_state" value="0" />
        </div>
        <!-- label 1 -->
        <div class="row mt-5">
          <div class="col-1"></div>
          <div class="col-10 text-center">
            <label class="success-description-label"
              >Please enter a new 4 digit PIN</label
            >
          </div>
          <div class="col-1"></div>
        </div>
        <!-- pin row -->
        <div class="row">
          <div class="col-1"></div>
          <div id="pin_div" class="col-10 text-center m-l-7">
            <input
              id="pin1"
              ref="pin1"
              autocomplete="nope"
              class="
                form-control
                ssn-text ssn-input-box-padding
                margin-pin-asterisk
                quickaccesspin-input-box-color
              "
              v-on:keyup="isNumber($event)"
              type="text"
              maxlength="1"
              inputmode="numeric"
            />
            <input
              id="pin2"
              autocomplete="nope"
              class="
                form-control
                ssn-text ssn-input-box-padding
                margin-pin-asterisk
                quickaccesspin-input-box-color
              "
              v-on:keyup="isNumber($event)"
              type="text"
              maxlength="1"
              inputmode="numeric"
            />
            <input
              id="pin3"
              autocomplete="nope"
              class="
                form-control
                ssn-text ssn-input-box-padding
                margin-pin-asterisk
                quickaccesspin-input-box-color
              "
              v-on:keyup="isNumber($event)"
              type="text"
              maxlength="1"
              inputmode="numeric"
            />
            <input
              id="pin4"
              autocomplete="nope"
              class="
                form-control
                ssn-text ssn-input-box-padding
                margin-pin-asterisk
                quickaccesspin-input-box-color
              "
              v-on:keyup="isNumber($event)"
              type="text"
              maxlength="1"
              inputmode="numeric"
            /><span
              class="input-group-addon"
              style="vertical-align: super"
              v-on:click="switchVisibility"
            >
              <a>
                <i
                  class="fa fa-eye-slash eye-icon onboarding-color-black"
                  aria-hidden="true"
                  v-if="!show"
                ></i>
                <i
                  class="fa fa-eye eye-icon onboarding-color-black"
                  aria-hidden="true"
                  v-if="show"
                ></i>
              </a>
            </span>
          </div>
          <div id="pin_view_div" class="col-10 text-center m-l-7 visibility">
            <input
              id="pin1_view"
              v-model="pin1"
              class="
                form-control
                ssn-text ssn-input-box-padding
                quickaccesspin-input-box-color
              "
              v-on:keyup="isNumber($event)"
              type="text"
              maxlength="1"
              inputmode="numeric"
              readonly
            />
            <input
              id="pin2_view"
              v-model="pin2"
              class="
                form-control
                ssn-text ssn-input-box-padding
                quickaccesspin-input-box-color
              "
              v-on:keyup="isNumber($event)"
              type="text"
              maxlength="1"
              inputmode="numeric"
              readonly
            />
            <input
              id="pin3_view"
              v-model="pin3"
              class="
                form-control
                ssn-text ssn-input-box-padding
                quickaccesspin-input-box-color
              "
              type="text"
              maxlength="1"
              inputmode="numeric"
              readonly
            />
            <input
              id="pin4_view"
              v-model="pin4"
              class="
                form-control
                ssn-text ssn-input-box-padding
                quickaccesspin-input-box-color
              "
              type="text"
              maxlength="1"
              inputmode="numeric"
              readonly
            />
            <span class="input-group-addon" style="vertical-align: super">
              <a v-on:click="switchVisibility">
                <i
                  class="fa fa-eye-slash eye-icon onboarding-color-black"
                  aria-hidden="true"
                  v-if="!show"
                ></i>
                <i
                  class="fa fa-eye eye-icon onboarding-color-black"
                  aria-hidden="true"
                  v-if="show"
                ></i>
              </a>
            </span>
          </div>
          <div
            class="
              col-1
              eye-icon-div
              d-flex
              align-items-center
              justify-content-center
            "
          ></div>
          <input type="hidden" id="pin_current_state" value="0" />
        </div>
        <!-- label 2 -->
        <div class="row mt-3">
          <div class="col-1"></div>
          <div class="col-10 text-center">
            <label class="success-description-label"
              >Re-enter 4 digit PIN</label
            >
          </div>
          <div class="col-1"></div>
        </div>
        <!-- pin row 2 -->
        <div class="row">
          <div class="col-1"></div>
          <div id="re_enter_pin_div" class="col-10 text-center m-l-7">
            <input
              id="re_enter_pin1"
              ref="reenterpin1"
              autocomplete="nope"
              class="
                form-control
                ssn-text ssn-input-box-padding
                margin-pin-asterisk
                quickaccesspin-input-box-color
              "
              v-on:keyup="isNumber($event)"
              type="text"
              maxlength="1"
              inputmode="numeric"
            />
            <input
              id="re_enter_pin2"
              autocomplete="nope"
              class="
                form-control
                ssn-text ssn-input-box-padding
                margin-pin-asterisk
                quickaccesspin-input-box-color
              "
              v-on:keyup="isNumber($event)"
              type="text"
              maxlength="1"
              inputmode="numeric"
            />
            <input
              id="re_enter_pin3"
              autocomplete="nope"
              class="
                form-control
                ssn-text ssn-input-box-padding
                margin-pin-asterisk
                quickaccesspin-input-box-color
              "
              v-on:keyup="isNumber($event)"
              type="text"
              maxlength="1"
              inputmode="numeric"
            />
            <input
              id="re_enter_pin4"
              autocomplete="nope"
              class="
                form-control
                ssn-text ssn-input-box-padding
                margin-pin-asterisk
                quickaccesspin-input-box-color
              "
              v-on:keyup="isNumber($event)"
              @keyup.enter="clickLoginPin"
              type="text"
              maxlength="1"
              inputmode="numeric"
            />
            <span class="input-group-addon" style="vertical-align: super">
              <a v-on:click="switchReEnterVisibility">
                <i
                  class="fa fa-eye-slash eye-icon onboarding-color-black"
                  aria-hidden="true"
                  v-if="!reshow"
                ></i>
                <i
                  class="fa fa-eye eye-icon onboarding-color-black"
                  aria-hidden="true"
                  v-if="reshow"
                ></i>
              </a>
            </span>
          </div>
          <div
            id="re_enter_pin_view_div"
            class="col-10 text-center m-l-7 visibility"
          >
            <input
              id="re_enter_pin1_view"
              v-model="re_enter_pin1"
              class="form-control ssn-text ssn-input-box-padding"
              v-on:keyup="isNumber($event)"
              type="text"
              maxlength="1"
              inputmode="numeric"
              readonly
            />
            <input
              id="re_enter_pin2_view"
              v-model="re_enter_pin2"
              class="form-control ssn-text ssn-input-box-padding"
              v-on:keyup="isNumber($event)"
              type="text"
              maxlength="1"
              inputmode="numeric"
              readonly
            />
            <input
              id="re_enter_pin3_view"
              v-model="re_enter_pin3"
              class="form-control ssn-text ssn-input-box-padding"
              type="text"
              maxlength="1"
              inputmode="numeric"
              readonly
            />
            <input
              id="re_enter_pin4_view"
              v-model="re_enter_pin4"
              class="form-control ssn-text ssn-input-box-padding"
              type="text"
              maxlength="1"
              inputmode="numeric"
              readonly
            />
            <span class="input-group-addon" style="vertical-align: super">
              <a v-on:click="switchReEnterVisibility">
                <i
                  class="fa fa-eye-slash eye-icon onboarding-color-black"
                  aria-hidden="true"
                  v-if="!reshow"
                ></i>
                <i
                  class="fa fa-eye eye-icon onboarding-color-black"
                  aria-hidden="true"
                  v-if="reshow"
                ></i>
              </a>
            </span>
          </div>
          <div
            class="
              col-1
              eye-icon-div
              d-flex
              align-items-center
              justify-content-center
            "
          ></div>
          <input type="hidden" id="re_enter_pin_current_state" value="0" />
        </div>
        <div class="row justify-content-center align-self-center">
          <div class="col-1"></div>
          <div class="col-10 text-center">
            <label class="pin-link-text-style-black" @click="showPinTextbox"
              >Having trouble entering your PIN?</label
            >
          </div>
          <div class="col-1"></div>
        </div>
        <div class="row justify-content-center mt-3">
          <div class="col-10 col-md-8 row">
            <div class="col-6">
              <button
                @click="hideModal('reset-pin-modal')"
                class="profile-btn-cancel w-100"
              >
                <span class="forgetpassword-ok-label">Cancel</span>
              </button>
            </div>
            <div class="col-6">
              <button
                @click="savePIN()"
                class="profile-btn-ok w-100"
              >
                <span class="forgetpassword-ok-label">Save</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <!-- PIN Textbox modal -->
    <b-modal
      ref="pin-textbox-modal"
      hide-footer
      v-b-modal.modal-center
      modal-backdrop
      no-close-on-backdrop
      hide-header
      id="pin-textbox-modal"
      centered
    >
      <div class="color">
        <div class="purchaserpower-modal-text">
          <div class="d-block">
            <label class="update-modal-title"
              >Please enter a current 4 digit PIN.</label
            >
          </div>
          <div class="row">
            <div class="col-10 text-center">
              <input
                type="password"
                name="modal_cur_pin"
                id="modal_cur_pin"
                maxlength="4"
                v-model="modal_cur_pin"
                class="form-control"
              />
            </div>
            <div class="col-2">
              <span
                class="input-group-addon"
                style="vertical-align: middle"
                @click="togglePinInputType('modal_cur_pin')"
                ><a><i aria-hidden="true" :class="inputPinCls"></i></a
              ></span>
            </div>
          </div>
          <div class="d-block">
            <label class="update-modal-title"
              >Please enter a new 4 digit PIN.</label
            >
          </div>
          <div class="row">
            <div class="col-10 text-center">
              <input
                type="password"
                name="modal_pin"
                id="modal_pin"
                maxlength="4"
                v-model="modal_pin"
                class="form-control"
              />
            </div>
            <div class="col-2">
              <span
                class="input-group-addon"
                style="vertical-align: middle"
                @click="togglePinInputType('modal_pin')"
                ><a><i aria-hidden="true" :class="inputPinCls1"></i></a
              ></span>
            </div>
          </div>
          <div class="d-block">
            <label class="update-modal-title">Re-enter 4 digit PIN.</label>
          </div>
          <div class="row">
            <div class="col-10 text-center">
              <input
                type="password"
                name="re_enter_modal_pin"
                id="re_enter_modal_pin"
                maxlength="4"
                v-model="re_enter_modal_pin"
                class="form-control"
              />
            </div>
            <div class="col-2">
              <span
                class="input-group-addon"
                style="vertical-align: middle"
                @click="togglePinInputType('re_enter_modal_pin')"
                ><a><i aria-hidden="true" :class="inputPinCls2"></i></a
              ></span>
            </div>
          </div>
          <div class="row justify-content-center mt-3">
            <div class="col-10 col-md-8 row">
              <div class="col-6">
                <button
                  class="profile-btn-cancel w-100"
                  @click="hidePinTextbox"
                >
                  <span class="forgetpassword-ok-label">Cancel</span></button
                >
              </div>
              <div class="col-6">
                <button
                  class="profile-btn-ok w-100"
                  @click="savePINModal"
                >
                  <span class="forgetpassword-ok-label">Next</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <!-- MODAL FOR EMAIL AND PHONE NUMBER CHANGE  -->
    <b-modal
      ref="edit-profile-verify"
      hide-footer
      v-b-modal.modal-center
      modal-backdrop
      no-close-on-backdrop
      hide-header
      id="edit-profile-verify"
      centered
    >
      <div class="color">
        <div class="purchaserpower-modal-text">
          <div class="d-block text-center">
            <label class="update-modal-title">{{ alertTitle }}</label>
          </div>
          <div class="row">
            <div class="col-12 text-center">
              <input
                :type="updateType == 'email' ? 'email' : 'text'"
                :inputmode="keyboadeType"
                v-model="changeemail"
                class="form-control"
                v-bind:style="{ 'border-color': phoneNumberColor }"
                @change="addEvent"
                v-mask="vmaskType"
                @input="addEvent"
                v-if="!(updateType == 'email')"
              />
              <input
                :type="updateType == 'email' ? 'email' : 'text'"
                :maxlength="maxlengthvalue"
                :inputmode="keyboadeType"
                v-model="changeemail"
                class="form-control text-lowercase"
                v-bind:style="{ 'border-color': phoneNumberColor }"
                @change="addEvent"
                @input="addEvent"
                v-if="updateType == 'email'"
              />
            </div>
          </div>
          <div class="row justify-content-center mt-3">
            <div class="col-10 col-md-8 row">
              <div class="col-6">
                <button
                  @click="hideModal('edit-profile-verify')"
                  class="profile-btn-cancel w-100"
                >
                  <span class="forgetpassword-ok-label">Cancel</span>
                </button>
              </div>
              <div class="col-6">
                <button
                  @click="clickverify"
                  class="profile-btn-ok w-100"
                >
                  <span class="forgetpassword-ok-label">{{
                    sendButtonTitle
                  }}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal
      ref="address-modal"
      hide-footer
      no-close-on-backdrop
      modal-backdrop
      hide-header
      id="address-modal"
      centered
    >
      <div class="color">
        <div class="purchaserpower-modal-text">
          <div class="d-block text-center">
            <label class="purchasepower-def-label">
              Please enter your full address
            </label>
          </div>
          <div class="row">
            <div class="col-12 text-center">
              <gmap-autocomplete
                :value="addressModel"
                placeholder
                @place_changed="setPlace"
                id="address"
                class="autocomplete form-control">
              </gmap-autocomplete>
              
            </div>
          </div>
          <div class="row justify-content-center mt-3">
            <div class="col-10 col-md-8 row">
              <div class="col-6">
                <button
                  @click="hideModal('address-modal')"
                  class="profile-btn-cancel w-100"
                >
                  <span class="forgetpassword-ok-label">Cancel</span>
                </button>
              </div>
              <div class="col-6">
                <button
                  @click="saveDetails()"
                  class="profile-btn-ok w-100"
                >
                  <span class="forgetpassword-ok-label">Save</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal
      ref="blocked-routing-number-modal"
      hide-footer
      v-b-modal.modal-center
      modal-backdrop
      hide-header
      no-close-on-backdrop
      id="blocked-routing-number-modal"
      centered
      title="BootstrapVue"
    >
      <div class="color">
        <div class="purchaserpower-modal-text">
          <div class="pin-success-top-spacing"></div>
          <div class="row" style="margin-bottom: 5px">
            <div class="col-12 text-center">
              <svg
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                width="120"
                height="120"
                viewBox="0 0 100 125"
                style="enable-background: new 0 0 100 125"
                xml:space="preserve"
                fill="#e14343"
              >
                <path
                  d="M96.2,47.5l-22-38c-0.4-0.6-1-1-1.7-1h-44c-0.7,0-1.4,0.4-1.7,1l-22,37.9c-0.4,0.6-0.4,1.4,0,2l22,38.1c0.4,0.6,1,1,1.7,1
h44c0.7,0,1.4-0.4,1.7-1l22-38C96.6,48.9,96.6,48.1,96.2,47.5z M71.3,84.5H29.7L8.8,48.4l20.8-35.9h41.7l20.8,36L71.3,84.5z
M50.5,60.5c1.1,0,2-0.9,2-2v-30c0-1.1-0.9-2-2-2c-1.1,0-2,0.9-2,2v30C48.5,59.6,49.4,60.5,50.5,60.5z M48.4,66.4
c-0.6,0.6-0.9,1.3-0.9,2.1c0,0.8,0.3,1.6,0.9,2.1s1.3,0.9,2.1,0.9c0.8,0,1.6-0.3,2.1-0.9c0.6-0.6,0.9-1.3,0.9-2.1
c0-0.8-0.3-1.6-0.9-2.1C51.5,65.3,49.5,65.3,48.4,66.4z"
                />
              </svg>
            </div>
          </div>
          <div class="d-block text-center">
            <label class="success-popup-style onboarding-modal-message">
              Error:1003. There is a problem with your bank. Please change your bank and try again.
            </label>
          </div>
          <div class="success-bottom-spacing"></div>
        </div>

        <div class="row mt-3">
          <div class="col-12">
            <button
              type="button"
              class="btn-login btn-get-started"
              @click="changeBankAccount"
            >
              Update Bank
            </button>
          </div>
        </div>
      </div>
    </b-modal>
    <real-account-number-modal page_name="profile" ref="RealAccountNumberModal"></real-account-number-modal>


    <b-modal
      class="bg-white"
      ref="dob-update-alert-modal"
      hide-footer
      v-b-modal.modal-center
      modal-backdrop
      hide-header
      no-close-on-backdrop
      id="dob-update-alert-modal"
      centered
      title="BootstrapVue"
    >
      <button type="button" class="close" data-dismiss="dob-update-alert-modal" aria-label="Close" @click="closeDobUpdateModal()">
      <span aria-hidden="true">&times;</span>
    </button>
      <div class="color">
        <div class="purchaserpower-modal-text">
          <div class="row" style="margin-bottom: 5px">
            <div class="col-12 text-center">
              <svg
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                width="120"
                height="120"
                viewBox="0 0 100 125"
                style="enable-background: new 0 0 100 125"
                xml:space="preserve"
                fill="#e14343"
              >
                <path
                  d="M96.2,47.5l-22-38c-0.4-0.6-1-1-1.7-1h-44c-0.7,0-1.4,0.4-1.7,1l-22,37.9c-0.4,0.6-0.4,1.4,0,2l22,38.1c0.4,0.6,1,1,1.7,1
h44c0.7,0,1.4-0.4,1.7-1l22-38C96.6,48.9,96.6,48.1,96.2,47.5z M71.3,84.5H29.7L8.8,48.4l20.8-35.9h41.7l20.8,36L71.3,84.5z
M50.5,60.5c1.1,0,2-0.9,2-2v-30c0-1.1-0.9-2-2-2c-1.1,0-2,0.9-2,2v30C48.5,59.6,49.4,60.5,50.5,60.5z M48.4,66.4
c-0.6,0.6-0.9,1.3-0.9,2.1c0,0.8,0.3,1.6,0.9,2.1s1.3,0.9,2.1,0.9c0.8,0,1.6-0.3,2.1-0.9c0.6-0.6,0.9-1.3,0.9-2.1
c0-0.8-0.3-1.6-0.9-2.1C51.5,65.3,49.5,65.3,48.4,66.4z"
                />
              </svg>
            </div>
          </div>
          <div class="d-block text-center">
            <label class="success-popup-style onboarding-modal-message">
              Please update your date of birth before updating your address
            </label>
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal
    class="bg-white"
    ref="address-update-alert-modal"
    hide-footer
    v-b-modal.modal-center
    modal-backdrop
    hide-header
    no-close-on-backdrop
    id="address-update-alert-modal"
    centered
    title="BootstrapVue"
    >
      <button type="button" class="close" data-dismiss="address-update-alert-modal" aria-label="Close" @click="closeAddressUpdateModal()">
      <span aria-hidden="true">&times;</span>
    </button>
      <div class="color">
        <div class="purchaserpower-modal-text">
          <div class="row" style="margin-bottom: 5px">
            <div class="col-12 text-center">
              <svg
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                width="120"
                height="120"
                viewBox="0 0 100 125"
                style="enable-background: new 0 0 100 125"
                xml:space="preserve"
                fill="#e14343"
              >
                <path
                  d="M96.2,47.5l-22-38c-0.4-0.6-1-1-1.7-1h-44c-0.7,0-1.4,0.4-1.7,1l-22,37.9c-0.4,0.6-0.4,1.4,0,2l22,38.1c0.4,0.6,1,1,1.7,1
h44c0.7,0,1.4-0.4,1.7-1l22-38C96.6,48.9,96.6,48.1,96.2,47.5z M71.3,84.5H29.7L8.8,48.4l20.8-35.9h41.7l20.8,36L71.3,84.5z
M50.5,60.5c1.1,0,2-0.9,2-2v-30c0-1.1-0.9-2-2-2c-1.1,0-2,0.9-2,2v30C48.5,59.6,49.4,60.5,50.5,60.5z M48.4,66.4
c-0.6,0.6-0.9,1.3-0.9,2.1c0,0.8,0.3,1.6,0.9,2.1s1.3,0.9,2.1,0.9c0.8,0,1.6-0.3,2.1-0.9c0.6-0.6,0.9-1.3,0.9-2.1
c0-0.8-0.3-1.6-0.9-2.1C51.5,65.3,49.5,65.3,48.4,66.4z"
                />
              </svg>
            </div>
          </div>
          <div class="d-block text-center">
            <label class="success-popup-style onboarding-modal-message">
              Address can only be changed once in any 6-month period. Please connect with CanPay support if you want any assistance.
              <hr>
              <strong>Update at: {{getFormatedUpdateRemainingDate(address_update_datetime)}}</strong>
            </label>
          </div>
        </div>
      </div>
    </b-modal>
    <!-- input city  modal -->
    <b-modal
        ref="input-field-modal"
        hide-footer
        no-close-on-backdrop
        v-b-modal.modal-center
        modal-backdrop
        hide-header
        id="input-field-modal"
        centered
      >
        <div class="color">
          <div class="purchaserpower-modal-text">
            <form
              ref="form"
              @submit.stop.prevent="save"
              class="needs-validation"
            >
              <div
                class="row"
                v-for="(fieldName, index) in googleNotReadAddress"
                :key="index"
              >
              
                <div class="col-4 mt-2 ">
                  <label class="purchasepower-def-label" style="text-transform: capitalize;">
                    {{ fieldName }}
                  </label>
                </div>
                <div class="col-8 mt-2" v-if="fieldName == 'address'">
                  <input
                    type="text"
                    v-model="street_address"
                    autocomplete="off"
                    class="form-control border border-dark"
                    v-bind:style="{ 'border-color': borderColorAddress }"
                  />
                </div>
                <div class="col-8 mt-2" v-if="fieldName == 'state'">
                  <input
                    type="text"
                    v-model="state"
                    autocomplete="off"
                    class="form-control border border-dark   text-uppercase"
                    v-bind:style="{ 'border-color': borderColorState }"
                    maxlength="2"
                  />
                </div>
                <div class="col-8 mt-2" v-if="fieldName == 'city'">
                  <input
                    v-on:keypress="isLetter($event)"
                    type="text"
                    v-model="city"
                    autocomplete="off"
                    class="form-control border border-dark"
                    v-bind:style="{ 'border-color': borderColorCity }"
                  />
                </div>
                <div class="col-8 mt-2" v-if="fieldName == 'zip'">
                  <input
                    v-on:keypress="zipisNumber($event)"
                    type="text"
                    v-model="zipcode"
                    autocomplete="off"
                    class="form-control border border-dark"
                    v-bind:style="{ 'border-color': borderColorZip }"
                    maxlength="5"
                  />
                </div>
              </div>
              <div class="row justify-content-center mt-3">
                <div class="col-10 col-md-8 row">
                  <div class="col-6">
                    <button
                      @click="hideModal('input-field-modal')"
                      type="button"
                      class="profile-btn-cancel w-100"
                    >
                      <span class="forgetpassword-ok-label">Cancel</span>
                    </button>
                  </div>
                  <div class="col-6">
                    <button
                      @click="hideInputCityModal"
                      type="button"
                      class="profile-btn-ok w-100"
                    >
                      <span class="forgetpassword-ok-label">OK</span>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </b-modal>
      <no-primary-account-modal ref="NoPrimaryAccountModal"></no-primary-account-modal>
  </div>
</div>
</template>

<script>
import api from "../../api/account.js";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import moment from "moment";
import Loading from "vue-loading-overlay";
import RealAccountNumberModal from '../Payment/RealAccountNumberModal.vue';
import NoPrimaryAccountModal from '../Payment/NoPrimaryAccountModal.vue';
import CanPayLoader from "../CustomLoader/CanPayLoader.vue"
/**
 * write a component's description
 * this component is use to login the user as a consumer
 */
export default {
  name: "Profile",
  components: {
    Loading,
    VueGoogleAutocomplete,
    RealAccountNumberModal,
    NoPrimaryAccountModal,
    CanPayLoader
  },
  /**
   * @description-
   * phoneNumber => this will take the consumer's phone no
   * phoneNumber => this will take the consumer's password
   * pinColor => use to validate the password field
   * phoneNumberColor => use to validate the phoneno field
   * token => use to store the user's token
   * @returns {any}
   */
  data() {
    let self = this;
    return {
      newPin: "",
      addressChange: false,
      show_apt_input: false,
      sendButtonTitle: "",
      oldPin: "",
      show: false,
      error_message: "",
      reshow: false,
      curshow: false,
      curpin1: "",
      curpin2: "",
      curpin3: "",
      curpin4: "",
      pin1: "",
      pin2: "",
      pin3: "",
      pin4: "",
      re_enter_pin1: "",
      re_enter_pin2: "",
      re_enter_pin3: "",
      re_enter_pin4: "",
      isLoading: false,
      email: "",
      phoneNumber: "",
      password: "",
      day: "",
      year: "",
      showbuttontitle: "Show",
      phoneNumberColor: "",
      pinColor: "",
      vmaskType: "(###) ###-####",
      maxlengthvalue: "",
      keyboadeType: "",
      updateType: "",
      first_name: "",
      middle_name: "",
      last_name: "",
      phone_no: "",
      full_address: "",
      date_of_birth: "",
      bank_name: "",
      account_type: "",
      account_no: "",
      show_bank: false,
      apt_number: "Apt #",
      existing_user: "",
      reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      vmaskType: "(###) ###-####",
      maxlengthvalue: "",
      changePhone: false,
      keyboadeType: "",
      updateType: "",
      newPhonenumber: "",
      sendButtonTitle: "Verify",
      updatePhone: "phone",
      updateEmail: "email",
      phoneNumberColor: "",
      changeemail: "",
      changePhone: "",
      alertTitle: "",
      months: [
        { title: "Month", key: "" },
        { title: "January", key: 1 },
        { title: "February", key: 2 },
        { title: "March", key: 3 },
        { title: "April", key: 4 },
        { title: "May", key: 5 },
        { title: "June", key: 6 },
        { title: "July", key: 7 },
        { title: "August", key: 8 },
        { title: "September", key: 9 },
        { title: "October", key: 10 },
        { title: "November", key: 11 },
        { title: "December", key: 12 },
      ],
      dob_year: "",
      dob_day: "",
      month_number: "",
      dob_month: "Month",
      formatted_date: "",
      is_date_of_birth_update: "",
      error_message: "",
      addressModel: "",
      modal_cur_pin: "",
      modal_pin: "",
      re_enter_modal_pin: "",
      inputPinCls: "fa fa-eye-slash onboarding-color-black",
      inputPinCls1: "fa fa-eye-slash onboarding-color-black",
      inputPinCls2: "fa fa-eye-slash onboarding-color-black",
      no_active_bank_account:false,
      can_update_address: false,
      address_update_datetime: null,
      address_update_mode: 0,
      checkAddressNotCome: false,
      googleNotReadAddress: [],
      borderColorCity: "",
      borderColorState: "",
      borderColorZip: "",
      borderColorAddress: "",
      country: "",
      usaState: [  
        "AA","AE","AK","AL","AP","AR","AS",
        "AZ","CA","CO","CT","DC","DE","FL","FM","GA",
        "GU","HI","IA","ID","IL","IN","KS",
        "KY","LA","MA","MD","ME","MH","MI","MN","MO","MP",
        "MS","MT","NC","ND","NE","NH","NJ","NM","NV","NY",
        "OH","OK","OR","PA","PR","PW","RI","SC","SD","TN",
        "TX","UT","VA","VI", "VT","WA","WI", "WV", "WY"
      ],
    };
  },
  created() {
    var self = this;
    this.isLoading = true;
    this.$root.$emit("changeWhiteBackground", [false, false, "ProfileHeader"]);
    api
      .getUserdetails()
      .then(function (response) {
        if (response.code == 200) {
          localStorage.setItem(
            "consumer_login_response",
            JSON.stringify(response.data)
          );
          self.setUserDetails(JSON.parse(JSON.stringify(response)));
          self.isLoading = false;
          localStorage.setItem("blocked_routing_number",response.data.blocked_routing_number)
          if (response.data.blocked_routing_number) {
          setTimeout(() => {
          self.$refs['blocked-routing-number-modal'].show();
      }, 1200);
          
            }
        }
      })
      .catch(function (error) {
        self.isLoading = false;
      });
  },
  mounted() {
    let self = this;
    self.$root.$emit("changeWhiteBackground", [false, false, "ProfileHeader"]);
    var element = document.getElementsByClassName("content-wrap");
    if (element[0]) {
      element[0].style.setProperty("background-color", "#149240");
    }
    //catching the phone otp pin data when emitted
    self.$root.$on("edit profile clicked", function (data) {
      this.$router.go(-1);
    });
    setTimeout(() => {
      self.$refs.RealAccountNumberModal.checkForRealAccountNumber();
    }, 2000);
  },
  methods: {
    showValidationModal(msg) {
      this.error_message = msg;
      this.$refs["validation-modal"].show();
    },
    hidevalidationModal() {
      this.error_message = "";
      this.$refs["validation-modal"].hide();
    },
    showPinTextbox() {
      this.inputPinCls = "fa fa-eye-slash onboarding-color-black";
      this.inputPinCls1 = "fa fa-eye-slash onboarding-color-black";
      this.inputPinCls2 = "fa fa-eye-slash onboarding-color-black";
      this.$refs["pin-textbox-modal"].show();
    },
    hidePinTextbox() {
      this.modal_cur_pin = "";
      this.modal_pin = "";
      this.re_enter_modal_pin = "";
      this.$refs["pin-textbox-modal"].hide();
    },
    togglePinInputType(id) {
      var self = this;
      var inputType = document.getElementById(id);
      if (inputType.type === "password") {
        inputType.type = "text";
        if (id == "modal_cur_pin") {
          self.inputPinCls = "fa fa-eye onboarding-color-black";
        } else if (id == "modal_pin") {
          self.inputPinCls1 = "fa fa-eye onboarding-color-black";
        } else {
          self.inputPinCls2 = "fa fa-eye onboarding-color-black";
        }
      } else {
        inputType.type = "password";
        if (id == "modal_cur_pin") {
          self.inputPinCls = "fa fa-eye-slash onboarding-color-black";
        } else if (id == "modal_pin") {
          self.inputPinCls1 = "fa fa-eye-slash onboarding-color-black";
        } else {
          self.inputPinCls2 = "fa fa-eye-slash onboarding-color-black";
        }
      }
    },
    formatPhoneNumber(str) {
      var res = str.substring(0, 3) + ".";
      var res1 = str.substring(3, 6) + ".";
      var res2 = str.substring(6, 10);
      str = res + res1 + res2;
      return str;
    },
    setUserDetails(response) {
      let self = this;
      self.first_name = response.data.first_name;
      self.middle_name = response.data.middle_name;
      self.last_name = response.data.last_name;
      self.phone_no = self.formatPhoneNumber(response.data.phone);
      self.email = response.data.email;
      self.date_of_birth = response.data.date_of_birth;
      self.street_address = response.data.street_address;
      self.apt_number =
        response.data.apt_number != null
          ? response.data.apt_number
          : "Apt or Unit #";
      self.city = response.data.city;
      self.state = response.data.state;
      self.zipcode = response.data.zipcode;
      self.driving_license = response.data.driving_license;
      self.state_issued = response.data.state_issued;
      self.ssn_number = response.data.ssn_number;
      self.bank_name = response.data.bank_name;
      self.account_type = response.data.account_type;
      self.account_no = response.data.account_no;
      self.existing_user = response.data.existing_user;
      self.is_date_of_birth_update = response.data.is_date_of_birth_update;
      if (response.data.bank_link_type == 1) {
        self.show_bank = true;
      }
      if (response.data.no_active_bank_account) {
        self.no_active_bank_account = true;
      } 
      self.full_address =
        self.street_address +
        " " +
        self.city +
        ", " +
        self.state +
        " " +
        self.zipcode;
      if (self.date_of_birth) {
        self.date_of_birth = moment(String(self.date_of_birth)).format(
          "MMM DD, YYYY"
        );
      }
      self.can_update_address = response.data.can_update_address
      self.address_update_datetime = response.data.address_update_datetime
      self.address_update_mode = response.data.address_update_mode
    },
    clickEditDateOfBirth() {
      this.$refs["dob-update-alert-modal"].hide();
      this.$refs["input-date-of-birth-modal"].show();
    },
    closeDobUpdateModal(){
      this.$refs["dob-update-alert-modal"].hide();
    },
    closeAddressUpdateModal(){
      this.$refs["address-update-alert-modal"].hide();
    },
    hideDateOfBirtModal() {
      if (
        this.month_number == "" ||
        this.dob_day == "" ||
        this.dob_year == ""
      ) {
        return false;
      }

      if (
        this.month_number == 2 &&
        this.dob_day == 29 &&
        this.dob_year % 4 != 0
      ) {
        // self.showValidationModal("Date of birth must be valid.");
        return false;
      }
      var formatted_date =
        this.month_number + "-" + this.dob_day + "-" + this.dob_year;
      var date = moment(formatted_date, "MM-DD-YYYY");
      if (!date.isValid()) {
        // self.showValidationModal("Date of birth must be valid.");
        return false;
      }
      this.updateDateOfBirth();
      this.$refs["input-date-of-birth-modal"].hide();
    },
    //opens month option modal
    openMonthModal() {
      this.$refs["month-modal"].show();
      setTimeout(function () {
        var element = document.getElementsByClassName("modal-sm")[0];
        element.classList.add("month-modal-sm");
      }, 10);
    },
    //closes month option modal
    hideMonthModal() {
      this.$refs["month-modal"].hide();
    },
    //checks if the value entered is number or not
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var self = this;
      if (evt.keyCode == 8 || evt.keyCode == 46) {
        if (evt.target.previousElementSibling != null) {
          evt.target.previousElementSibling.focus();
        }
      } else {
        if (evt.target.nextElementSibling != null) {
          evt.target.nextElementSibling.focus();
        }
        if (evt.target.id === "curpin4") {
          self.$refs.pin1.focus();
        }
        if (evt.target.id === "pin4") {
          self.$refs.reenterpin1.focus();
        }
      }
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode < 48 || charCode > 57) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    //this function validated dob day just like a datepicker
    dobValidation(evt) {
      if (this.isNumber(evt)) {
        // case 1: if month is february then maximum 29 should be day limit (Leap year vallidation is handled in a different function)
        // case 2: months with 31 days
        //case 3: months with 30 days
        var limit = this.month_number % 2 == 0 ? 30 : 31;
        if (
          this.month_number == 8 ||
          this.month_number == 10 ||
          this.month_number == 12
        ) {
          limit = 31;
        }
        limit = this.month_number == 2 ? 29 : limit;
        if (this.dob_day > limit) {
          this.dob_day = this.day;
          if (this.dob_day.length < 2) {
            this.dob_day = 0 + this.dob_day;
          }
        }
        this.day = this.dob_day;
      }
    },
    //checks if proper year format is entered or not
    checkValidYear(el, placeholder) {
      if (typeof el.target === "undefined") {
        document.getElementById(el).setAttribute("placeholder", placeholder);
      } else {
        el.target.setAttribute("placeholder", placeholder);
      }
      if (this.dob_year.length < 4 && this.dob_year.length != 0) {
        this.showValidationModal("Please enter a four digit year.");
      }
    },
    //adds zero to the day
    addZero(el, placeholder) {
      if (typeof el.target === "undefined") {
        document.getElementById(el).setAttribute("placeholder", placeholder);
      } else {
        el.target.setAttribute("placeholder", placeholder);
      }
      if (this.dob_day.length < 2 && this.dob_day.length != 0) {
        this.dob_day = 0 + this.dob_day;
      }
    },
    //sets the value of month variable according to the value selected in the month option modal
    setMonth(month) {
      this.dob_month = month.title;
      this.month_number = month.key;
      this.hideMonthModal();
    },
    removeattribute(el) {
      if (typeof el.target === "undefined") {
        document.getElementById(el).removeAttribute("placeholder");
      } else {
        el.target.removeAttribute("placeholder");
      }
    },
    setattribute(el, placeholder) {
      if (typeof el.target === "undefined") {
        document.getElementById(el).setAttribute("placeholder", placeholder);
      } else {
        el.target.setAttribute("placeholder", placeholder);
      }
    },
    updateDateOfBirth() {
      var self = this;
      this.isLoading = true;
      this.$root.$emit("changeWhiteBackground", [
        false,
        false,
        "ProfileHeader",
      ]);
      var request = {
        dateOfBirth:
          this.month_number + "-" + this.dob_day + "-" + this.dob_year,
      };
      api
        .updateDateOfBirth(request)
        .then(function (response) {
          if (response.code == 200) {
            self.isLoading = false;
            self.existing_user = response.data.existing_user;
            self.is_date_of_birth_update =
              response.data.is_date_of_birth_update;
            self.date_of_birth = response.data.date_of_birth;
            if (self.date_of_birth) {
              self.date_of_birth = moment(String(self.date_of_birth)).format(
                "MMM DD, YYYY"
              );
            }
            this.$router.push("/profile");
          }
        })
        .catch(function (error) {
          self.showValidationModal(error.response.data.message);
          self.isLoading = false;
        });
    },
    showValidationModal(msg) {
      this.error_message = msg;
      this.$refs["validation-modal"].show();
    },
    hidevalidationModal() {
      this.error_message = "";
      this.$refs["validation-modal"].hide();
    },
    // generates the connect link and opens finicity portal to link bank account
    getConnectLink() {
      var self = this;
      self.$refs["delink-modal"].hide();
      self.isLoading = true;
      api
        .updateLinkGenerate()
        .then((response) => {
          if (response.code == 200) {
            const finicityConnectUrl = response.data.link;
            window.finicityConnect.launch(finicityConnectUrl, {
              selector: "#connect-container",
              overlay: "rgba(255,255,255, 0)",
              success: function (data) {
                console.log("Yay! We got data", data);
                if (data.code == 200) {
                  //now store the details at canpay end
                  self.storeBankDetails();
                }
              },
              cancel: function () {
                self.isLoading = false;
                console.log("The user cancelled the iframe");
              },
              error: function (err) {
                self.isLoading = false;
                console.error(
                  "Some runtime error was generated during Finicity Connect",
                  err
                );
              },
              loaded: function () {
                self.isLoading = false;
                console.log(
                  "This gets called only once after the iframe has finished loading"
                );
              },
              route: function (event) {
                self.isLoading = false;
              },
              user: function (event) {
                if (event.data.errorCode) {
                  console.log(event.data.data.institutionId);
                  setTimeout(() => {
                    window.finicityConnect.destroy();
                    //if error code is present then call the connect fix api
                    self.generateConnectFix(event.data.data.institutionId);
                  }, 2000);
                }
              },
            });
          } else {
            self.isLoading = false;
          }
        })
        .catch((err) => {
          self.isLoading = false;
          console.log(err);
        });
    },
    generateConnectFix(id) {
      let self = this;
      this.isLoading = true;
      var request = {
        institution_id: id,
      };
      api
        .generateConnectFix(request)
        .then((response) => {
          if (response.code == 200) {
            const finicityConnectUrl = response.data.link;
            window.finicityConnect.launch(finicityConnectUrl, {
              selector: "#connect-container",
              overlay: "rgba(255,255,255, 0)",
              success: function (data) {
                console.log("Yay! We got data", data);
                if (data.code == 200) {
                  //now store the details at canpay end
                  self.storeBankDetails();
                }
              },
              cancel: function () {
                self.isLoading = false;
                console.log("The user cancelled the iframe");
              },
              error: function (err) {
                console.log(err);
                self.isLoading = false;
                console.error(
                  "Some runtime error was generated during Finicity Connect",
                  err
                );
              },
              loaded: function () {
                self.isLoading = false;
                console.log(
                  "This gets called only once after the iframe has finished loading"
                );
              },
              route: function (event) {
                self.isLoading = false;
                console.log(
                  "This is called as the user progresses through Connect"
                );
              },
              user: function (event) {
                if (event.data.errorCode) {
                  console.log(event.data.data.institutionId);
                  setTimeout(() => {
                    window.finicityConnect.destroy();
                    //if error code is present then call the connect fix api
                    self.generateConnectFix(event.data.data.institutionId);
                  }, 2000);
                }
              },
            });
          } else {
            self.isLoading = false;
          }
        })
        .catch((err) => {
          self.isLoading = false;
          console.log(err);
        });
    },
    //calls the api to change bank account through finicity portal
    changeBankAccount() {
      let self = this;
      self.$refs['blocked-routing-number-modal'].show();
      self.$refs["delink-modal"].hide();
      self.$router.push("/banklinking");
    },
    //stores the bank details into canpay end
    storeBankDetails() {
      var self = this;
      this.isLoading = true;
      api
        .updateBank()
        .then((response) => {
          if (response.code == 200) {
            this.getUserDetails();
            setTimeout(() => {
              this.$refs.RealAccountNumberModal.checkForRealAccountNumber();
            }, 2000);
          }
        })
        .catch(function (error) {
          self.isLoading = false;
          if (error.response.data.code == 598) {
            self.$refs["delink-modal"].show();
          }else if(error.response.data.code == 597){
            self.generateConnectFix(
              error.response.data.data.institutionId
            );
          } else {
            self.showValidationModal(error.response.data.message);
          }
        });
    },
    gotoBankListing() {
      this.$router.push("/banklisting");
    },
    maskCurPin() {
      var self = this;
      var ysp1 = new YouShallPass("*");
      var ysp2 = new YouShallPass("*");
      var ysp3 = new YouShallPass("*");
      var ysp4 = new YouShallPass("*");
      ysp1.fixIE9();
      ysp2.fixIE9();
      ysp3.fixIE9();
      ysp4.fixIE9();
      document
        .querySelector("#curpin1")
        .addEventListener("input", ysp1.keyboardInputHandle.bind(ysp1));
      document.querySelector("#curpin1").addEventListener("input", function () {
        self.curpin1 = ysp1.realText;
      });
      document
        .querySelector("#curpin2")
        .addEventListener("input", ysp2.keyboardInputHandle.bind(ysp2));
      document.querySelector("#curpin2").addEventListener("input", function () {
        self.curpin2 = ysp2.realText;
      });
      document
        .querySelector("#curpin3")
        .addEventListener("input", ysp3.keyboardInputHandle.bind(ysp3));
      document.querySelector("#curpin3").addEventListener("input", function () {
        self.curpin3 = ysp3.realText;
      });
      document
        .querySelector("#curpin4")
        .addEventListener("input", ysp4.keyboardInputHandle.bind(ysp4));
      document.querySelector("#curpin4").addEventListener("input", function () {
        self.curpin4 = ysp4.realText;
      });
    },
    maskPin() {
      var self = this;
      var ysp1 = new YouShallPass("*");
      var ysp2 = new YouShallPass("*");
      var ysp3 = new YouShallPass("*");
      var ysp4 = new YouShallPass("*");
      ysp1.fixIE9();
      ysp2.fixIE9();
      ysp3.fixIE9();
      ysp4.fixIE9();
      document
        .querySelector("#pin1")
        .addEventListener("input", ysp1.keyboardInputHandle.bind(ysp1));
      document.querySelector("#pin1").addEventListener("input", function () {
        self.pin1 = ysp1.realText;
      });
      document
        .querySelector("#pin2")
        .addEventListener("input", ysp2.keyboardInputHandle.bind(ysp2));
      document.querySelector("#pin2").addEventListener("input", function () {
        self.pin2 = ysp2.realText;
      });
      document
        .querySelector("#pin3")
        .addEventListener("input", ysp3.keyboardInputHandle.bind(ysp3));
      document.querySelector("#pin3").addEventListener("input", function () {
        self.pin3 = ysp3.realText;
      });
      document
        .querySelector("#pin4")
        .addEventListener("input", ysp4.keyboardInputHandle.bind(ysp4));
      document.querySelector("#pin4").addEventListener("input", function () {
        self.pin4 = ysp4.realText;
      });
    },
    maskReEnterPin() {
      var self = this;
      var ysp1 = new YouShallPass("*");
      var ysp2 = new YouShallPass("*");
      var ysp3 = new YouShallPass("*");
      var ysp4 = new YouShallPass("*");
      ysp1.fixIE9();
      ysp2.fixIE9();
      ysp3.fixIE9();
      ysp4.fixIE9();
      document
        .querySelector("#re_enter_pin1")
        .addEventListener("input", ysp1.keyboardInputHandle.bind(ysp1));
      document
        .querySelector("#re_enter_pin1")
        .addEventListener("input", function () {
          self.re_enter_pin1 = ysp1.realText;
        });
      document
        .querySelector("#re_enter_pin2")
        .addEventListener("input", ysp2.keyboardInputHandle.bind(ysp2));
      document
        .querySelector("#re_enter_pin2")
        .addEventListener("input", function () {
          self.re_enter_pin2 = ysp2.realText;
        });
      document
        .querySelector("#re_enter_pin3")
        .addEventListener("input", ysp3.keyboardInputHandle.bind(ysp3));
      document
        .querySelector("#re_enter_pin3")
        .addEventListener("input", function () {
          self.re_enter_pin3 = ysp3.realText;
        });
      document
        .querySelector("#re_enter_pin4")
        .addEventListener("input", ysp4.keyboardInputHandle.bind(ysp4));
      document
        .querySelector("#re_enter_pin4")
        .addEventListener("input", function () {
          self.re_enter_pin4 = ysp4.realText;
        });
    },
    switchVisibility() {
      var self = this;
      if ($("#pin_current_state").val() == 0) {
        $("#pin_current_state").val("1");
        self.show = true;
        $("#pin_div").addClass("visibility");
        $("#pin_view_div").removeClass("visibility");
      } else {
        $("#pin_current_state").val("0");
        self.show = false;
        $("#pin_view_div").addClass("visibility");
        $("#pin_div").removeClass("visibility");
      }
    },
    switchCurrentVisibility() {
      var self = this;
      if ($("#curpin_current_state").val() == 0) {
        $("#curpin_current_state").val("1");
        self.curshow = true;
        $("#curpin_div").addClass("visibility");
        $("#curpin_view_div").removeClass("visibility");
      } else {
        $("#curpin_current_state").val("0");
        self.curshow = false;
        $("#curpin_view_div").addClass("visibility");
        $("#curpin_div").removeClass("visibility");
      }
    },
    switchReEnterVisibility() {
      var self = this;
      if ($("#re_enter_pin_current_state").val() == 0) {
        $("#re_enter_pin_current_state").val("1");
        self.reshow = true;
        $("#re_enter_pin_div").addClass("visibility");
        $("#re_enter_pin_view_div").removeClass("visibility");
      } else {
        $("#re_enter_pin_current_state").val("0");
        self.reshow = false;
        $("#re_enter_pin_view_div").addClass("visibility");
        $("#re_enter_pin_div").removeClass("visibility");
      }
    },
    savePIN() {
      let self = this;
      const regex = /[0-9]/g;
      if (
        self.curpin1 == "" ||
        self.curpin2 == "" ||
        self.curpin3 == "" ||
        self.curpin4 == ""
      ) {
        self.showValidationModal("Please enter your current access pin.");
        return false;
      }
      if (
        self.pin1 == "" ||
        self.pin2 == "" ||
        self.pin3 == "" ||
        self.pin4 == "" ||
        self.re_enter_pin1 == "" ||
        self.re_enter_pin2 == "" ||
        self.re_enter_pin3 == "" ||
        self.re_enter_pin4 == ""
      ) {
        return false;
      } else if (
        self.pin1 + self.pin2 + self.pin3 + self.pin4 !==
        self.re_enter_pin1 +
          self.re_enter_pin2 +
          self.re_enter_pin3 +
          self.re_enter_pin4
      ) {
        self.showValidationModal("Pin does not match.");
        return false;
      } else if (
        isNaN(self.pin1 + self.pin2 + self.pin3 + self.pin4) ||
        isNaN(
          self.re_enter_pin1 +
            self.re_enter_pin2 +
            self.re_enter_pin3 +
            self.re_enter_pin4
        ) ||
        isNaN(self.curpin1 + self.curpin2 + self.curpin3 + self.curpin4)
      ) {
        self.showValidationModal("Pin must be numeric");
        return false;
      } else {
        this.oldPin = self.curpin1 + self.curpin2 + self.curpin3 + self.curpin4;
        this.newPin = self.pin1 + self.pin2 + self.pin3 + self.pin4;
        this.confirmPin =
          self.re_enter_pin1 +
          self.re_enter_pin2 +
          self.re_enter_pin3 +
          self.re_enter_pin4;
        var request = {
          old_password: this.oldPin,
          new_password: this.newPin,
          confirm_password: this.confirmPin,
        };
        self.hideModal("reset-pin-modal");
        self.isLoading = true;
        api
          .changePIN(request)
          .then(function (response) {
            if (response.code == 200) {
              self.isLoading = false;
              self.showValidationModal(response.message);
            }
          })
          .catch(function (error) {
            self.isLoading = false;
            self.showValidationModal(error.response.data.message);
          });
      }
    },
    savePINModal() {
      let self = this;
      const regex = /[0-9]/g;
      if (self.modal_cur_pin == "") {
        self.showValidationModal("Please enter your current access pin.");
        return false;
      }
      if (self.modal_pin == "" || self.re_enter_modal_pin == "") {
        return false;
      } else if (self.modal_pin !== self.re_enter_modal_pin) {
        self.showValidationModal("Pin does not match.");
        return false;
      } else if (
        isNaN(self.modal_pin) ||
        isNaN(self.re_enter_modal_pin) ||
        isNaN(self.modal_cur_pin)
      ) {
        self.showValidationModal("Pin must be numeric");
        return false;
      } else {
        this.oldPin = self.modal_cur_pin;
        this.newPin = self.modal_pin;
        this.confirmPin = self.re_enter_modal_pin;
        var request = {
          old_password: this.oldPin,
          new_password: this.newPin,
          confirm_password: this.confirmPin,
        };
        self.hideModal("pin-textbox-modal");
        self.hideModal("reset-pin-modal");
        self.modal_cur_pin = "";
        self.modal_pin = "";
        self.re_enter_modal_pin = "";
        self.isLoading = true;
        api
          .changePIN(request)
          .then(function (response) {
            if (response.code == 200) {
              self.isLoading = false;
              self.showValidationModal(response.message);
            }
          })
          .catch(function (error) {
            self.isLoading = false;
            self.showValidationModal(error.response.data.message);
          });
      }
    },
    // opens reset modal
    resetPIN() {
      this.$refs["reset-pin-modal"].show();
      var self = this;
      setTimeout(() => {
        self.maskCurPin();
        self.maskPin();
        self.maskReEnterPin();
      }, 300);
    },
    hideModal(modal) {
      if (modal === "edit-profile-verify") {
        this.changeemail = "";
        this.newPhonenumber = "";
      }
      if (modal === "address-modal") {
        this.addressModel = "";
        var user_details = localStorage.getItem("consumer_login_response")
        ? JSON.parse(localStorage.getItem("consumer_login_response"))
        : null;
        if(user_details){
          this.street_address = user_details.street_address;
          this.city = user_details.city;
          this.state = user_details.state;
          this.zipcode = user_details.zipcode;
        }
      }
      if (modal === "input-field-modal") {
        this.$refs[modal].hide();
        this.$refs["address-modal"].show();
      }else{
        this.$refs[modal].hide();
      }
    },
    clickchange(type) {
      if (type == "phone") {
        this.maxlengthvalue = "10";
        this.keyboadeType = "";
        this.alertTitle =
          "Enter verification code sent on your phone and email.";
        this.vmaskType = "XXXXXX";
      } else {
        this.maxlengthvalue = "6";
        this.keyboadeType = "email";
        this.alertTitle = "Enter verification code sent on your phone.";
      }
      this.updateType = type;
      this.sendVerificationCode_Phone_Email(type);
    },
    /**
     * @description
     */
    addEvent() {
      this.phoneNumberColor = "";
    },
    /**
     * @description
     */
    sendVerificationCode_Phone_Email(updatetype) {
      let self = this;
      var request = {
        change_request_type: updatetype,
      };
      self.phoneNumberColor = "";
      self.pinColor = "";
      api
        .sendVerificationCode(request)
        .then((response) => {
          if (response.code == 200) {
            self.sendButtonTitle = "Verify";
            self.$refs["edit-profile-verify"].show();
          }
        })
        .catch(function (error) {
          self.showValidationModal(error.response.data.message);
        });
    },

    /**
     * @description
     */
    verifyVerificationCode_Phone_Email() {
      let self = this;
      var phone = self.phone_no.replace(/[^a-zA-Z0-9]/g, "");
      var request = {
        phone_no: phone,
        verification_code: this.changeemail,
      };
      self.phoneNumberColor = "";
      self.pinColor = "";
      api
        .verifyVerificationCode(request)
        .then((response) => {
          if (response.code == 200) {
            self.changeemail = "";
            if (self.updateType == "email") {
              self.alertTitle = "Enter new email";
              self.keyboadeType = "email";
              self.maxlengthvalue = "50";
              self.sendButtonTitle = "Submit";
              this.vmaskType =
                "^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$";
            } else if (self.updateType == "phone") {
              self.alertTitle = "Enter new phone number";
              self.keyboadeType = "numeric";
              self.maxlengthvalue = "10";
              self.sendButtonTitle = "Submit";
              self.vmaskType = "(###) ###-####";
            }
          }
        })
        .catch(function (error) {
          self.showValidationModal(error.response.data.message);
        });
    },
    /**
     * @description
     */
    modifyEmail() {
      let self = this;
      var request = {
        email: this.changeemail.toLowerCase(),
      };
      self.phoneNumberColor = "";
      self.pinColor = "";
      api
        .updateNewEmail(request)
        .then((response) => {
          if (response.code == 200) {
            self.changeemail = "";
            self.hideModal("edit-profile-verify");
            self.getUserDetails();
          }
        })
        .catch(function (error) {
          self.showValidationModal(error.response.data.message);
        });
    },
    /**
     * @description
     */
    sendOTPNewPhoneNumber() {
      let self = this;
      var user_details = localStorage.getItem("consumer_login_response")
      ? JSON.parse(localStorage.getItem("consumer_login_response"))
      : null;
      self.changeemail = self.changeemail.replace(/[^a-zA-Z0-9]/g, "");
      var request = {
        phoneNo: this.changeemail,
        sessionId: Math.floor(Math.random() * (99999999 - 100000) + 100000),
        user_id:user_details.user_id,
      };
      self.phoneNumberColor = "";
      self.pinColor = "";
      api
        .sendOtpyNewPhone(request)
        .then((response) => {
          if (response.code == 200) {
            self.newPhonenumber = self.changeemail;
            self.changeemail = "";
            self.updateType = "new phone";
            self.alertTitle =
              "Enter verification code sent on your new phone number.";
            this.vmaskType = "XXXXXX";

            self.keyboadeType = "";
            self.sendButtonTitle = "Verify";
            self.changePhone = false;
          }
        })
        .catch(function (error) {
          self.showValidationModal(error.response.data.message);
        });
    },
    /**
     * @description
     */
    verifyNewPhoneNumber() {
      let self = this;
      self.newPhonenumber = self.newPhonenumber.replace(/[^a-zA-Z0-9]/g, "");
      var request = {
        phoneNo: this.newPhonenumber,
        otp: this.changeemail,
      };
      self.phoneNumberColor = "";
      self.pinColor = "";
      api
        .verifyNewPhone(request)
        .then((response) => {
          if (response.code == 200) {
            self.changeemail = "";
            this.newPhonenumber = "";
            self.hideModal("edit-profile-verify");
            self.getUserDetails();
          }
        })
        .catch(function (error) {
          self.showValidationModal(error.response.data.message);
        });
    },
    clickverify() {
      let self = this;
      if (self.changePhone == true) {
        self.updateType = "new phone";
        self.sendOTPNewPhoneNumber();
      }
      if (self.sendButtonTitle == "Verify" && self.updateType == "phone") {
        if (self.changeemail == "") {
          self.phoneNumberColor = "red";
          return false;
        } else {
          self.verifyVerificationCode_Phone_Email();
        }
      } else if (
        self.sendButtonTitle == "Verify" &&
        self.updateType == "email"
      ) {
        if (self.changeemail == "") {
          self.phoneNumberColor = "red";
          return false;
        } else {
          self.verifyVerificationCode_Phone_Email();
        }
      } else if (
        self.sendButtonTitle == "Submit" &&
        self.updateType == "email"
      ) {
        if (self.changeemail.length == 0) {
          self.phoneNumberColor = "red";
          return false;
        } else if (self.isEmailValid() == "has-error") {
          self.phoneNumberColor = "red";
          this.showValidationModal("Please enter valid email address.");
          return false;
        } else {
          self.phoneNumberColor = "";
          self.modifyEmail();
        }
      } else if (
        self.sendButtonTitle == "Submit" &&
        self.updateType == "phone"
      ) {
        if (self.changeemail == "") {
          self.phoneNumberColor = "red";
          return false;
        } else {
          self.updateType = "new phone";
          self.sendOTPNewPhoneNumber();
        }
      } else if (
        self.sendButtonTitle == "Verify" &&
        self.updateType == "new phone"
      ) {
        if (self.changeemail == "") {
          self.phoneNumberColor = "red";
          return false;
        } else {
          self.verifyNewPhoneNumber();
        }
      }
    },
    formatPhoneNumber(str) {
      var res = str.substring(0, 3) + ".";
      var res1 = str.substring(3, 6) + ".";
      var res2 = str.substring(6, 10);
      str = res + res1 + res2;
      return str;
    },
    isEmailValid: function () {
      return this.changeemail == ""
        ? ""
        : this.reg.test(this.changeemail)
        ? "has-success"
        : "has-error";
    },
    getUserDetails() {
      var self = this;
      self.isLoading = true;
      api
        .getUserdetails()
        .then(function (response) {
          if (response.code == 200) {
            localStorage.setItem(
              "consumer_login_response",
              JSON.stringify(response.data)
            );
            self.setUserDetails(JSON.parse(JSON.stringify(response)));
            self.isLoading = false;
          }
        })
        .catch(function (error) {
          self.isLoading = false;
        });
    },
    checkAddressUpdate(){
      var self = this;
      if(self.can_update_address == true && (self.existing_user == 1 && self.is_date_of_birth_update == 0)){
        this.$refs["dob-update-alert-modal"].show();
      }else if(self.can_update_address == false && (self.existing_user == 1 && self.is_date_of_birth_update == 0)){
        this.$refs["dob-update-alert-modal"].show();
      }else if(self.can_update_address == false){
        this.$refs["address-update-alert-modal"].show();
      }else{
        self.showAddressInput();
      }
    },
    showAddressInput() {
      this.addressModel = this.full_address;
        this.$refs["dob-update-alert-modal"].hide();
      this.$refs["address-modal"].show();
    },
    //checks if the value entered is number or not
    zipisNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode < 48 || charCode > 57) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    // check the value enter is charecter
    isLetter(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[a-zA-Z\s]+$/.test(char)) return true;
      // Match with regex
      else e.preventDefault(); // If not match, don't add to input text
    },
    hideInputCityModal() {
      var self = this;
      console.log("Address: "+self.street_address);
      console.log("City: "+self.city);
      console.log("State: "+self.state);
      console.log("Zip: "+self.zipcode);
      console.log("Country: "+self.country);
      let checkValue = [];

        if (this.state.trim() != "") {
          this.state = this.state.toUpperCase();
          this.borderColorState = "";
          checkValue.push(true);
        } else {
          checkValue.push(false);
          this.borderColorState = "#e14343 !important";
        }

        if (this.city.trim() != "") {
          this.city = this.city.trim();
          this.borderColorCity = "";

          checkValue.push(true);
        } else {
          checkValue.push(false);
          this.borderColorCity = "#e14343 !important";
        }

        if (this.zipcode.trim() != "") {
          this.zipcode = this.zipcode.trim();
          this.borderColorZip = "";

          checkValue.push(true);
        } else {
          checkValue.push(false);
          this.borderColorZip = "#e14343 !important";
        }

        if (this.street_address.trim() != "") {
          this.street_address = this.street_address.trim();
          this.borderColorAddress = "";

          checkValue.push(true);
        } else {
          checkValue.push(false);
          this.borderColorAddress = "#e14343 !important";
        }
        if(this.street_address != ''){
          this.addressModel = this.street_address;
        }
        if(this.city != ''){
          this.addressModel = this.addressModel + ", " + this.city;
        }
        if(this.state != ''){
          this.addressModel = this.addressModel + ", " +this.state;
        }
        if(this.zipcode != ''){
          this.addressModel = this.addressModel + " " +this.zipcode;
        }
        if(this.country != ''){
          this.addressModel = this.addressModel + ", " +this.country;
        }
      if (checkValue.indexOf(false) === -1) {
        checkValue = [];
        this.$refs["address-modal"].show();
        this.$refs["input-field-modal"].hide();
      }
    },
    isValidAddress() {
      let isValid = true;
        this.googleNotReadAddress = [];
        if (this.state.trim() == "") {
          isValid = false;
          this.googleNotReadAddress.push("state");
        }
        if (this.city.trim() == "") {
          isValid = false;
          this.googleNotReadAddress.push("city");
        }
        if (this.zipcode.trim() == "") {
          isValid = false;
          this.googleNotReadAddress.push("zip");
        }
        return isValid;
    },
    showInputCityModal() {
      this.$refs["address-modal"].hide();
      this.$refs["input-field-modal"].show();
    },
    //gets the address from google api
    setPlace(place) {
        var self = this;
        self.addressModel = place.formatted_address;
        self.setLocation(place.address_components);
    },

    setLocation(addressArray){
      var self = this;
      var streetAddress = '';
      var streetNumber = '';
      var route = '';
      self.street_address = '';
      self.country = '';
      self.state = '';
      self.city = '';
      self.zipcode = '';
      self.checkAddressNotCome = false;
      self.googleNotReadAddress = [];
      for (var i = 0; i < addressArray.length; i++) {
          // street number
          if(addressArray[i]['types'][0] == 'street_number'){
              streetNumber = addressArray[i].long_name;
          }
          // route
          if(addressArray[i]['types'][0] == 'route'){
              route = addressArray[i].long_name;
          }
          // street address
          if(streetNumber !== '' || route!==''){
              streetAddress = streetNumber+" "+route;
              self.street_address = streetAddress;
          }
          // country
          if(addressArray[i]['types'][0] == 'country'){
              self.country = addressArray[i].short_name;
          }
          // state
          if(addressArray[i]['types'][0] == 'administrative_area_level_1'){
              self.state = addressArray[i].short_name;
          }
          // city
          if(addressArray[i]['types'][0] == 'locality'){
              self.city = addressArray[i].long_name;
          }
          // zip
          if(addressArray[i]['types'][0] == 'postal_code'){
              self.zipcode = addressArray[i].long_name;
          }


      }
      if(self.street_address == ""){
        self.googleNotReadAddress.push("address");
      }
      if(self.state == ""){
        self.googleNotReadAddress.push("state");
      }
      if(self.city == ""){
        self.googleNotReadAddress.push("city");
      }
      if(self.zipcode == ""){
        self.googleNotReadAddress.push("zip");
      }
      if (self.street_address == "" || self.city == "" || self.state == "" || self.zipcode == "" || self.country == "") { 
        self.showInputCityModal();
      }
    },
    
    showAptInput() {
      var self = this;
      this.show_apt_input = true;
      this.apt_number = "";
      setTimeout(() => {
        self.$refs.apt_input.focus();
      }, 100);
    },
    saveDetails() {
      let self = this;
      if(!(self.usaState.includes(self.state) ))
      {
        self.showValidationModal("Us_address subdivision must be one of: AA, AE, AK, AL, AP, AR, AS, AZ, CA, CO, CT, DC, DE, FL, FM, GA, GU, HI, IA, ID, IL, IN, KS, KY, LA, MA, MD, ME, MH, MI, MN, MO, MP, MS, MT, NC, ND, NE, NH, NJ, NM, NV, NY, OH, OK, OR, PA, PR, PW, RI, SC, SD, TN, TX, UT, VA, VI, VT, WA, WI, WV, WY.");
        return false;
      }
      if (self.date_of_birth) {
        self.date_of_birth = moment(String(self.date_of_birth)).format(
          "MM-DD-YYYY"
        );
      }
      let is_valid_address = self.isValidAddress();
      if(is_valid_address){
        var request = {
          dateOfBirth: self.date_of_birth,
          address: self.street_address,
          zip: self.zipcode,
          state: self.state,
          city: self.city,
          apt_number: self.apt_number,
        };
        self.hideModal("address-modal");
        this.isLoading = true;
        api
          .saveDetails(request)
          .then((response) => {
            if (response.code == 200) {
              self.show_apt_input = false;
              self.getUserDetails();
            }
          })
          .catch(function (error) {
            self.showValidationModal(error.response.data.message);
            self.isLoading = false;
            self.showloader = false;
          });
      }else{
        this.showInputCityModal();
      }
    },
    getFormatedUpdateRemainingDate(date) {
      var self = this;
      if(date){
        if(self.address_update_mode == 0){
          return moment(date).format('H:mm:ss A')
        }else{
          return moment(date).format('MM-DD-YYYY')
        }
      }else{
        return null;
      }
    },
  },
};
</script>

<style lang="scss">
.b-button {
  background-color: transparent;
}

#pay-modal-center___BV_modal_content_ {
  border-radius: 10px;
  margin: 10px;
  background-color: #ffffff;
}
#pay-modal-center___BV_modal_body_ {
  background-color: #ffffff;
  border-radius: 12px;
  margin: 10px;
}

.content-wrap {
  overflow: visible;
  background-color: #149240;
}
#input-date-of-birth-modal___BV_modal_body_ {
  background-color: #ffffff;
  border-radius: 12px;
  margin: 10px;
}

@media only screen and (width: 600px) and (height: 960px) {
  .month-modal-sm {
    margin-left: 100px;
  }
}
@media only screen and (min-width: 768px) and (min-height: 1024px) {
  .month-modal-sm {
    margin-left: 120px;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1366px) {
  .month-modal-sm {
    margin-left: 120px;
  }
}
@media (min-width: 1281px) {
  .month-modal-sm {
    margin-left: 293px;
    margin-top: 14px;
  }
}

#month-modal___BV_modal_body_ {
  background-color: #ffffff;
  margin: 20px;
  margin-top: 0px;
  margin-bottom: 0px;
}
#month-modal___BV_modal_content_ {
  background-color: #ffffff;
}
.month-modal-sm {
  float: left !important;
}
#delink-modal___BV_modal_body_ {
  background-color: #ffffff;
  border-radius: 12px;
  margin: 10px;
}
#reset-pin-modal___BV_modal_body_ {
  background-color: #ffffff;
  border-radius: 12px;
  margin: 10px;
}
#address-modal___BV_modal_body_ {
  background-color: #ffffff;
  border-radius: 12px;
  margin: 10px;
}
#blocked-routing-number-modal___BV_modal_body_ {
  background-color: #ffffff;
  border-radius: 12px;
  margin: 10px;
}
#dob-update-alert-modal___BV_modal_body_{
  background-color: #ffffff;
  border-radius: 12px;
}
#address-update-alert-modal___BV_modal_body_{
  background-color: #ffffff;
  border-radius: 12px;
}
#input-field-modal___BV_modal_body_ {
  background-color: #ffffff;
  border-radius: 12px;
  margin: 10px;
}
.profile-btn-cancel{
  border: 0;
  background: #000;
  padding: 10px 25px;
  margin: 0;
  border-radius: 3px;
}
.profile-btn-ok{
  border: 0;
  background: #149240;
  color: #fff;
  padding: 10px 25px;
  margin: 0;
  border-radius: 3px;
}
</style>
