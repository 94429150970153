<template>
  <div class="container pt-3 pb-4">

    <div v-if="filterTerminalTypes.length && !showEnterTip">
      <div v-for="(terminals, index) in filterTerminalTypes" :key="index">
        <pending-transaction-detail-card
          v-bind:terminal-types="terminalTypes"
          v-bind:terminals="terminals"
          v-bind:index="index"
        />
      </div>
    </div>
   
    <div
      class="one mx-auto history-div"
      v-if="!terminalTypes.length && this.showloader && !showEnterTip"
    >
      <DefaultLoader :color="'#fffff'" />
      <br />
      <h3 style="color: #ffffff">Loading</h3>
    </div>
    <div
      class="one mx-auto history-div"
      v-if="!terminalTypes.length && !this.showloader"
    >
      <span class="success-text">No transactions to display.</span>
      <br />
      <span class="success-sub-text">
        Your transaction history will show here once you make a purchase with
        CanPay.
      </span>
    </div>

  </div>
</template>
<script>
import api from "../api/transactiondetails.js";
import { DefaultLoader } from "vue-spinners-css";
import tip from "./Payment/Tip.vue";
import PendingTransactionDetailCard from "./PendingTransactionDetailCard.vue";
import moment from "moment";
import { saveAs } from "file-saver";
export default {
  name: "TransactionDetails",
  data() {
    return {
      showloader: false,
      showEnterTip: false,
      transactiondetails: {},
      setupterminalModel: {
        type: "",
        transaction_type_id: "",
        terminal_name: "",
        tip_amount: "",
        searchKeyword: "0.01",
      },
      terminalTypes: [],
      searchText: '',
    };
  },
  watch: {
    //the reason this code segment was added is when clicked into transaction details from tip page the page body used to remain white
    showEnterTip: function (newval, oldval) {
      if (newval == false) {
        document
          .getElementsByClassName("content-wrap")[0]
          .style.setProperty("background-color", "#149240");
      }
    },
  },
  created() {
    this.pendingTransactionHistory();
  },
  components: {
    DefaultLoader,
    "enter-tip": tip,
    "pending-transaction-detail-card": PendingTransactionDetailCard,
  },
  methods: {
    pendingTransactionHistory() {
      var self = this;
      self.showloader = true;
      self.terminalTypes = [];
      api
        .getPendingTransactionDetails()
        .then(function (response) {
          if (response.code == 200) {
            self.terminalTypes = response.data;
            self.showloader = false;
          } else {
            if(response.message){
              alert(response.message);
            }
            self.showloader = false;
          }
        })
        .catch(function (error) {
          self.showloader = false;
        });
    },
    clickTransaction(transactionId) {
      let self = this;
      var header = {
        "Content-type": "application/json",
      };
      var request = {
        transaction_id: transactionId,
      };
      api
        .getparticularTransaction(request)
        .then((response) => {
          if (response.code == 200) {
            this.transactiondetails = response.data;
            // this.showEnterTip = true;
            self.showloader = false;
            self.$forceUpdate();
          } else {
            self.showloader = false;
          }
        })
        .catch(function (error) {
          self.showloader = false;
        });
    },
    transactionHistory: function () {
      this.showloader = true;
      var self = this;
      api
        .transactionHistory()
        .then(function (response) {
          if (response.code == 200) {
            self.terminalTypes = response.data;
            self.showloader = false;
          } else {
            if(response.message){
              alert(response.message);
            }
            self.showloader = false;
          }
        })
        .catch(function (error) {
          self.showloader = false;
        });
    },
    searchedTransactionHistory(data) {
      let self = this;
      var header = {
        "Content-type": "application/json",
      };
      var request = {
        search_keyword: data,
      };
      api
        .searchtransactionHistory(request)
        .then((response) => {
          if (response.code == 200) {
            self.terminalTypes = response.data;
            self.$forceUpdate();
            self.showloader = false;
          } else {
            self.showloader = false;
          }
        })
        .catch(function (error) {
          self.showloader = false;
        });
    },
    transactionExport() {
      this.showloader = true;
      var self = this;
      console.log(self.terminalTypes);
      var request = {
        report: self.terminalTypes,
      };
      api
        .exportTransaction(request)
        .then((response) => {
          var FileSaver = require("file-saver");
          var blob = new Blob([response], {
            type: "application/xlsx",
          });
          FileSaver.saveAs(
            blob,
            moment().format("MM/DD/YYYY") +
              "_Transaction__Details.xlsx"
          );
          self.loading = false;
        })
        .catch(function (error) {
          self.showloader = false;
        });
    },
    viewHistoricalTransaction() {
      this.$router.push("/historicaltransactiondetails").catch((err) => {});
    },
    searchTransaction() {
      var self = this;
      self.$root.$on("search-keyword", function (data) {
        self.searchText = data;
      });
    },
  },
  computed: {
    filterTerminalTypes() {
      let tempTerminalTypes = this.terminalTypes;
      if (this.searchText != '' && this.searchText) {
        var lowSearch = String(this.searchText).toLowerCase();
        tempTerminalTypes = tempTerminalTypes.filter((item) => {
          return (
              String(parseFloat(item.total_amount).toFixed(2)).toLowerCase().includes(lowSearch) || 
              String(item.store_name).toLowerCase().includes(lowSearch)
            );
        })
      }
      return tempTerminalTypes;
    }
  },
  mounted() {
    var self = this;
    self.showEnterTip = false;
    var element = document.getElementsByClassName("content-wrap");
    if (element[0]) {
      element[0].style.setProperty("background-color", "#149240");
    }
    self.$root.$emit("changeWhiteBackground", [
      false,
      false,
      "PendingTransactionHistoryHeader",
    ]);
    this.searchTransaction();

    self.$root.$on("TransactionHistory", function (data) {
      self.showEnterTip = false;
    });
  },
  beforeDestroy() {
    this.$root.$off("search-keyword");
  },
};
</script>

<style>
.min-h {
  min-height: calc(100vh - 115px) !important;
}

.tran-card-link,
.tran-card-link:hover {
  text-decoration: none;
  color: unset;
}
.tran-card {
  background: white;
  border-radius: 7px;
  text-align: left;
}

.tran-card-header {
  position: relative;
  padding: 15px 17px;
  border-radius: 7px;
}
.tran-store-logo {
  position: absolute;
  width: 80px;
  height: 40px;
  top: 5px;
  right: 10px;
}
.tran-title {
  font-weight: 600;
  font-size: 25px;
}
.tran-store-name {
  font-weight: 600;
  font-size: 18px;
}

.tran-point-card {
  padding: 7px 0;
}
.tran-point-card .title {
  margin: 0 !important;
  padding: 0;
}
.tran-point-card .title span {
  font-size: 21px;
  line-height: 21px;
  font-weight: 800;
}


.tran-timing {
  list-style: none;
  margin: 0;
  padding: 0;
  color: #a3a3a3;
    font-size: 12px;
    font-weight: 600;
}
.tran-timing li {
  display: flex;
  align-items: center;
}

.tran-card-body {
  padding: 10px 15px;
}

.tran-detail {
  align-items: center;
  margin: 0;
}
.tran-detail .title {
  font-weight: 700;
  font-size: 16px;
  margin: 0;
}
.tran-detail .value {
  font-weight: 700;
  font-size: 18px;
  margin: 0;
}

.tran-detail-point {
  align-items: center;
  margin: 0;
  color: #149240;
}
.tran-detail-point .title {
  font-weight: 700;
  font-size: 16px;
  margin: 0;
}
.tran-detail-point .value {
  font-weight: 700;
  font-size: 18px;
  margin: 0;
}

.tran-detail-total {
  align-items: center;
  background: #e3e3e3;
  padding: 13px 15px;
}
.tran-detail-total .title {
  font-weight: 700;
  font-size: 21px;
  margin: 0;
}
.tran-detail-total .value {
  font-weight: 700;
  font-size: 21px;
  margin: 0;
}

.tran-detail-store {
  font-size: 13px;
    font-weight: 600;
}
.active-bg-card {
  background-color: #e3e3e3 !important;
}
.min-h{
    min-height: calc(100vh - 115px)!important;
}


.tran-card-link, .tran-card-link:hover{
    text-decoration: none;
    color: unset;
}
.tran-card{
    background: white;
    border-radius: 7px;
    text-align: left;
}


.tran-store-logo{
    position: absolute;
    width: 80px;
    top: 10px;
    right: 10px;
}


.tran-point-card{
    padding: 7px 0;
}
.tran-point-card .title{
    margin: 0!important;
    padding: 0;
}

.tran-point-card .value{
  font-size: 15px;
  line-height: 15px;
  font-weight: 800;
}

.tran-timing{
    list-style: none;
    margin: 0;
    padding: 0;
    color: #9b9999;
    font-size: 13px;
    font-weight: 600;
}
.tran-timing li{
    display: flex;
    align-items: center;
}

.tran-card-body{
  padding: 10px 15px;
}

.tran-detail{
    align-items: center;
    margin: 0;
}

.tran-detail-point{
    align-items: center;
    margin: 0;
    color: #149240;
}


.tran-detail-icon-avatar{
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-color: #118037 !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.active-bg-card{
    background-color: #e3e3e3 !important;
    border-radius: 7px 7px 0 0;
}
</style>
