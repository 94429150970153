<template>
<div>
  <div v-if="isLoading">
   <CanPayLoader/>
  </div>
  <div class="container">
    <cp-onboardingheader></cp-onboardingheader>

    <div v-if="!isLoading">
      <div class="row label-row mt-5">
        <div class="col-12">
          <span class="setup-label-onboarding">
            Please Confirm Your
            <br />Account Information
          </span>
        </div>
        <div class="col-3"></div>
      </div>
      <div class="row enter-name-row-gap first-row-gap">
        <div class="col-12">
          <div class="form-group">
            <span class="form-control-icon">
              <svg
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 50 62.5"
                style="enable-background: new 0 0 50 62.5"
                xml:space="preserve"
                height="20"
                width="20"
              >
                <path
                  d="M32.3,32.1c0.2-0.8,0.4-1.6,0.4-2.5v-8.9c0-4.8-3.9-8.8-8.8-8.8s-8.8,3.9-8.8,8.8v8.9c0,0.9,0.2,1.7,0.4,2.4
	               c-2.3,0.7-4.4,2.2-6.1,4.2c-2.4,3-3.8,7-3.8,11.3c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0.7,0.6,1.4,1.4,1.4h33.9c0.7,0,1.4-0.6,1.4-1.4
	               c0,0,0,0,0-0.1c0,0,0,0,0-0.1C42.2,40.3,38,33.9,32.3,32.1z M17.8,20.8c0-3.4,2.7-6.1,6.1-6.1c3.4,0,6.1,2.7,6.1,6.1v8.9
	               c0,3.4-2.7,6.1-6.1,6.1s-6.1-2.7-6.1-6.1V20.8z M11.5,38c1.4-1.7,3.2-2.9,5.1-3.4c1.6,2.3,4.2,3.8,7.3,3.8c3,0,5.7-1.5,7.3-3.8
	               c4.5,1.2,7.8,6,8.2,11.8h-31C8.6,43.2,9.7,40.3,11.5,38z"
                />
              </svg>
            </span>
            <input
              v-model="UserModel.first_name"
              class="form-control"
              type="text"
              maxlength="50"
              placeholder="First Name"
              @focus="removeattribute($event)"
              @blur="setattribute($event, 'First Name')"
              v-bind:style="{ 'border-color': UserModel.firstnameColor }"
            />
          </div>
        </div>
      </div>
      <div class="row enter-name-row-gap">
        <div class="col-12">
          <div class="form-group">
            <span class="form-control-icon">
              <svg
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 50 62.5"
                style="enable-background: new 0 0 50 62.5"
                xml:space="preserve"
                height="20"
                width="20"
              >
                <path
                  d="M32.3,32.1c0.2-0.8,0.4-1.6,0.4-2.5v-8.9c0-4.8-3.9-8.8-8.8-8.8s-8.8,3.9-8.8,8.8v8.9c0,0.9,0.2,1.7,0.4,2.4
	     c-2.3,0.7-4.4,2.2-6.1,4.2c-2.4,3-3.8,7-3.8,11.3c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0.7,0.6,1.4,1.4,1.4h33.9c0.7,0,1.4-0.6,1.4-1.4
	     c0,0,0,0,0-0.1c0,0,0,0,0-0.1C42.2,40.3,38,33.9,32.3,32.1z M17.8,20.8c0-3.4,2.7-6.1,6.1-6.1c3.4,0,6.1,2.7,6.1,6.1v8.9
	     c0,3.4-2.7,6.1-6.1,6.1s-6.1-2.7-6.1-6.1V20.8z M11.5,38c1.4-1.7,3.2-2.9,5.1-3.4c1.6,2.3,4.2,3.8,7.3,3.8c3,0,5.7-1.5,7.3-3.8
	     c4.5,1.2,7.8,6,8.2,11.8h-31C8.6,43.2,9.7,40.3,11.5,38z"
                />
              </svg>
            </span>
            <input
              type="text"
              class="form-control"
              maxlength="50"
              placeholder="Middle Name"
              @focus="removeattribute($event)"
              @blur="setattribute($event, 'Middle Name')"
              v-model="UserModel.middle_name"
              v-bind:style="{ 'border-color': UserModel.middlenameColor }"
            />
          </div>
        </div>
      </div>
      <div class="row enter-name-row-gap">
        <div class="col-9">
          <div class="form-group">
            <span class="form-control-icon">
              <svg
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 50 62.5"
                style="enable-background: new 0 0 50 62.5"
                xml:space="preserve"
                height="20"
                width="20"
              >
                <path
                  d="M32.3,32.1c0.2-0.8,0.4-1.6,0.4-2.5v-8.9c0-4.8-3.9-8.8-8.8-8.8s-8.8,3.9-8.8,8.8v8.9c0,0.9,0.2,1.7,0.4,2.4
	                 c-2.3,0.7-4.4,2.2-6.1,4.2c-2.4,3-3.8,7-3.8,11.3c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0.7,0.6,1.4,1.4,1.4h33.9c0.7,0,1.4-0.6,1.4-1.4
                       	c0,0,0,0,0-0.1c0,0,0,0,0-0.1C42.2,40.3,38,33.9,32.3,32.1z M17.8,20.8c0-3.4,2.7-6.1,6.1-6.1c3.4,0,6.1,2.7,6.1,6.1v8.9
	                    c0,3.4-2.7,6.1-6.1,6.1s-6.1-2.7-6.1-6.1V20.8z M11.5,38c1.4-1.7,3.2-2.9,5.1-3.4c1.6,2.3,4.2,3.8,7.3,3.8c3,0,5.7-1.5,7.3-3.8
                    	c4.5,1.2,7.8,6,8.2,11.8h-31C8.6,43.2,9.7,40.3,11.5,38z"
                />
              </svg>
            </span>
            <input
              type="text"
              v-model="UserModel.last_name"
              placeholder="Last Name"
              class="form-control"
              maxlength="50"
              @focus="removeattribute($event)"
              @blur="setattribute($event, 'Last Name')"
              v-bind:style="{ 'border-color': UserModel.lastnameColor }"
            />
          </div>
        </div>
        <div class="col-3 suffix-padding">
          <button
            id="suffix-btn"
            @click="openSuffixModal"
            class="btn-reg-dropdown-onboard-style dropdown-toggle suffix-label"
            aria-haspopup="false"
            aria-expanded="false"
          >
            <span class="">{{ suffix }}</span>
            <span style="display: block">
              <svg
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                width="12px"
                height="12px"
                viewBox="0 0 56 48.5"
                style="enable-background: new 0 0 56 48.5"
                xml:space="preserve"
              >
                <path id="Polygon_1" d="M28,48.5L0,0l56,0L28,48.5z" />
              </svg>
            </span>
          </button>
        </div>
      </div>
      <div class="row enter-name-row-gap">
        <div class="col-12">
          <div class="form-group">
            <span class="form-control-icon">
              <svg
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 72 90"
                style="enable-background: new 0 0 72 90"
                xml:space="preserve"
                height="20"
                width="20"
              >
                <path
                  d="M48,70H24c-5.5,0-10-4.5-10-10V12c0-5.5,4.5-10,10-10h24c5.5,0,10,4.5,10,10v48C58,65.5,53.5,70,48,70z M24,6
	                c-3.3,0-6,2.7-6,6v48c0,3.3,2.7,6,6,6h24c3.3,0,6-2.7,6-6V12c0-3.3-2.7-6-6-6H24z"
                />
                <path
                  d="M40,14h-8c-1.1,0-2-0.9-2-2s0.9-2,2-2h8c1.1,0,2,0.9,2,2S41.1,14,40,14z"
                />
                <circle cx="36" cy="56.4" r="4" />
              </svg>
            </span>
            <input
              inputmode="numeric"
              @keypress="isNumber($event)"
              @focus="removeattribute($event)"
              @blur="setattribute($event, 'Mobile Number')"
              v-model="UserModel.phone"
              placeholder="Mobile Number"
              class="form-control"
              v-mask="'(###) ###-####'"
              v-bind:style="{ 'border-color': UserModel.phoneColor }"
            />
          </div>
        </div>
      </div>
      <div class="row p-3">
        <div class="col-02 squaredOne" style="margin-top: 0.3rem">
          <input
            type="checkbox"
            v-model="checked"
            id="squaredOne"
            v-on:click="addBoxShadow"
          />
          <label for="squaredOne"></label>
        </div>
        <div
          class="
            col-10
            terms-checkbox-onboard-position
            text-left
            align-self-center
          "
        >
          <label
            >I have read and agreed to the
            <span
              class="terms-checkbox-onboard"
              v-on:click="redirectToTermsAndCondition"
              ><b>Terms of <br />Service End User License Agreement</b></span
            >
            <br />and
            <span
              class="terms-checkbox-onboard"
              v-on:click="redirectToPrivacyPolicy"
            >
              <b>Privacy Policy</b></span
            >
          </label>
        </div>
      </div>
    </div>
    <div class="row next-btn-row">
      <div class="col-12">
        <button
          type="button"
          class="btn-login"
          @click="clickSubmit"
          :disabled="!checked"
        >
          Next
        </button>
      </div>
    </div>
    <!-- Modal for suffix options -->
    <div>
      <b-modal
        ref="suffix-modal"
        hide-footer
        hide-header
        size="sm"
        centered
        variant="primary"
        id="suffix-modal"
      >
        <div>
          <center>
            <div class="row" @click="setSuffixValue('Suffix')">
              <div class="col-12 opt-suffix">Suffix</div>
            </div>
            <div class="row" @click="setSuffixValue('I')">
              <div class="col-12 suffix-option">I</div>
            </div>
            <div class="row" @click="setSuffixValue('II')">
              <div class="col-12 suffix-option">II</div>
            </div>
            <div class="row" @click="setSuffixValue('III')">
              <div class="col-12 suffix-option">III</div>
            </div>
            <div class="row" @click="setSuffixValue('Jr.')">
              <div class="col-12 suffix-option">Jr.</div>
            </div>
            <div class="row" @click="setSuffixValue('Sr.')">
              <div class="col-12 suffix-option">Sr.</div>
            </div>
          </center>
        </div>
      </b-modal>
    </div>
    <!-- suffix modal end -->

    <!-- Validation modal -->
    <b-modal
      ref="validation-modal"
      hide-footer
      v-b-modal.modal-center
      modal-backdrop
      hide-header
      id="validation-modal"
      centered
    >
      <div class="color">
        <div class="purchaserpower-modal-text">
          <div class="d-block text-center">
            <label class="purchasepower-def-label">
              {{ error_message }}
            </label>
          </div>
          <br />
          <br />
          <div class="text-center">
            <button
              type="button"
              class="mx-auto col-10 offset-1 btn-black"
              style="height: 60px"
              @click="hidevalidationModal(false)"
            >
              <label class="purchasepower-modal-ok-label">OK</label>
            </button>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</div>
</template>
 <script>
/**
 * Registration component is using for consumer registration process
 */
import OnboardingHeader from "./OnboardingHeader.vue";
import api from "../../api/onboarding.js";
import constants from "../Common/constant.js";
import Toasted from "vue-toasted";
import Loading from "vue-loading-overlay";
import CanPayLoader from "../CustomLoader/CanPayLoader.vue";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "Registration",
  components: {
    Loading,
    "cp-onboardingheader": OnboardingHeader,
    CanPayLoader
  },
  /**
   * @description-
   * first_name => this will store the user's first_name
   * middle_name => this will store the user's middle_name
   * last_name => this will store the user's last_name
   * phone => this will store the user's phone
   * email => this will store the user's email
   * @returns {any}
   */
  data() {
    return {
      error_message: "",
      progressMax: 100,
      progress: 20,
      suffix: "Suffix",
      show: constants.enter_name_phone,
      newPhonenumber: false,
      isLoading: false,
      fullPage: true,
      checked: false,
      enterOTPapiFlag: false,
      alphabet_reg: /^[a-zA-Z ]+$/,
      fulladdress: "",
      day: "",
      month_number: "",
      dob_month: "",
      year: "",
      reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      UserModel: {
        first_name: "",
        middle_name: "",
        last_name: "",
        phone: "",
        email: "",
        firstnameColor: "",
        middlenameColor: "",
        lastnameColor: "",
        phoneColor: "",
        emailColor: "",
        address: "",
        state: "",
        city: "",
        zip: "",
        routingNumber: "",
        accountNumber: "",
      },
      ssn: "",
      pin: "",
      otpsuccess: false,
      modalMessage: "",
    };
  },
  created() {},
  destroyed() {
    document
      .getElementById("app")
      .style.setProperty("background-color", "#149240");
  },
  mounted() {
    this.$root.$emit("show_header", false);
    this.$root.$emit("changeWhiteBackground", [true, false, ""]);
    document
      .getElementById("app")
      .style.setProperty("background-color", "#ffffff");

    var element = document.getElementsByClassName("wrapper");
    element[0].style.setProperty("background-color", "#ffffff");
    this.phone = localStorage.getItem("phone");
    this.existingPhone = localStorage.getItem("existing_phone");
    var elementHtml = document.getElementsByTagName("html")[0];
    elementHtml.style.setProperty("background-color", "#ffffff");
  },
  watch: {},
  methods: {
   onCancel() {
      console.log("User cancelled the loader.");
    },
    addBoxShadow() {
      let self = this;
      if (self.checked) {
        var element = document.getElementsByClassName("squaredOne");
        element[0].style.setProperty("box-shadow", "0 0 0 1.5px #eeeeee");
      } else {
        var element = document.getElementsByClassName("squaredOne");
        element[0].style.setProperty("box-shadow", "0 0 0 1.5px #179346");
      }
    },

    redirectToTermsAndCondition() {
      this.$router.push("/onboardingtermsandcondition");
    },
    redirectToPrivacyPolicy() {
      this.$router.push("/onboardingprivacypolicy");
    },
    removeattribute(el) {
      if (typeof el.target === "undefined") {
        document.getElementById(el).removeAttribute("placeholder");
      } else {
        el.target.removeAttribute("placeholder");
      }
    },
    setattribute(el, placeholder) {
      if (typeof el.target === "undefined") {
        document.getElementById(el).setAttribute("placeholder", placeholder);
      } else {
        el.target.setAttribute("placeholder", placeholder);
      }
    },
    openSuffixModal() {
      this.$refs["suffix-modal"].show();
      setTimeout(function () {
        var element = document.getElementsByClassName("modal-sm")[0];
        element.classList.add("suffix-modal-sm");
      }, 10);
    },
    setSuffixValue(val) {
      this.suffix = val;
      this.hideSuffixModal();
    },
    hideSuffixModal() {
      this.$refs["suffix-modal"].hide();
    },
    clickSubmit() {
      let self = this;
      self.UserModel.phone = self.UserModel.phone.replace(/[^a-zA-Z0-9]/g, "");
      if (self.UserModel.first_name == "") {
        this.UserModel.firstnameColor = "red";
        self.showValidationModal(RequiredField);
        return false;
      } else if (self.UserModel.last_name == "") {
        this.UserModel.lastnameColor = "red";
        self.showValidationModal(RequiredField);
        return false;
      } else if (self.UserModel.phone == "") {
        this.UserModel.phoneColor = "red";
        self.showValidationModal(RequiredField);
        return false;
      } else if (self.UserModel.phone.length < 10) {
        self.showValidationModal(RequiredField);
        return false;
      } else {
        let self = this;
        self.UserModel.firstnameColor = "";
        self.UserModel.middlenameColor = "";
        self.UserModel.lastnameColor = "";
        self.UserModel.phoneColor = "";
        if (!self.alphabet_reg.test(self.UserModel.first_name)) {
          self.showValidationModal("Please enter a valid name.");
          return false;
        }
        if (
          self.UserModel.middle_name != "" &&
          !self.alphabet_reg.test(self.UserModel.middle_name)
        ) {
          self.showValidationModal("Please enter a valid name.");
          return false;
        }
        if (!self.alphabet_reg.test(self.UserModel.last_name)) {
          self.showValidationModal("Please enter a valid name.");
          return false;
        }

        self.enterConsumerAddress();
      }
    },
    showValidationModal(msg) {
      this.error_message = msg;
      this.$refs["validation-modal"].show();
    },
    hidevalidationModal(flag) {
      this.error_message = "";
      this.$refs["validation-modal"].hide();
      if (flag) {
        this.$router.push("/login");
      }
      if (this.refresh) {
        this.$router.go();
      }
    },
    enterConsumerAddress: function () {
      let self = this;
      this.isLoading = true;
      var request = {
        session_id: localStorage.getItem("sessionId"),
        email: localStorage.getItem("email"),
        onboarding_manual_review_id: null,
        user_id: localStorage.getItem("user_id"),
        is_duplicate: localStorage.getItem("is_duplicate"),
        status: localStorage.getItem("status"),
        phone: self.UserModel.phone,
        first_name: self.UserModel.first_name,
        middle_name: self.UserModel.middle_name,
        last_name: self.UserModel.last_name,
         suffix: this.suffix,
      };
      api
        .onboardingManualReview(request)
        .then((response) => {
          this.isLoading = false;
          if (response.code == 200) {
            localStorage.removeItem("is_duplicate");
            localStorage.removeItem("user_id");
            localStorage.removeItem("status");
            localStorage.setItem("manualReviewId", response.data.id);
            self.$router.push("/uploadconsumerdocument");
          }
        })
        .catch((err) => {
          self.isLoading = false;
          err.response.data.message;
               self.showValidationModal(err.response.data.message);
        });
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode < 48 || charCode > 57) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  },
};
</script>
<style lang="scss">
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
::placeholder {
  color: rgb(0, 0, 0);
  opacity: 1;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
}
::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #000000 !important;
  font-family: "Open Sans" !important;
  font-size: 14px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: normal !important;
  // letter-spacing: 0.5px !important;
  text-align: left !important;
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: #000000 !important;
  font-family: "Open Sans" !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: normal !important;
  // letter-spacing: 0.5px !important;
  text-align: left !important;
}
:-ms-input-placeholder {
  /* IE 10+ */
  color: #000000 !important;
  font-family: "Open Sans" !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: normal !important;
  // letter-spacing: 0.5px !important;
  text-align: left !important;
}
:-moz-placeholder {
  /* Firefox 18- */
  color: #000000 !important;
  font-family: "Open Sans" !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: normal !important;
  // letter-spacing: 0.5px !important;
  text-align: left !important;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
@media only screen and (min-width: 300px) {
  .suffix-modal-sm {
    float: right !important;
  }
}
@media only screen and (width: 360px) and (width: 640px) {
  .suffix-modal-sm {
    float: right !important;
    margin-top: -5px;
    width: 94px;
  }
}
@media only screen and (min-width: 360px) and (min-height: 700px) {
  .suffix-modal-sm {
    float: right !important;
  }
}
@media only screen and (max-width: 375px) and (max-width: 812px) {
  .suffix-modal-sm {
    float: right !important;
    margin-top: -58px;
    width: 95px;
  }
}
@media only screen and (width: 600px) and (height: 960px) {
  .suffix-modal-sm {
    float: right !important;
    margin-right: 100px;
    margin-top: -110px;
  }
}
@media only screen and (min-width: 768px) and (min-height: 1024px) {
  .suffix-modal-sm {
    float: right !important;
    margin-right: 120px;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1366px) {
  .suffix-modal-sm {
    float: right !important;
    margin-right: 120px;
    margin-top: -190px;
  }
}
@media (min-width: 1281px) {
  .suffix-modal-sm {
    float: right !important;
    margin-right: 293px;
    margin-top: 14px;
  }
}
#suffix-modal___BV_modal_content_ {
  margin-top: 165px;
  height: 238px;
  width: 66px;
}
#suffix-modal___BV_modal_body_ {
  background-color: white !important;
  border-radius: 4px;
}
#info_modal___BV_modal_content_ {
  border-radius: 10px;
  margin: 10px;
  background-color: #ffffff;
}
#info_modal___BV_modal_body_ {
  background-color: #ffffff;
  border-radius: 12px;
  margin: 10px;
}
#pin-success-modal___BV_modal_content_ {
  border-radius: 10px;
  margin: 10px;
  background-color: #ffffff;
}
#pin-success-modal___BV_modal_body_ {
  background-color: #ffffff;
  border-radius: 12px;
  margin: 10px;
}
#sign-in-modal___BV_modal_body_ {
  background-color: #ffffff;
  border-radius: 12px;
  margin: 10px;
}
</style>
