<template>
<div>
  <div v-if="isLoading">
    <CanPayLoader/>
  </div>
 <div class="container px-4 py-3 touch-select-area" v-else>

    <div v-if="showPaymentPIN" class="pb-3">
      
      <div
        class="
          d-flex
          col-12
          flex-column
          align-items-start
          purchase_power_style
          px-0
          mb-3
        "
      >
        <div class="col-12 purchpower-header">
          <div class="purchpower-text d-flex align-items-center" v-if="currentUser.bank_link_type == 1">PurchPower <sup class="ml-2 tm-icon">TM</sup></div>
          <div class="purchpower-text d-flex align-items-center" v-else>Spending Limit <sup class="ml-2 tm-icon">TM</sup></div>
        </div>
        
        <div style="position: relative;" class="d-flex align-items-center pl-3 pr-4 pb-3 pt-3 w-100">
          <p class="purchasepower-box-amount mb-0 position-relative">{{ purchase_power }}
            <span @click="openModal('reward-point-info-modal')" class="reward-point-info-logo rp-imark-avatar">
              <svg
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              width="19px"
              fill="#ffffff"
              viewBox="0 0 90 90"
              xml:space="preserve"
            >
              <path
                d="M45,0C20.1,0,0,20.1,0,45s20.1,45,45,45s45-20.1,45-45C90,20.2,69.8,0,45,0z M45,82C24.6,82,8,65.4,8,45S24.6,8,45,8
                s37,16.6,37,37S65.4,82,45,82z M40,20c0-2.8,2.2-5,5-5s5,2.2,5,5s-2.2,5-5,5S40,22.8,40,20z M56,65.4V68c0,1.1-0.9,2-2,2H36
                c-1.1,0-2-0.9-2-2v-2.6c0-0.9,0.6-1.6,1.4-1.9l4.1-1.3c0.3-0.1,0.5-0.4,0.5-0.7V41h-3.1c-1.6,0-2.9-1.3-2.9-2.9c0-1.2,0.8-2.3,2-2.7
                l11.4-3.6c1.1-0.3,2.2,0.3,2.5,1.3c0.1,0.2,0.1,0.4,0.1,0.6v27.8c0,0.3,0.2,0.6,0.5,0.7l4.1,1.3C55.4,63.7,56,64.5,56,65.4z"
              />
            </svg>
            </span>
          </p>

          <div class="bank-account-info" v-if="currentUser.bank_link_type == 1">
            <svg class="mr-2" width="17px" fill="#fff" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
              viewBox="0 0 90.6 100" style="enable-background:new 0 0 90.6 100;" xml:space="preserve">
            <path d="M45.3,0L0,30.1v21h90.6v-21L45.3,0z M9,42.1v-7.2l36.3-24.1l36.3,24.1v7.2L9,42.1z"/>
            <rect x="0.1" y="91" width="90.4" height="9"/>
            <rect x="11.6" y="57.9" width="11.5" height="26.3"/>
            <rect x="67.5" y="57.9" width="11.5" height="26.3"/>
            <rect x="39.6" y="57.9" width="11.5" height="26.3"/>
            </svg>
            <p class="text-white m-0">
              x {{ last_four_account_no }}
            </p>
          </div>
        </div>
        
      </div>

      <div v-if="rwState == 'allowed' || rwState == 'partially_allowed'">
        <div class="row">
            <div class="col-12">
              <div class="cp-point-card">
                <div class="px-3 py-2">
                  <div class="d-flex position-relative">
                    <svg class="cp-point-logo" width="35" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="35" cy="35" r="35" fill="black"/>
                    <path d="M58.0327 44.4525C60.969 38.2154 59.7673 32.2515 59.4272 30.6694C59.0417 28.8825 58.0667 24.66 54.5183 20.8699C52.8282 19.0889 50.821 17.6413 48.6004 16.6018C45.6075 15.2019 43.0567 14.9287 41.6622 14.7921C35.9144 14.223 31.1643 15.9075 28.5114 17.114C29.8031 15.8716 31.2794 14.8387 32.8875 14.0523C34.3086 13.3704 35.8149 12.8842 37.3656 12.6069C39.9046 12.1197 42.5076 12.0697 45.0633 12.4589C46.2423 12.641 51.1626 13.4946 56.0714 17.3188C63.225 22.8844 66.1613 30.6125 64.4947 39.5016C61.9553 53.1026 48.9632 61.0697 35.7897 57.5641C31.9125 56.5284 27.8312 54.0814 26.0173 51.6912C26.108 51.6912 26.2101 51.6685 26.2667 51.7026C26.7202 51.9985 27.1624 52.3172 27.6272 52.6131C34.9962 57.2568 42.5465 57.291 50.0515 53.1367C51.0019 52.6094 51.9047 52.0001 52.7497 51.3156C56.0034 48.6751 57.4885 45.6021 58.0327 44.4525Z" fill="white"/>
                    <path d="M65.1182 45.2722C65.0389 45.7502 64.9028 46.4331 64.6874 47.2412C63.1229 53.023 59.5518 56.6765 58.0213 58.2016C52.4209 63.79 45.9022 65.4062 42.9206 66.1119C36.7873 67.5573 31.8331 66.9427 30.0532 66.6582C23.6479 65.6338 19.2492 63.0388 17.9001 62.1852C15.1477 60.4502 12.6808 58.2961 10.5878 55.8001C8.14327 52.8969 6.22821 49.5834 4.93068 46.012C4.60191 45.1014 3.35485 41.5276 3.03741 36.6335C2.74266 31.9671 3.44554 28.4843 3.6156 27.6762C4.17003 25.0362 5.06567 22.4803 6.27977 20.0733C6.91463 18.8441 10.1456 12.8346 17.2879 8.35027C18.9431 7.30316 23.2738 4.84474 29.407 3.91145C31.0282 3.66105 45.0066 1.72618 55.0171 10.5242C59.6539 14.5988 62.386 20.0505 62.8168 20.9269C63.4801 22.2806 64.0557 23.6759 64.54 25.104C64.3813 24.8877 63.2023 23.226 63.1683 23.1805C58.8149 16.7044 53.7587 13.6541 53.7587 13.6541C51.8468 12.5265 49.7911 11.665 47.6481 11.0932C40.8346 9.24941 35.0981 10.8884 33.7377 11.2981C25.7565 13.6996 21.0291 20.0847 20.757 20.4603C15.9388 27.1299 16.0408 34.0954 16.1315 36.0303C16.4376 42.5406 19.2718 47.1387 20.5642 48.9939C20.6663 49.1191 20.8137 49.3126 20.995 49.5403C21.1311 49.7224 21.2671 49.9045 21.4032 50.0866C24.7816 54.5482 29.2596 57.6553 34.2365 59.2032C37.9454 60.3419 41.8646 60.6138 45.6942 59.9984C49.5239 59.3829 53.1626 57.8962 56.3321 55.6522C59.121 53.6718 60.8102 51.6117 61.8532 50.337C63.2363 48.6525 64.2453 46.9908 64.5741 46.3306C64.6194 46.251 65.0956 45.2722 65.1182 45.2722Z" fill="#007EE5"/>
                    <path d="M28.7061 45.6C22.7202 45.6 19.7273 41.6278 19.7273 35.5387C19.7273 29.2447 22.879 25.4546 29.0462 25.4546C31.427 25.4546 33.3429 26.0237 34.8167 27.2301C36.1998 28.4707 36.9707 30.1552 37.1634 32.2949H34.76C33.8984 32.2949 33.2749 31.8966 32.9008 31.1112C32.2432 29.7113 30.9508 28.9943 29.0576 28.9943C25.3617 28.9943 23.7179 31.612 23.7179 35.5501C23.7179 39.3743 25.2937 42.0717 28.9329 42.0717C31.427 42.0717 32.8554 40.6945 33.2862 38.532H37.1521C36.8007 43.0392 33.6377 45.6 28.7061 45.6Z" fill="white"/>
                    <path d="M49.3373 25.2747H42.5465C41.4015 25.2747 40.4945 26.2762 40.4945 27.4258V45.3063H44.5758V38.17H49.632C53.634 38.17 55.7766 35.6661 55.7766 31.7166C55.7766 27.551 53.5773 25.2747 49.3373 25.2747ZM48.8725 34.5507H44.8932V28.746H49.0765C50.9811 28.746 51.9561 29.7135 51.9561 31.6597C51.9674 33.606 50.9471 34.5848 48.8725 34.5507H48.8725Z" fill="#007EE5"/>
                    </svg>
                    <div class="d-flex align-items-center">
                      <p class="ml-2 mb-0 d-flex align-items-center cp-point-card-title">Pay with Points</p>
                      <!-- NEED LATER  -->
                      <!-- <div class="mx-0 ml-2 row justify-content-between align-items-center">
                        <p class="rw-merchant-text mb-0"><span>Merchant: </span>Alternative Compassion Center</p>
                        <a class="merchant-select-btn ml-1" href="javascript:void(0)" @click="showModal('changeMerchantModal')">change</a>
                      </div> -->
                    </div>
                    <div class="rw-beta-tag dark">
                      Beta
                    </div>
                  </div>
                </div>
                <hr class="white">
                <div class="cp-point-card-bottom p-0">
                    <PriceRange 
                    @change="rangeOnSlide"
                    @input="inputChange"
                    v-bind:owned-points="getTotalPoints()" 
                    v-bind:generic-points="parseInt(wheelPoints.canpay_points.reward_point)" 
                    v-bind:merchant-points="selectedMerchant && selectedMerchant.reward_point ? parseInt(selectedMerchant.reward_point) : 0" 
                    v-bind:exchange-rate="parseFloat(wheelPoints.canpay_points.exchange_rate)"
                    v-bind:minimum-redeem-points="parseInt(wheelPoints.canpay_points.minimum_redeem_points)"
                    v-model="minimumSpendPoints"
                    />
                </div>
              </div>
            </div>
        </div>
      </div>

      <div class="row" style="margin-top: 20px">
        <div
          class="col-12 d-flex flex-column align-items-start"
          style="margin: auto"
        >
          <h5 class="paymentcode-boxl-title mb-0">Payment Code</h5>
        </div>
      </div>
      <div class="row" v-if="copyTextShow">
        <div class="col-12 d-flex flex-column" style="margin: auto">
          <div class="payment-pin-copy-label p-3">Payment Code Copied</div>
        </div>
      </div>
      <div class="row"  style="position: relative">
        <div class="col-12 d-flex flex-column" style="margin: auto">
          <div class="payment-pin-label payment-pin-padding mt-0" >{{payment_pin}}</div>
        </div>
        <span style="position: absolute;
        right: 35px;
        top: 50%;
        transform: translateY(-50%);" id="copy-button-text" @click="copyPaymentPin()"
          ><svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            style="width: 30px; color: gray"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-copy"
          >
            <rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect>
            <path
              d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"
            ></path></svg></span>
      </div>
      <div class="row">
        <div class="col-12 d-flex flex-column" style="margin: auto">
          <div class="payment-pin-label px-3">
            <img v-bind:src="qr_code" class="qr-code my-0" />
          </div>
        </div>
      </div>
    </div>
    <div class="mx-auto" v-else-if="!showPaymentPIN && hasTransaction">
      <enter-tip :transactiondetails="transactiondetails"></enter-tip>
    </div>
    <div class="one mx-auto history-div" v-else>
      <label class="paymentcode-boxl-title text-center">
        Open
        <a
          href="https://www.canpaydebit.com/about-us/terms-conditions/"
          class="hyperlink"
        >
          <u>www.CanPayApp.com</u>
        </a>
        on your mobile device to make payments.
      </label>
    </div>
</div>
    <!------------------------------------------------------------------------>
    <!-----------------------  MODAL FOR EXPIRED QRCODE  !------------------------>
    <!--------------------------------------------------------------------------->
    <b-modal
      ref="payment-code-expired"
      hide-footer
      v-b-modal.modal-center
      no-close-on-backdrop
      modal-backdrop
      hide-header
      id="payment-code-expired"
      centered
      title="BootstrapVue"
    >
      <div class="color">
        <div class="purchaserpower-modal-text">
          <div
            class="col-4 offset-4"
            style="
              top: 10px;
              height: 100px;
              padding-right: 0px !important;
              padding-left: 0px !important;
            "
          >
            <svg
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 100 125"
              style="enable-background: new 0 0 100 125; margin-left: -5px"
              xml:space="preserve"
              fill="#f05f5f"
              width="110"
              height="110"
            >
              <title>112all</title>
              <polygon
                points="43.9,44.2 26.5,44.2 26.5,48.2 47.9,48.2 47.9,18.2 43.9,18.2 "
              />
              <path
                d="M86.4,53.6C91,30.9,76.3,8.8,53.6,4.3S8.8,14.4,4.3,37.1s10.1,44.8,32.8,49.3c5.4,1.1,11,1.1,16.5,0
	c7.5,10.6,22.2,13.2,32.8,5.7s13.2-22.2,5.7-32.8C90.6,57.1,88.6,55.2,86.4,53.6z M45.4,83.3c-20.9,0-37.9-16.9-37.9-37.8
	S24.5,7.6,45.4,7.6s37.9,16.9,37.9,37.8c0,0,0,0,0,0c0,2-0.2,4-0.5,6C71,46,57,51.1,51.5,62.9c-2.9,6.3-2.9,13.6,0,19.9
	C49.5,83.1,47.4,83.3,45.4,83.3L45.4,83.3z M72.9,92.4c-10.8,0-19.6-8.7-19.6-19.5c0-10.8,8.7-19.6,19.5-19.6S92.4,62,92.4,72.8
	c0,0,0,0,0,0C92.4,83.7,83.7,92.4,72.9,92.4L72.9,92.4z"
              />
              <rect x="70.8" y="60" width="4" height="18.8" />
              <rect x="70.8" y="82" width="4" height="4.8" />
            </svg>
          </div>
          <div class="d-block text-center">
            <label class="purchasepower-def-label">Payment Code Expired</label>
          </div>
          <label
            class="purchasepower-modal-text text-center"
            style="margin: 10px"
          >
          </label>
          <div class="text-center">
            <button
              type="button"
              class="mx-auto col-10 offset-1 btn-black"
              style="height: 60px"
              v-on:click="genereateQRCode"
            >
              <label class="purchasepower-modal-ok-label"
                >Generate New Code</label
              >
            </button>
          </div>
        </div>
      </div>
    </b-modal>
    <!---------------------------------------------------------------->
    <!-----------------------  MODAL FOR PURCHASE POWER  !----------------->
    <!---------------------------------------------------------------->
    <b-modal
      ref="purchase-modal"
      hide-footer
      v-b-modal.modal-center
      no-close-on-backdrop
      modal-backdrop
      hide-header
      id="purchase-modal"
      centered
      title="BootstrapVue"
    >
      <div class="color">
        <div class="purchaserpower-modal-text">
          <div class="col-12 text-center mt-5">
            <svg
              v-if="currentUser.bank_link_type == 1"
              version="1.1"
              id="Layer_1"
              xmlns:cc="http://creativecommons.org/ns#"
              xmlns:dc="http://purl.org/dc/elements/1.1/"
              xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
              xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
              xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
              xmlns:svg="http://www.w3.org/2000/svg"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 26.5 33.1"
              style="enable-background: new 0 0 26.5 33.1"
              xml:space="preserve"
              width="75"
              height="75"
              fill="#149240"
            >
              <g transform="translate(0,-270.54165)">
                <path
                  d="M17.1,272.1c-0.3,0-0.6,0.1-0.8,0.2l-5.6,3.2H3.4c-1,0-1.8,0.8-1.8,1.8v16.3c0,1,0.8,1.8,1.8,1.8h18.8c1,0,1.8-0.8,1.8-1.8
		v-4.8h0.4c0.3,0,0.5-0.2,0.5-0.5v-4.7c0-0.3-0.2-0.5-0.5-0.5h-0.4v-5.7c0-1-0.8-1.8-1.8-1.8h-2l-1.5-2.6
		C18.3,272.4,17.7,272.1,17.1,272.1L17.1,272.1z M17.2,273.2c0.2,0,0.4,0.1,0.6,0.4l2.5,4.4H8.7l8-4.6
		C16.9,273.2,17,273.2,17.2,273.2L17.2,273.2z M3.4,276.6h5.4l-2.2,1.2H4.1c-0.7,0-0.7,1,0,1h18.8v4.2h-3.3c-1.6,0-2.9,1.3-2.9,2.9
		s1.3,2.9,2.9,2.9h3.3v4.8c0,0.4-0.3,0.7-0.7,0.7H3.4c-0.4,0-0.7-0.3-0.7-0.7v-16.3C2.6,277,2.9,276.6,3.4,276.6L3.4,276.6z
		 M20.8,276.6h1.4c0.4,0,0.7,0.3,0.7,0.7v0.5h-1.4L20.8,276.6z M19.6,284.1h4.3v3.6h-4.3c-1,0-1.8-0.8-1.8-1.8
		C17.7,284.9,18.5,284.1,19.6,284.1z M19.8,284.9c-0.6,0-1.1,0.5-1.1,1c0,0.6,0.5,1,1.1,1s1.1-0.5,1.1-1
		C20.9,285.4,20.4,284.9,19.8,284.9z"
                />
              </g>
            </svg>
            <svg
              version="1.1"
              v-else
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 64 55"
              style="enable-background: new 0 0 64 55"
              xml:space="preserve"
              fill="#149240"
              width="100"
              height="100"
            >
              <g>
                <path
                  d="M63,0H1C0.4,0,0,0.4,0,1v32c0,0.6,0.4,1,1,1h62c0.6,0,1-0.4,1-1V1C64,0.4,63.6,0,63,0z M62,32H2V2h60V32z"
                />
                <path
                  d="M6,26c1.7,0,3,1.3,3,3c0,0.6,0.4,1,1,1h44c0.6,0,1-0.4,1-1c0-1.7,1.3-3,3-3c0.6,0,1-0.4,1-1V9c0-0.6-0.4-1-1-1
		c-1.7,0-3-1.3-3-3c0-0.6-0.4-1-1-1H10C9.4,4,9,4.4,9,5c0,1.7-1.3,3-3,3C5.4,8,5,8.4,5,9v16C5,25.6,5.4,26,6,26z M24,17
		c0-6.1,3.6-11,8-11s8,4.9,8,11s-3.6,11-8,11S24,23.1,24,17z M53.1,6C53.5,8,55,9.5,57,9.9v14.2c-2,0.4-3.5,1.9-3.9,3.9H37.3
		c3.1-2.8,4.8-6.8,4.7-11c0.1-4.2-1.6-8.2-4.7-11H53.1z M7,9.9C9,9.5,10.5,8,10.9,6h15.8c-3.1,2.8-4.8,6.8-4.7,11
		c-0.1,4.2,1.6,8.2,4.7,11H10.9C10.5,26,9,24.5,7,24.1V9.9z"
                />
                <path
                  d="M50,21c1.7,0,3-1.3,3-3s-1.3-3-3-3s-3,1.3-3,3S48.3,21,50,21z M50,17c0.6,0,1,0.4,1,1s-0.4,1-1,1s-1-0.4-1-1S49.4,17,50,17
		z"
                />
                <path
                  d="M14,21c1.7,0,3-1.3,3-3s-1.3-3-3-3s-3,1.3-3,3S12.3,21,14,21z M14,17c0.6,0,1,0.4,1,1s-0.4,1-1,1s-1-0.4-1-1S13.4,17,14,17
		z"
                />
                <path
                  d="M63,48H43v-4c0-0.3-0.1-0.5-0.3-0.7l-4-4c-0.4-0.4-1-0.4-1.4,0c0,0,0,0,0,0l-4,4C33.1,43.5,33,43.7,33,44v4H1
		c-0.6,0-1,0.4-1,1s0.4,1,1,1h32v4c0,0.6,0.4,1,1,1h8c0.6,0,1-0.4,1-1v-4h20c0.6,0,1-0.4,1-1S63.6,48,63,48z M41,53h-6v-8.6l3-3l3,3
		V53z"
                />
              </g>
            </svg>
          </div>
          <br />
          <br />
          <div class="d-block text-center">
            <label class="purchasepower-def-label">
              <b v-if="currentUser.bank_link_type == 1"
                >What is my Purchase Power?</b
              >
              <b v-else>What is my Spending Limit?</b>
            </label>
          </div>
          <br />
          <h3
            v-if="currentUser.bank_link_type == 1"
            class="purchasepower-modal-text text-center"
          >
            Purchase Power is your available spending with CanPay. Your Purchase
            Power is impacted by many factors including: recent purchases
            through CanPay, your total CanPay spending history, and how long
            you've had a CanPay account. Negative items, like NSF payment
            returns, can also impact your Purchase Power.
          </h3>
          <h3 v-else class="purchasepower-modal-text text-center">
            Your Spending Limit is based on the default <br />
            CanPay limits. If you would <br />
            like a custom limit, please Direct Link <br />your bank below and
            gain access to <br />CanPay’s proprietary Purchase Power<br />
            tool. Purchase Power calculates <br />custom purchase limits which
            can<br />
            include higher individual purchases <br />and spending more often.
          </h3>
          <br />
          <br />
          <div class="text-center">
            <button
              type="button"
              class="mx-auto col-10 offset-1 btn-black"
              style="height: 60px"
              @click="hideModal('purchase-modal')"
            >
              <label class="purchasepower-modal-ok-label">OK</label>
            </button>
          </div>
        </div>
      </div>
    </b-modal>
    <!------------------------------------------------------------------------>
    <!-----------------------  MODAL FOR TRANSACTION DECLINED  !------------------------>
    <!--------------------------------------------------------------------------->
    <b-modal
      ref="transaction-modal"
      hide-footer
      v-b-modal.modal-center
      no-close-on-backdrop
      modal-backdrop
      hide-header
      id="transaction-modal"
      centered
      title="BootstrapVue"
    >
      <div class="color">
        <div class="col-12 text-center">
          <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width="120"
            height="120"
            viewBox="0 0 100 125"
            style="enable-background: new 0 0 100 125"
            xml:space="preserve"
            fill="#e14343"
          >
            <path
              d="M96.2,47.5l-22-38c-0.4-0.6-1-1-1.7-1h-44c-0.7,0-1.4,0.4-1.7,1l-22,37.9c-0.4,0.6-0.4,1.4,0,2l22,38.1c0.4,0.6,1,1,1.7,1
	h44c0.7,0,1.4-0.4,1.7-1l22-38C96.6,48.9,96.6,48.1,96.2,47.5z M71.3,84.5H29.7L8.8,48.4l20.8-35.9h41.7l20.8,36L71.3,84.5z
	 M50.5,60.5c1.1,0,2-0.9,2-2v-30c0-1.1-0.9-2-2-2c-1.1,0-2,0.9-2,2v30C48.5,59.6,49.4,60.5,50.5,60.5z M48.4,66.4
	c-0.6,0.6-0.9,1.3-0.9,2.1c0,0.8,0.3,1.6,0.9,2.1s1.3,0.9,2.1,0.9c0.8,0,1.6-0.3,2.1-0.9c0.6-0.6,0.9-1.3,0.9-2.1
	c0-0.8-0.3-1.6-0.9-2.1C51.5,65.3,49.5,65.3,48.4,66.4z"
            />
          </svg>
        </div>
        <div class="d-block text-center">
          <label class="purchasepower-def-label">Transaction Declined</label>
        </div>
        <label v-if="no_refresh_button == 1"
          class="purchasepower-modal-text"
          style="margin: 10px; text-align: justify;"
          >{{ transactionDeclinedMessage }}</label>
        <label v-else
          class="purchasepower-modal-text text-center"
          style="margin: 10px"
          >{{ transactionDeclinedMessage }}</label
        >
        <br />
        <br />
        <div class="text-center">
          <button v-if="no_refresh_button == 1 && relink_banking_button == 0"
            type="button"
            class="mx-auto col-10 offset-1 btn-black"
            style="height: 60px"
            v-on:click="$router.push('/pay')"
          >
            <label class="purchasepower-modal-ok-label">Go to Home</label>
          </button>
          <button v-else-if="no_refresh_button == 1 && relink_banking_button == 1"
            type="button"
            class="mx-auto col-10 offset-1 btn-black"
            style="height: 60px"
            v-on:click="$router.push('/banklinking')"
          >
            <label class="purchasepower-modal-ok-label">Relink Banking</label>
          </button>
          <button v-else
            type="button"
            class="mx-auto col-10 offset-1 btn-black"
            style="height: 60px"
            v-on:click="refreshQRCode()"
          >
            <label class="purchasepower-modal-ok-label">Refresh</label>
          </button>
        </div>
      </div>
    </b-modal>
    <!----- MODAL FOR NO ACTIVE BANK----->
    <b-modal
      ref="no-primary-account-modal"
      hide-footer
      v-b-modal.modal-center
      no-close-on-backdrop
      modal-backdrop
      hide-header
      id="no-primary-account-modal"
      centered
      title="BootstrapVue"
    >
      <div class="color">
        <div class="col-12 text-center">
          <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width="120"
            height="120"
            viewBox="0 0 100 125"
            style="enable-background: new 0 0 100 125"
            xml:space="preserve"
            fill="#e14343"
          >
            <path
              d="M96.2,47.5l-22-38c-0.4-0.6-1-1-1.7-1h-44c-0.7,0-1.4,0.4-1.7,1l-22,37.9c-0.4,0.6-0.4,1.4,0,2l22,38.1c0.4,0.6,1,1,1.7,1
	h44c0.7,0,1.4-0.4,1.7-1l22-38C96.6,48.9,96.6,48.1,96.2,47.5z M71.3,84.5H29.7L8.8,48.4l20.8-35.9h41.7l20.8,36L71.3,84.5z
	 M50.5,60.5c1.1,0,2-0.9,2-2v-30c0-1.1-0.9-2-2-2c-1.1,0-2,0.9-2,2v30C48.5,59.6,49.4,60.5,50.5,60.5z M48.4,66.4
	c-0.6,0.6-0.9,1.3-0.9,2.1c0,0.8,0.3,1.6,0.9,2.1s1.3,0.9,2.1,0.9c0.8,0,1.6-0.3,2.1-0.9c0.6-0.6,0.9-1.3,0.9-2.1
	c0-0.8-0.3-1.6-0.9-2.1C51.5,65.3,49.5,65.3,48.4,66.4z"
            />
          </svg>
        </div>
        <label
          class="purchasepower-modal-text"
          style="margin: 10px; text-align: justify;"
          >{{ transactionDeclinedMessage }}</label>
        <br />
        <br />
        <div class="text-center">
          <button
            type="button"
            class="mx-auto col-10 offset-1 btn-black"
            style="height: 60px"
            v-on:click="$router.push('/banklinking')"
          >
            <label class="purchasepower-modal-ok-label">Relink Banking</label>
          </button>
        </div>
      </div>
    </b-modal>

    <!----- MODAL FOR LAST RANSACTION CONFIRMATION----->
    <b-modal
      ref="confirm-modal"
      hide-footer
      v-b-modal.modal-center
      modal-backdrop
      hide-header
      id="confirm-modal"
      centered
      no-close-on-backdrop
      no-close-on-esc
    >
      <div class="color">
        <div class="col-12 text-center">
          <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width="120"
            height="120"
            viewBox="0 0 100 125"
            style="enable-background: new 0 0 100 125"
            xml:space="preserve"
            fill="#e14343"
          >
            <path
              d="M96.2,47.5l-22-38c-0.4-0.6-1-1-1.7-1h-44c-0.7,0-1.4,0.4-1.7,1l-22,37.9c-0.4,0.6-0.4,1.4,0,2l22,38.1c0.4,0.6,1,1,1.7,1
	h44c0.7,0,1.4-0.4,1.7-1l22-38C96.6,48.9,96.6,48.1,96.2,47.5z M71.3,84.5H29.7L8.8,48.4l20.8-35.9h41.7l20.8,36L71.3,84.5z
	 M50.5,60.5c1.1,0,2-0.9,2-2v-30c0-1.1-0.9-2-2-2c-1.1,0-2,0.9-2,2v30C48.5,59.6,49.4,60.5,50.5,60.5z M48.4,66.4
	c-0.6,0.6-0.9,1.3-0.9,2.1c0,0.8,0.3,1.6,0.9,2.1s1.3,0.9,2.1,0.9c0.8,0,1.6-0.3,2.1-0.9c0.6-0.6,0.9-1.3,0.9-2.1
	c0-0.8-0.3-1.6-0.9-2.1C51.5,65.3,49.5,65.3,48.4,66.4z"
            />
          </svg>
        </div>
        <div class="purchaserpower-modal-text">
          <div class="d-block text-center">
            <label class="purchasepower-def-label">
              <div v-html="transaction_msg"></div>
            </label>
          </div>
          <div class="row justify-content-center">
            <div class="col-11 row">
              <div class="col-6">
                <button
                @click="cancel('confirm-modal')"
                class="btn btn-danger btn-md center-block tip-btn m-0 mt-5 w-100"
                style="height: 60px!important;border-radius: 7px!important;"
                >
                  <label class="forgetpassword-ok-label">Cancel</label>
                </button>
              </div>
              <div class="col-6">
                <button
                @click="hideConfirmModal('confirm-modal')"
                class="btn btn-danger btn-md center-block tip-ok-btn m-0 mt-5  w-100"
                style="height: 60px!important;border-radius: 7px!important;"
                >
                  <label class="forgetpassword-ok-label">Make another payment</label>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>

    <!------------------------------------------------------------------------>
    <!-----------------------  MODAL FOR REWARD WHEEL SHUTDOWN  !------------------------>
    <!--------------------------------------------------------------------------->
    <b-modal
      ref="reward-wheel-shutdown-modal"
      hide-footer
      v-b-modal.modal-center
      no-close-on-backdrop
      modal-backdrop
      hide-header
      id="reward-wheel-shutdown-modal"
      centered
      title="BootstrapVue"
    >
      <h5 class="text-center mb-4">Error Code 2001. Please connect with CanPay Admin for more details</h5>
      <div class="row justify-content-center">
        <div class="col-6">
          <button class="btn other-points-btn btn-primary w-100 mt-0" @click="hideModal('reward-wheel-shutdown-modal')">OK</button>
        </div>
      </div>
    </b-modal>


    <!------------------------------------------------------------------------>
    <!-----------------------  MODAL FOR REWARD POINT INFO  !------------------------>
    <!--------------------------------------------------------------------------->
    <b-modal
    ref="reward-point-info-modal"
    hide-footer
    v-b-modal.modal-center
    no-close-on-backdrop
    modal-backdrop
    hide-header
    id="reward-point-info-modal"
    centered
    title="BootstrapVue"
    >
    <div class="color">
      <div class="purchaserpower-modal-text">
        <div class="col-12 text-center mt-5 mb-5">
          <svg
            version="1.1"
            v-if="manual_bank_link"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 64 55"
            style="enable-background: new 0 0 64 55"
            xml:space="preserve"
            fill="#149240"
            width="100"
            height="100"
          >
            <g>
              <path
                d="M63,0H1C0.4,0,0,0.4,0,1v32c0,0.6,0.4,1,1,1h62c0.6,0,1-0.4,1-1V1C64,0.4,63.6,0,63,0z M62,32H2V2h60V32z"
              />
              <path
                d="M6,26c1.7,0,3,1.3,3,3c0,0.6,0.4,1,1,1h44c0.6,0,1-0.4,1-1c0-1.7,1.3-3,3-3c0.6,0,1-0.4,1-1V9c0-0.6-0.4-1-1-1
c-1.7,0-3-1.3-3-3c0-0.6-0.4-1-1-1H10C9.4,4,9,4.4,9,5c0,1.7-1.3,3-3,3C5.4,8,5,8.4,5,9v16C5,25.6,5.4,26,6,26z M24,17
c0-6.1,3.6-11,8-11s8,4.9,8,11s-3.6,11-8,11S24,23.1,24,17z M53.1,6C53.5,8,55,9.5,57,9.9v14.2c-2,0.4-3.5,1.9-3.9,3.9H37.3
c3.1-2.8,4.8-6.8,4.7-11c0.1-4.2-1.6-8.2-4.7-11H53.1z M7,9.9C9,9.5,10.5,8,10.9,6h15.8c-3.1,2.8-4.8,6.8-4.7,11
c-0.1,4.2,1.6,8.2,4.7,11H10.9C10.5,26,9,24.5,7,24.1V9.9z"
              />
              <path
                d="M50,21c1.7,0,3-1.3,3-3s-1.3-3-3-3s-3,1.3-3,3S48.3,21,50,21z M50,17c0.6,0,1,0.4,1,1s-0.4,1-1,1s-1-0.4-1-1S49.4,17,50,17
z"
              />
              <path
                d="M14,21c1.7,0,3-1.3,3-3s-1.3-3-3-3s-3,1.3-3,3S12.3,21,14,21z M14,17c0.6,0,1,0.4,1,1s-0.4,1-1,1s-1-0.4-1-1S13.4,17,14,17
z"
              />
              <path
                d="M63,48H43v-4c0-0.3-0.1-0.5-0.3-0.7l-4-4c-0.4-0.4-1-0.4-1.4,0c0,0,0,0,0,0l-4,4C33.1,43.5,33,43.7,33,44v4H1
c-0.6,0-1,0.4-1,1s0.4,1,1,1h32v4c0,0.6,0.4,1,1,1h8c0.6,0,1-0.4,1-1v-4h20c0.6,0,1-0.4,1-1S63.6,48,63,48z M41,53h-6v-8.6l3-3l3,3
V53z"
              />
            </g>
          </svg>
          <svg
            v-else
            version="1.1"
            id="Layer_1"
            xmlns:cc="http://creativecommons.org/ns#"
            xmlns:dc="http://purl.org/dc/elements/1.1/"
            xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
            xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
            xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
            xmlns:svg="http://www.w3.org/2000/svg"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 26.5 33.1"
            style="enable-background: new 0 0 26.5 33.1"
            xml:space="preserve"
            width="75"
            height="75"
            fill="#149240"
          >
            <g transform="translate(0,-270.54165)">
              <path
                d="M17.1,272.1c-0.3,0-0.6,0.1-0.8,0.2l-5.6,3.2H3.4c-1,0-1.8,0.8-1.8,1.8v16.3c0,1,0.8,1.8,1.8,1.8h18.8c1,0,1.8-0.8,1.8-1.8
v-4.8h0.4c0.3,0,0.5-0.2,0.5-0.5v-4.7c0-0.3-0.2-0.5-0.5-0.5h-0.4v-5.7c0-1-0.8-1.8-1.8-1.8h-2l-1.5-2.6
C18.3,272.4,17.7,272.1,17.1,272.1L17.1,272.1z M17.2,273.2c0.2,0,0.4,0.1,0.6,0.4l2.5,4.4H8.7l8-4.6
C16.9,273.2,17,273.2,17.2,273.2L17.2,273.2z M3.4,276.6h5.4l-2.2,1.2H4.1c-0.7,0-0.7,1,0,1h18.8v4.2h-3.3c-1.6,0-2.9,1.3-2.9,2.9
s1.3,2.9,2.9,2.9h3.3v4.8c0,0.4-0.3,0.7-0.7,0.7H3.4c-0.4,0-0.7-0.3-0.7-0.7v-16.3C2.6,277,2.9,276.6,3.4,276.6L3.4,276.6z
M20.8,276.6h1.4c0.4,0,0.7,0.3,0.7,0.7v0.5h-1.4L20.8,276.6z M19.6,284.1h4.3v3.6h-4.3c-1,0-1.8-0.8-1.8-1.8
C17.7,284.9,18.5,284.1,19.6,284.1z M19.8,284.9c-0.6,0-1.1,0.5-1.1,1c0,0.6,0.5,1,1.1,1s1.1-0.5,1.1-1
C20.9,285.4,20.4,284.9,19.8,284.9z"
              />
            </g>
          </svg>
        </div>
        <div class="d-block text-center">
          <label class="purchasepower-def-label">
            <b v-if="manual_bank_link">What is my Spending Limit?</b>
            <b v-else>What is my Purchase Power?</b>
          </label>
        </div>
        <br />
        <h3
          v-if="manual_bank_link"
          class="purchasepower-modal-text text-justify"
        >
          Your Spending Limit is based on the default CanPay limit. If you
          would like a custom limit, please Direct Link your bank below
          and gain access to CanPay’s proprietary Purchase Power tool.
          Purchase Power calculates custom purchase limits which can
          include higher individual purchases and spending more often.
        </h3>
        <h3 v-else class="purchasepower-modal-text text-justify">
          Purchase Power is your available spending with CanPay. Your
          Purchase Power is impacted by many factors including: recent
          purchases through CanPay, your total CanPay spending history,
          and how long you've had a CanPay account. Negative items, like
          NSF payment returns, can also impact your Purchase Power.
        </h3>
        <div v-if="manual_bank_link" class="text-center mt-5">
          <div class="row mt-2 mb-3">
            <div class="col-12">
              <button
                type="button"
                class="btn-modal-black p-1"
                @click="hideModal('reward-point-info-modal')"
              >
                Close
              </button>
            </div>
          </div>
        </div>
        <div v-else class="text-center mt-5">
          <button
            type="button"
            class="mx-auto col-10 offset-1 btn-black"
            style="height: 60px"
            @click="hideModal('reward-point-info-modal')"
          >
            <label class="purchasepower-modal-ok-label">OK</label>
          </button>
        </div>
      </div>
    </div>
    </b-modal>


    <!------------------------------------------------------------------------>
    <!-----------------------  MODAL FOR CHANGE MERCHANT  !------------------------>
    <!--------------------------------------------------------------------------->
    <b-modal
      ref="changeMerchantModal"
      hide-footer
      v-b-modal.modal-center
      :no-close-on-backdrop="false"
      modal-backdrop
      hide-header
      id="changeMerchantModal"
      centered
      title="BootstrapVue"
    >
      <div @click="changeMerchant" class="change-merchant-selection">
        <svg width="76" viewBox="0 0 76 56" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M24.6588 0.464064H24.4588L24.4588 0.664073L24.459 4.26261L24.459 4.4626H24.659L49.1301 4.4626C56.1269 4.4626 61.8855 10.2214 61.8855 17.218L61.8855 34.8187L55.1192 28.0529L54.9778 27.9115L54.8363 28.053L52.3173 30.572L52.1758 30.7135L52.3173 30.8549L63.7433 42.2809L63.8847 42.4223L64.0261 42.2809L75.4515 30.8549L75.5929 30.7135L75.4515 30.572L72.9324 28.053L72.791 27.9115L72.6495 28.053L65.8839 34.8186V17.218C65.8839 7.9306 58.4172 0.464062 49.1299 0.464062L24.6588 0.464064ZM1.08612 25.3034L0.944678 25.4448L1.08614 25.5863L3.60571 28.1052L3.74713 28.2466L3.88853 28.1052L10.6542 21.3395V38.9401C10.6542 48.2275 18.1209 55.6941 27.4082 55.6941H51.8793H52.0793V55.4941V51.8957V51.6957H51.8793L27.4082 51.6957C20.4114 51.6957 14.6527 45.9369 14.6527 38.9402L14.6534 21.3396L21.419 28.1053L21.5605 28.2468L21.7019 28.1053L24.221 25.5862L24.3624 25.4448L24.221 25.3034L12.795 13.8774L12.6535 13.736L12.5121 13.8774L1.08612 25.3034Z" fill="black" stroke="black" stroke-width="0.4"/>
        </svg>
        <p>Change Merchant</p>
      </div>
      <hr class="m-0">
      <div @click="removeMerchant" class="change-merchant-selection">
        <svg width="66" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M33.26 65.62C51.1322 65.62 65.62 51.1325 65.62 33.26C65.62 15.3878 51.1322 0.9 33.26 0.9C15.3878 0.9 0.9 15.3878 0.9 33.26C0.9 51.1322 15.3878 65.62 33.26 65.62ZM33.2606 61.8362C17.4787 61.8362 4.68444 49.0416 4.68444 33.26C4.68444 17.4781 17.4787 4.6838 33.2606 4.6838C49.0426 4.6838 61.8368 17.4781 61.8368 33.26C61.8368 49.0419 49.0426 61.8362 33.2606 61.8362ZM45.3517 42.6756L35.9361 33.26L45.3517 23.8444C46.0909 23.1053 46.0909 21.9081 45.3517 21.1689C44.6126 20.4298 43.4154 20.4298 42.6762 21.1689L33.2606 30.5845L23.8451 21.1689C23.1059 20.4298 21.9087 20.4298 21.1696 21.1689C20.4304 21.9081 20.4304 23.1053 21.1696 23.8444L30.5852 33.26L21.1696 42.6756C20.4304 43.4147 20.4304 44.6119 21.1696 45.3511C21.9087 46.0902 23.1059 46.0902 23.8451 45.3511L33.2606 35.9355L42.6762 45.3511C43.4154 46.0902 44.6126 46.0902 45.3517 45.3511C46.0909 44.6119 46.0909 43.4147 45.3517 42.6756Z" fill="black" stroke="black" stroke-width="0.2"/>
        </svg>
        <p>Remove Merchant</p>
      </div>
    </b-modal>

    <!-- MERCHANT OPTIONS  -->
    <transition name="slide" type="animation">
    <div v-show="showMerchantPoint" class="merchant-options-card" id="merchantOptionsCard">
        <MerchantPoints v-model="selectedMerchant" :merchant-points="wheelPoints.other_points">
           <template v-slot:backbtn="">
            <a class="merchant-header-btn" href="javascript:void(0)" @click="redirectBackToDashboard">
              <svg width="30" viewBox="0 0 74 52" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M2.13235 23.5735L23.8235 1.88235C25 0.705882 26.9118 0.705882 28.0882 1.88235C29.2647 3.05882 29.2647 4.97059 28.0882 6.14706L11.5441 22.6912H56.9853C58.6765 22.6912 60 24.0147 60 25.7059C60 27.3971 58.6765 28.7206 56.9853 28.7206H11.5441L28.0882 45.2647C29.2647 46.4412 29.2647 48.3529 28.0882 49.5294C27.5 50.1176 26.7647 50.4118 25.9559 50.4118C25.1471 50.4118 24.4118 50.1176 23.8235 49.5294L2.13235 27.8382L0 25.7059L2.13235 23.5735Z" fill="black"/>
              </svg>
            </a>
          </template>
        </MerchantPoints>
    </div>
    </transition>
    <!--- Problematic Account Modal Start --->
    <problematic-account-modal ref="ProblematicAccountModal" :handleCanPayLoader="handleCanPayLoader" :connectMXBankAccount="connectMXBankAccount" :account_details="problematic_account_details"></problematic-account-modal>
    <!--- Problematic Account Modal End --->
    <!--- MX Connect Widget Start --->
    <mx-connect-widget ref="mxRef" :on-event="handleWidgetEvent" :widget-url="mxConnectUrl" />
    <!--- MX Connect Widget End --->
</div>
</template>
<script>
/**
 * Dashboard is homepage where the qrcode and payment pin is present
 */
import { db } from "../../firebaseConfig.js";
import tip from "./Tip.vue";
import ProblematicAccountModal from "../Modal/ProblematicAccountModal.vue"
import api from "../../api/payment.js";
import VueLoadImage from "vue-load-image";
import constants from "../Common/constant.js";
import Loading from "vue-loading-overlay";
import PriceRange from "./components/PriceRange.vue";
import rewardwheelapi from "../../api/rewardwheel.js";
import MerchantPoints from "./components/MerchantPoints.vue";
import CanPayLoader from "../CustomLoader/CanPayLoader.vue";
import MxConnectWidget from '../MxConnectWidget.vue';
import moment from 'moment';
import {
  isMobile,
  isMobileOnly,
  isIOS,
  isMobileSafari,
  browserName,
} from "mobile-device-detect";

export default {
  name: "Dashboard",
  /**
   * @description -
   * @returns {any}
   * qr_url => this will store the qr-url from generate qrcode api
   * qr_code => this will store the qr code in base64 format
   * payment_pin => this will store the payment pin
   */

  data() {
    let self = this;
    return {
      img: "",
      isLoading: false,
      fullPage: true,
      showPaymentPIN: true,
      transactionDeclinedMessage: "",
      transactionModal: "show_transaction_modal",
      purchasePowerModal: "show_purchase_power_modal",
      qrCodeModal: "show_qrcode_modal",
      hasTransaction: false,
      transactiondetails: {},
      nopurchasepower: localStorage.getItem("nopurchasepower"),
      users: {},
      qr_url: "",
      qr_code: "",
      payment_pin: "",
      purchase_power: localStorage.getItem("purchasepower"),
      nopaymentpin: false,
      currentUser: localStorage.getItem("consumer_login_response")
        ? JSON.parse(localStorage.getItem("consumer_login_response"))
        : null,
      generalError: false,
      transaction_msg: "",
      browserName: browserName,
      copyPaymentCode: null,
      copyTextShow: false,

      minimumSpendPoints: 0,
      wheelPoints: {},
      transactionPointForm: {
        generic_reward_point: null,
        reward_wheel_id: null,
        reward_point: null
      },
      last_four_account_no: "",
      rwState: '',
      manual_bank_link: true,
      showMerchantPoint: false,
      selectedMerchant: {},
      no_refresh_button: 0,
      relink_banking_button:0,
      fix_connection:false,
      problematic_account_details:{
        bank_name:'',
        account_no:'',
        active_bank_id:''
      },
      showProblematicModal:false,
      mxConnectUrl:null
    };
  },
  firestore() {
    return {
      //users: db.collection("users"),
    };
  },
  created() {
    if (isMobile || browserName == "Android Browser") {
      this.showPaymentPIN = true;
    }
    this.getLastTransaction();
    this.getdata();
    this.getWheelPoints();

    var currentUser = localStorage.getItem("consumer_login_response")
      ? JSON.parse(localStorage.getItem("consumer_login_response"))
      : null;

    this.last_four_account_no = currentUser.account_no;

    if (currentUser.bank_link_type == 1) {
      this.manual_bank_link = false;
    }
  },

  mounted() {
    const consumer_login_response = JSON.parse(localStorage.getItem("consumer_login_response"));
    this.rwState = consumer_login_response && consumer_login_response.rw_state ? consumer_login_response.rw_state : ''

    this.$root.$emit("changeWhiteBackground", [false, false, "PayDashboardHeader"]);
    this.$intercom.update({
      hide_default_launcher: true,
    });
  },
  beforeDestroy() {
    // Show the launcher again
    this.$intercom.update({
      hide_default_launcher: false,
    });
  },
  components: {
    "enter-tip": tip,
    "vue-load-image": VueLoadImage,
    Loading,
    PriceRange,
    MerchantPoints,
    CanPayLoader,
    ProblematicAccountModal,
    MxConnectWidget
  },

  watch: {
    copyTextShow: {
      handler(value) {

          setTimeout(() => {
            this.copyTextShow = false;
          }, 3000);

      },
      immediate: true, // This ensures the watcher is triggered upon creation
    },
  },
  methods: {
    /**
     *  on load of the page this function will get the qrcode everytime
     */
    handleWidgetEvent(event) {
      var self = this;
      console.log('MX PostMessage: ', event);
      if (event.type === 'mx/connect/memberConnected') {
        console.log("Got here");
        self.$router.push({ name: 'mx-success', params: { 'user_guid': event.metadata.user_guid, 'member_guid':event.metadata.member_guid, 'mx_user_type':'challenged', 'fix_connection': self.fix_connection, 'active_bank_id': self.problematic_account_details.active_bank_id } })
        if(self.fix_connection == true){
          self.fix_connection = false;
        }
      } else if (event.type === 'mx/connect/loaded') {
        // Handle widget loading completion
      }
    },
    connectMXBankAccount() {
      let self = this;
      self.fix_connection = true;
      this.isLoading = true;
      let request = {
            active_bank_id:this.problematic_account_details.active_bank_id
      };
      accountAPI
        .directLinkGenerateForMx(request)
        .then((response) => {
          if (response.code == 200) {
            const finicityConnectUrl = response.data;
            window.finicityConnect.launch(finicityConnectUrl, {
              selector: "#connect-container",
              overlay: "rgba(255,255,255, 0)",
              success: function (data) {
                console.log("Yay! We got data", data);
                if (data.code == 200) {
                  if (self.update_bank == true) {
                    // self.updateBankDetails();
                  } else {
                    //now store the details at canpay end
                    self.storeBankDetails();
                    setTimeout(() => {
                      self.$refs.RealAccountNumberModal.checkForRealAccountNumber();
                    }, 2000);
                  }
                }
              },
              cancel: function () {
                self.isLoading = false;
                console.log("The user cancelled the iframe");
              },
              error: function (err) {
                console.log(err);
                self.isLoading = false;
                console.error(
                  "Some runtime error was generated during Finicity Connect",
                  err
                );
              },
              loaded: function () {
                self.isLoading = false;
                console.log(
                  "This gets called only once after the iframe has finished loading"
                );
              },
              route: function (event) {
                self.isLoading = false;
                console.log(
                  "This is called as the user progresses through Connect"
                );
              },
              user: function (event) {
                if (event.data.errorCode) {
                  console.log(event.data.data.institutionId);
                  setTimeout(() => {
                    window.finicityConnect.destroy();
                    //if error code is present then call the connect fix api
                    self.generateConnectFix(
                      event.data.data.institutionId,
                      null
                    );
                  }, 2000);
                }
              },
            });
          } else {
            self.isLoading = false;
          }
        })
        .catch((err) => {
          self.fix_connection = false;
          self.isLoading = false;
          console.log(err);
        });
    },
    handleCanPayLoader(value){
      this.isLoading = value;
    },
    showPurchasePower(){
     let self = this;
     return localStorage.getItem("purchasepower");
    },
    showModal(data) {
      this.$refs[data].show();
    },
    hideModal(data) {
      this.$refs[data].hide();
    },
    hideConfirmModal(data) {
      this.$refs[data].hide();
      this.genereateQRCode();
    },

    genereateQRCode: function () {
      let self = this;
      this.isLoading = true;
      api
        .getQRCode()
        .then(function (response) {
          if (response.code == 200) {
            self.nopaymentpin = false;
            self.qr_url = response.data.qr_url.slice(7, -4);
            self.getQRCode();
            //Add Spacing between every 3 characters in payment pin

            var paymentCodestr = response.data.payment_pin;
            var paymentCodeparts = paymentCodestr.match(/.{1,3}/g);
            var paymentCodeValue = paymentCodeparts.join(" ");
            self.payment_pin = paymentCodeValue;
            self.copyPaymentCode = response.data.payment_pin;


          }
        })
        .catch(function (err) {
          if(err.response.data.code == 596){
            self.transactionDeclinedMessage = err.response.data.message;
            self.showModal("no-primary-account-modal");
            return false;
          }
          if(err.response.data.code!= 597){
            alert(err.response.data.message);
          }
          if (err.response.data.code == 401) {
            self.$router.push("/login");
          }
          if (err.response.data.code == 598) {
            self.$router.push("/pay");
          }
          if (err.response.data.code == 597) {
            self.$router.push("/pay");
          }
        });
    },
    refreshQRCode() {
      let self = this;
      self.hideModal("transaction-modal");
      
      self.transactionPointForm.reward_wheel_id = null
      self.transactionPointForm.reward_point = null
      self.minimumSpendPoints = 0
      if(self.wheelPoints.other_points.length > 0){
        self.wheelPoints.other_points.forEach((element, index) => {
          self.wheelPoints.other_points[index].spend_point = 0;
        });
      }
      self.purchase_power = localStorage.getItem("purchasepower")

      self.genereateQRCode();
      if (self.generalError) {
        self.generalError = false;
        self.getLastTransaction();
      }
    },
    /**
     *  on load of the page this function will get the base64 qrcode
     */
    getQRCode: function () {
      let self = this;
      var request = {
        image_retrieval_code: self.qr_url,
      };
      api
        .getQRCodeImage(request)
        .then((response) => {
          if (response.code == 200) {
            if(this.showProblematicModal == true){
                this.$refs.ProblematicAccountModal.showModal();
                this.showProblematicModal = false;
            }
            self.qr_code = response.data;
            self.img = new Image();
            self.img.src = response.data;
            self.img.class = "qr-code";
            self.img.onload = function () {
              self.isLoading = false;
            };
            self.hideModal("payment-code-expired");
            // if(self.purchase_power_updated == true){
            //   setTimeout(()=>{
            //    self.purchase_power_updated = false;
            //   },1000);
            // }
          } else {
            alert(response.message);
          }
        })
        .catch(function (error) {
          self.hideModal("payment-code-expired");
        });
    },
    /**
     *  on click of settings button this function redirect  to settings page
     */

    getdata() {
      let ref = db
        .collection("users")
        .doc(String(String(this.currentUser.user_id)));
      ref.get().then((snapshot) => {
        if (snapshot.exists) {
          this.users = snapshot.data();
          const containsKey = (obj, key) => Object.keys(obj).includes(key);
          const hasName = containsKey(this.users, "transaction_successful");
          const hasNameQRCode = containsKey(this.users, "qr_code_expired");
          const mxActionNeeded = containsKey(this.users,"mx_action_needed");
          if (hasName == true) {
            if (this.users.transaction_successful == null) {
            } else {
              const isDeclined = containsKey(
                this.users.transaction_successful,
                "declined"
              );
              if (isDeclined) {
                this.transactionDeclinedMessage =
                  this.users.transaction_successful.message;
                  if(this.users.transaction_successful.no_refresh_button == 1){
                    this.no_refresh_button = 1;
                  }
                this.relink_banking_button = this.users.transaction_successful.relink_banking_button;
                this.showModal("transaction-modal");
                this.setdata();
              } else {
                this.transactiondetails = this.users.transaction_successful;
                this.$root.$emit("showSpinInfo", {show_spin_info: false});
                this.hasTransaction = hasName;
                this.showPaymentPIN = false;
                this.setdata();
              }
            }
          }

          if (hasNameQRCode == true) {
            if (this.users.qr_code_expired == 1) {
              this.transactionPointForm.reward_point = null
              if(this.wheelPoints.other_points.length > 0){
                this.wheelPoints.other_points.forEach((element, index) => {
                  this.wheelPoints.other_points[index].spend_point = 0;
                });
              }
              this.minimumSpendPoints = 0
              this.purchase_power = localStorage.getItem("purchasepower")
              this.showModal("payment-code-expired");
            }
          }          

            if(mxActionNeeded == true){
                if(this.users.mx_action_needed && this.users.mx_action_needed.mx_user_action_needed == 1){
                    this.problematic_account_details.bank_name = this.users.mx_action_needed.bank_name;
                    this.problematic_account_details.account_no = this.users.mx_action_needed.account_no;
                    this.problematic_account_details.active_bank_id = this.users.mx_action_needed.active_bank_id;
                    this.showProblematicModal = true;
                }
              }

        }

        ref.onSnapshot((convo) => {
          this.minimumSpendPoints = 0

          let source = convo.metadata.hasPendingWrites ? "Local" : "Server";
          // TODO: add messages to store
          let ref = db
            .collection("users")
            .doc(String(this.currentUser.user_id));
          ref.get().then((snapshot) => {
            if (snapshot.exists) {
              this.users = snapshot.data();
              this.users = snapshot.data();
              const containsKey = (obj, key) => Object.keys(obj).includes(key);
              const hasName = containsKey(this.users, "transaction_successful");
              const hasNameQRCode = containsKey(this.users, "qr_code_expired");
              const mxActionNeeded = containsKey(this.users,"mx_action_needed");
              if (hasName == true) {
                if (this.users.transaction_successful == null) {
                } else {
                  const isDeclined = containsKey(
                    this.users.transaction_successful,
                    "declined"
                  );
                  if (isDeclined) {
                    this.transactionDeclinedMessage =
                      this.users.transaction_successful.message;
                      if(this.users.transaction_successful.no_refresh_button == 1){
                        this.no_refresh_button = 1;
                      }
                      this.relink_banking_button = this.users.transaction_successful.relink_banking_button;
                    if (
                      this.transactionDeclinedMessage ==
                      "Network connection error, please refresh and try again."
                    ) {
                      this.generalError = true;
                    }
                    this.showModal("transaction-modal");
                    this.setdata();
                  } else {
                    this.transactiondetails = this.users.transaction_successful;
                    this.$root.$emit("showSpinInfo", {show_spin_info: false});
                    this.hasTransaction = hasName;
                    this.showPaymentPIN = false;
                    this.setdata();
                  }
                }
              }

              if (hasNameQRCode == true) {
                if (this.users.qr_code_expired == null) {
                } else if (this.users.qr_code_expired == 1) {
                  this.transactionPointForm.reward_point = null
                  if(this.wheelPoints.other_points.length > 0){
                    this.wheelPoints.other_points.forEach((element, index) => {
                      this.wheelPoints.other_points[index].spend_point = 0;
                    });
                  }
                  this.minimumSpendPoints = 0
                  this.purchase_power = localStorage.getItem("purchasepower")
                  this.showModal("payment-code-expired");
                }
              }

            if(mxActionNeeded == true){
                if(this.users.mx_action_needed && this.users.mx_action_needed.mx_user_action_needed == 1){
                    this.problematic_account_details.bank_name = this.users.mx_action_needed.bank_name;
                    this.problematic_account_details.account_no = this.users.mx_action_needed.account_no;
                    this.problematic_account_details.active_bank_id = this.users.mx_action_needed.active_bank_id;
                    this.showProblematicModal = true;
                }
              }

            }
          });
        });
      });
    },
    setdata() {
      var self = this;
      var washingtonRef = db
        .collection("users")
        .doc(String(this.currentUser.user_id));
      // Set the "capital" field of the city 'DC'
      return washingtonRef
        .update({
          transaction_successful: null,
          purchase_power_updated: null
        })
        .then(function () {
          console.log("Document successfully updated!");
        })
        .catch(function (error) {
          // The document probably doesn't exist.
          console.error("Error updating document: ", error);
        });
    },
    getLastTransaction() {
      var self = this;
      self.isLoading = true;
      api
        .getLastTransaction()
        .then(function (response) {
          if (response.code == 200) {
            if (response.data == null) {
              self.isLoading = false;
              self.genereateQRCode();
            } else {
              // show modal containing past transaction details to take confirmation from consumer
              self.transaction_msg = response.message;
              setTimeout(function(){
                self.isLoading = false;
                self.showModal("confirm-modal");
              }, 1000);
            }
          }
        })
        .catch(function (err) {
          console.log("err", err);
          if (err.response.data.message == 401) {
            this.$router.push("/login");
          }
        });
    },
    cancel(data) {
      this.$router.push("/pay");
      this.$refs[data].hide();
    },
    copyPaymentPin() {
      let self = this;
      navigator.clipboard.writeText(self.copyPaymentCode);
      var start = document.getElementById("copy-button-text").value;
      this.copyTextShow = true;
    },
    getWheelPoints(){
      rewardwheelapi
      .wheelPoints()
      .then((response) => {
        let pointsArr = response.data
        pointsArr.other_points.forEach((other_point, index) => {
          pointsArr.other_points[index]['spend_point'] = 0
          pointsArr.other_points[index]['disabled'] = false
        });
        this.wheelPoints = pointsArr
      })
      .catch(function (error) {
      });
    },
    rewardPointTransaction(payload){
      var self = this;
      rewardwheelapi
      .transactionRewardPoint(payload)
      .then((response) => {
        
      })
      .catch(function (error) {

        if(error.response.data.code == 596){
          self.showModal('reward-wheel-shutdown-modal');
          var consumer_login_response = JSON.parse(localStorage.getItem("consumer_login_response"));
          consumer_login_response.rw_state = 'not_allowed'
          self.rwState = 'not_allowed'
          self.purchase_power = localStorage.getItem("purchasepower")
          localStorage.setItem(
            "consumer_login_response",
            JSON.stringify(consumer_login_response)
          );
          const event = new CustomEvent('rwStateCahnged', {detail: consumer_login_response});
          document.dispatchEvent(event);
        }

        self.transactionPointForm.reward_point = null
        if(self.wheelPoints.other_points.length > 0){
          self.wheelPoints.other_points.forEach((element, index) => {
            self.wheelPoints.other_points[index].spend_point = 0;
          });
        }
        self.showModal("payment-code-expired");
        self.minimumSpendPoints = 0
      });
    },
    rangeOnSlide(val){
 
      var self = this;
      let value = 0;
      if(val > 0){
        value = val;
        var original_purchase_power = localStorage.getItem("purchasepower").replace("$", "")
        var original_purchase_power = original_purchase_power.split(",").join("")
        var original_purchase_power = parseFloat(original_purchase_power).toFixed(2)

        var pointAmount = parseFloat(parseFloat(self.wheelPoints.canpay_points.exchange_rate) * value).toFixed(2)

        self.purchase_power = '$' + this.pointNumberFormatter(parseFloat(parseFloat(pointAmount) + parseFloat(original_purchase_power)))

      }else{
        value = 0;
        self.purchase_power = localStorage.getItem("purchasepower")
      }

      self.transactionPointForm.generic_reward_point = value
      self.rewardPointTransaction(self.transactionPointForm);
    },
    openModal(modal){
      this.$refs[modal].show()
    },
    inputChange(val) {
      var self = this;
      let value = 0;
      if(val > 0){
        value = val;
        var original_purchase_power = localStorage.getItem("purchasepower").replace("$", "")
        var original_purchase_power = original_purchase_power.split(",").join("")
        var original_purchase_power = parseFloat(original_purchase_power).toFixed(2)

        var pointAmount = parseFloat(parseFloat(self.wheelPoints.canpay_points.exchange_rate) * value).toFixed(2)

        self.purchase_power = '$' + this.amountNumberFormatter(parseFloat(parseFloat(pointAmount) + parseFloat(original_purchase_power)))
      
      }else{
        value = 0;
        self.purchase_power = localStorage.getItem("purchasepower")
      }
    },
    changeMerchant(){
      this.showMerchantPoint = true

      var app = document.getElementById('nav-drawer');
      app.setAttribute('style', 'overflow-y:hidden !important');

      var card = document.getElementById('merchantOptionsCard');
      card.setAttribute('style', 'top:'+jQuery(document.getElementById('nav-drawer')).scrollTop()+'px !important');

      this.hideModal('changeMerchantModal')
    },
    removeMerchant(){
      this.showMerchantPoint = false
      this.selectedMerchant = {}
      this.hideModal('changeMerchantModal')
    },
    redirectBackToDashboard(){
      this.showMerchantPoint = false
      var app = document.getElementById('nav-drawer');
      app.setAttribute('style', 'overflow-y:scroll !important');
    },
    getTotalPoints(){
      var self = this;
      if(self.selectedMerchant && self.selectedMerchant.reward_point){
        return parseInt(self.wheelPoints.canpay_points.reward_point) + parseInt(self.selectedMerchant.reward_point)
      }else{
        return parseInt(self.wheelPoints.canpay_points.reward_point)
      }
    }
  },
  destroyed(){
    var app = document.getElementById('nav-drawer');
    app.setAttribute('style', 'overflow-y:scroll !important');
  }
};
</script>

<style lang="scss">
#confirm-modal___BV_modal_body_ {
  background-color: #ffffff;
  border-radius: 12px;
  margin: 10px;
}
#changeMerchantModal___BV_modal_content_{
  margin: 10px;
}
#changeMerchantModal___BV_modal_body_{
  background-color: #ffffff;
  padding: 0;
  border-radius: 10px;
}
#changeMerchantModal{
  padding-left: 0!important;
}
.other-points-btn.btn-primary{
  background-color: #118037;
  border-color: #118037;
}
.other-points-btn{
  padding: 10px!important;
  height: 45px;
  margin-top: 0;
}
.point-selected-alert{
  margin-bottom: 35px;
  padding: 8px;
}
.point-selected-alert.success{
  background: #15913f;
  color: white;
}
.point-selected-alert.error{
  background: #952200f7;
  color: white;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
.total-point-select-card{
  background: #118037;
  padding: 10px 10px;
  border-radius: 10px;
  color: #ffff;
  font-weight: bold;
}
#reward-wheel-shutdown-modal___BV_modal_body_{
  background-color: #fff;
  border-radius: 3px;
  padding: 30px 10px;
}

.merchant-point-card{
  background-color: #0e7532;
  border-radius: 8px;
  padding: 12px 20px;
  cursor: pointer;
}
hr.white {
    margin-top: 5px;
    margin-bottom: 5px;
    border: 0;
    border-top: 1px solid rgb(255 255 255 / 25%);
}
.reward-point-info-logo{
  position: absolute;
  top: -15px;
  right: -25px;
}
#reward-point-info-modal___BV_modal_content_{
  margin: 10px;
}
#reward-point-info-modal___BV_modal_body_{
  background-color: #fff;
  border-radius: 10px;
}
.bank-account-info{
  display: flex;
  position: absolute;
  right: 12px;
  top: 12px;
}
.touch-select-area{
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.payment-pin-padding{
  padding: 13px 10px;
}
.tm-icon{
  font-size: 10px;
}
.rw-merchant-text{
  font-size: 12px;
  color: #fff;
}
.rw-merchant-text span{
  font-weight: 600;
}
.merchant-select-btn{
  font-size: 10px;
  color: #fff;
  background: #0e5e2d;
  padding: 4px 8px;
  border-radius: 50px;
  text-decoration: none;
}
.merchant-select-btn:hover{
  text-decoration: none;
  color: #fff;
}
.merchant-select-btn:active{
  text-decoration: none;
}
.change-merchant-selection{
  display: flex;
  padding: 25px 20px;
  cursor: pointer;
}
.change-merchant-selection svg{
  width: 30px;
}
.change-merchant-selection p{
  margin: 0;
  font-weight: 18px;
  font-weight: 600;
  margin-left: 10px;
}


.merchant-options-card {
  background: #149240;
  position: fixed;
  bottom: -100%;
  opacity:1;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
}

// ********* Slide Animation ************
.slide-enter { 
  opacity: 0;
}

.slide-enter-active {
  animation: slide-in .3s ease-in forwards;
  transition: opacity .3s;
}


.slide-leave-active {
  animation: slide-out .3s ease-out forwards; 
  opacity: 1;
  transition: opacity .3s;
}
#no-primary-account-modal___BV_modal_body_ {
    border-radius: 10px;
    margin: 10px;
    background-color: #fff;
  }

@keyframes slide-in {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes slide-out {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(100%);
  }
}

@media only screen and ( min-width:280px) and ( max-width:700px) {
  .rw-merchant-text {
    font-size: 8px;
  }
  .merchant-select-btn {
    font-size: 8px;
    padding: 3px 8px; 
  }
}

@media only screen and ( min-width:320px) and ( max-width:700px) {
  .rw-merchant-text {
    font-size: 10px;
  }
  .merchant-select-btn {
    font-size: 9px;
    padding: 3px 8px; 
  }
}

@media only screen and ( min-width:376px) and ( max-width:800px) {
  .rw-merchant-text {
    font-size: 12px;
  }
  .merchant-select-btn{
    font-size: 10px;
    padding: 4px 8px;
  }
}

#updated-purchase-power___BV_modal_content_{
  background-color:#ffffff;
}
#updated-purchase-power___BV_modal_body_{
  background-color:#ffffff;
  border-radius:10px;
}
#updated-purchase-power-less___BV_modal_content_{
    background-color:#ffffff;
}
#updated-purchase-power-less___BV_modal_body_{
  background-color:#ffffff;
  border-radius:10px;
}
.black-button-ok{
 padding:8px 15px;
 width:100%;
 border-radius:10px;
 border:none;
 font-family: 'Montserrat';
 background-color: #000000;
 color:#ffffff;
 font-weight:bolder;
 margin-top:10px;
}
</style>
