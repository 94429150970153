<template>
<div>
  <div v-if="isLoading">
    <CanPayLoader/>
  </div>
  <div class="container">
    <div  class="row btn-gap">
      <div class="col-12 d-flex flex-column" style="margin: auto">
        <button
          type="button"
          class="btn-black btn-verify mb-0"
          @click="viewHistoricalTransaction()"
        >
          View Historical Transaction
        </button>
      </div>
    </div>
    <div class="row botton-gap btn-gap" v-if="terminalTypes.length && !showEnterTip">
      <div class="col-12 d-flex flex-column" style="margin: auto">
        <button
          type="button"
          class="btn-black btn-verify"
          v-on:click="transactionExport()"
        >
          Download Transaction Report
        </button>
      </div>
    </div>

    <div
    class="btn-gap pb-4"
    v-if="terminalTypes.length && !showEnterTip"
    >
      <div
      v-for="(terminals, index) in terminalTypes"
      :key="index"
      >
        <transaction-detail-card
        v-bind:terminal-types="terminalTypes"
        v-bind:terminals="terminals"
        v-bind:index="index"
        />
      </div>
    </div>

    <div
      class="one mx-auto history-div"
      v-if="!terminalTypes.length && this.showloader && !showEnterTip"
    >
      <DefaultLoader :color="'#fffff'" />
      <br />
      <h3 style="color: #ffffff">Loading</h3>
    </div>
    <div
      class="one mx-auto history-div"
      v-if="!terminalTypes.length && !this.showloader"
    >
      <span class="success-text">No transactions to display.</span>
      <br />
      <span class="success-sub-text">
        Your transaction history will show here once you make a purchase with CanPay.
      </span>
    </div>


    <!-- Leave a tip modal start -->
    <div>
      <b-modal
        ref="my-modal"
        hide-footer
        v-b-modal.modal-center
        modal-backdrop
        hide-header
        id="tip-modal-center"
        centered
        title="BootstrapVue"
        class="hsf"
      >
        <div class="row cross-btn-div">
          <button @click="hideModal" class="cross-btn">
            <svg
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 100 125"
              style="enable-background: new 0 0 100 125"
              xml:space="preserve"
              fill="#ffffff"
              height="30"
              width="30"
            >
              <g>
                <path
                  d="M85.7,14.3c-2.3-2.3-6.1-2.3-8.5,0L50,41.5L22.7,14.3c-2.3-2.3-6.1-2.3-8.5,0c-2.3,2.3-2.3,6.1,0,8.5L41.5,50L14.3,77.3
		c-2.3,2.3-2.3,6.1,0,8.5c1.2,1.2,2.7,1.8,4.2,1.8s3.1-0.6,4.2-1.8L50,58.5l27.3,27.3c1.2,1.2,2.7,1.8,4.2,1.8s3.1-0.6,4.2-1.8
		c2.3-2.3,2.3-6.1,0-8.5L58.5,50l27.3-27.3C88.1,20.4,88.1,16.6,85.7,14.3z"
                />
              </g>
            </svg>
          </button>
        </div>
        <div class="row">
          <svg
            class="dollar-icon"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 100 125"
            style="enable-background: new 0 0 100 125"
            xml:space="preserve"
            height="40"
            width="40"
            fil="#ffffff"
            fill="#ffffff"
          >
            <g id="Layer_2" />
            <g id="Layer_1">
              <g>
                <path
                  d="M5273.1,2400.1v-2c0-2.8-5-4-9.7-4s-9.7,1.3-9.7,4v2c0,1.8,0.7,3.6,2,4.9l5,4.9c0.3,0.3,0.4,0.6,0.4,1v6.4
			c0,0.4,0.2,0.7,0.6,0.8l2.9,0.9c0.5,0.1,1-0.2,1-0.8v-7.2c0-0.4,0.2-0.7,0.4-1l5.1-5C5272.4,2403.7,5273.1,2401.9,5273.1,2400.1z
			 M5263.4,2400c-4.8,0-7.4-1.3-7.5-1.8l0,0c0.1-0.5,2.7-1.8,7.5-1.8s7.3,1.3,7.5,1.8C5270.7,2398.7,5268.2,2400,5263.4,2400z"
                />
                <path
                  d="M5268.4,2410.3c-0.6,0-1,0.4-1,1s0.4,1,1,1h4.3c0.6,0,1-0.4,1-1s-0.4-1-1-1H5268.4z"
                />
                <path
                  d="M5272.7,2413.7h-4.3c-0.6,0-1,0.4-1,1s0.4,1,1,1h4.3c0.6,0,1-0.4,1-1C5273.7,2414.1,5273.3,2413.7,5272.7,2413.7z"
                />
                <path
                  d="M5272.7,2417h-4.3c-0.6,0-1,0.4-1,1s0.4,1,1,1h4.3c0.6,0,1-0.4,1-1C5273.7,2417.5,5273.3,2417,5272.7,2417z"
                />
              </g>
              <g>
                <path
                  d="M46.3,32.2h7.4c4,0,7.2,3.3,7.2,7.2h6.6c0-7.6-6.2-13.8-13.9-13.8h-0.4v-7.8h-6.6v7.8h-0.4c-7.6,0-13.9,6.2-13.9,13.8
			s6.2,13.8,13.9,13.8h7.4c4,0,7.2,3.2,7.2,7.2s-3.2,7.2-7.2,7.2h-7.4c-4,0-7.2-3.3-7.2-7.2h-6.6c0,7.6,6.2,13.8,13.9,13.8h0.4V82
			h6.6v-7.8h0.4c7.6,0,13.9-6.2,13.9-13.8s-6.2-13.8-13.9-13.8h-7.4c-4,0-7.2-3.3-7.2-7.2S42.3,32.2,46.3,32.2z"
                />
                <path
                  d="M50,2.5C23.8,2.5,2.5,23.8,2.5,50S23.8,97.5,50,97.5S97.5,76.2,97.5,50S76.2,2.5,50,2.5z M50,90.9
			C27.5,90.9,9.1,72.5,9.1,50S27.5,9.1,50,9.1S90.9,27.5,90.9,50S72.5,90.9,50,90.9z"
                />
              </g>
            </g>
          </svg>
          <label class="leave-a-tip-modal-label">Leave a TIP</label>
        </div>
        <div class="row">
          <div class="col-12">
            <div
              class="t-div"
              @click="selectTip(15)"
              :class="{ 'folder-selected': selected == 1 }"
              data-dismiss="modal"
            >
              <h5 class="h4-style">15%</h5>
              <h5 class="h4-style1">${{ tipamountFor15 }}</h5>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div
              class="t-div"
              @click="selectTip(20)"
              :class="{ 'folder-selected': selected == 2 }"
              data-dismiss="modal"
            >
              <h5 class="h4-style">20%</h5>
              <h5 class="h4-style1">${{ tipamountFor20 }}</h5>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div
              class="t-div"
              @click="selectTip(25)"
              :class="{ 'folder-selected': selected == 3 }"
              data-dismiss="modal"
            >
              <h5 class="h4-style">25%</h5>
              <h5 class="h4-style1">${{ tipamountFor25 }}</h5>
            </div>
          </div>
        </div>
        <div class="row custom-tip-div">
          <div class="col-12">
            <div
              class="input-group"
              :class="{ 'folder-selected': selected == 4 }"
            >
              <input
                class="form-control Custom-Tip"
                v-model="customTipAmount"
                inputmode="numeric"
                :placeholder="customplaceholder"
                style="height:50px; color: #000000; padding-right: 20px; !important;"
                @keypress="isNumber($event)"
              />
              <button
                type="button"
                class="btn3 btn-dark"
                data-dismiss="modal"
                :disabled="disableButton == false"
                @click="clickADD"
                style="
                  z-index: 5;
                  margin-left: -10px;
                  border-radius: 8px !important;
                "
              >
                ADD
              </button>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <label class="purchase-power-modal-text"
              >$ {{ transactionAmount }} Purchase</label
            >
          </div>
        </div>
      </b-modal>
    </div>
    <!-- MODAL FOR TRANSACTION ERROR MESSAGES -->
    <div>
      <b-modal
        ref="transaction-error-modal"
        hide-footer
        v-b-modal.modal-center
        modal-backdrop
        hide-header
        id="transaction-error-modal"
        centered
      >
        <div class="color">
          <div class="purchaserpower-modal-text">
            <div class="d-block text-center">
              <label class="purchasepower-def-label">
                {{ error_message }}
              </label>
            </div>
            <br />
            <br />
            <div class="text-center">
              <button
                type="button"
                class="mx-auto col-10 offset-1 btn-black"
                style="height: 60px"
                @click="hideTransactionErrorModal"
              >
                <label class="purchasepower-modal-ok-label">OK</label>
              </button>
            </div>
          </div>
        </div>
      </b-modal>
    </div>
    <!-- MODAL FOR TERMINAL TIP NOT ALLOWED -->
    <div>
      <b-modal
        ref="tip-not-allowed-modal"
        hide-footer
        v-b-modal.modal-center
        modal-backdrop
        hide-header
        id="tip-not-allowed-modal"
        centered
      >
        <div class="color">
          <div class="purchaserpower-modal-text">
            <div class="d-block text-center">
              <label class="purchasepower-def-label">
                {{ tip_error_message }}
              </label>
            </div>
            <br />
            <br />
            <div class="text-center">
              <button
                type="button"
                class="mx-auto col-10 offset-1 btn-black"
                style="height: 60px"
                @click="hideTipErrorModal"
              >
                <label class="purchasepower-modal-ok-label">OK</label>
              </button>
            </div>
          </div>
        </div>
      </b-modal>
    </div>

  </div>
</div>
</template>
<script>
import api from "../api/transactiondetails.js";
import pay from "./../api/payment.js";
import { DefaultLoader } from "vue-spinners-css";
import tip from "./Payment/Tip.vue";
import TransactionDetailCard from "./TransactionDetailCard.vue";
import moment from "moment";
import CanPayLoader from "./CustomLoader/CanPayLoader.vue";
import { saveAs } from "file-saver";
import Loading from "vue-loading-overlay";
export default {
  name: "TransactionDetails",
  data() {
    return {
      showloader: false,
      showEnterTip: false,
      transactiondetails: {},
      setupterminalModel: {
        type: "",
        transaction_type_id: "",
        terminal_name: "",
        tip_amount: "",
        searchKeyword: "0.01",
      },
      terminalTypes: [],
      customplaceholder: "Custom TIP",
      transactionAmount: "",
      finaltransactionAmount: "",
      transactionID: "",
      tipamountFor15: "",
      tipamountFor20: "",
      tipamountFor25: "",
      selected: -1,
      tipamount: "",
      customTipAmount: "",
      disableButton: false,
      showTipTransaction: false,
      finalTipamount: "",
      enableTip: 0,
      approve_label: "",
      isLoading: false,
      fullPage: true,
      error_message: "",
      tip_error_message: ""
    };
  },
  watch: {
    tipamount: function (newval, oldval) {
      if (this.tipamount.length > 0) {
        this.disableButton = true;
      }
    },
    customTipAmount: function (newval, oldval) {
      this.tipamount = this.customTipAmount;
    },
  },
  created() {
    this.transactionHistory();
  },
  components: {
    DefaultLoader,
    "enter-tip": tip,
    Loading,
    'transaction-detail-card': TransactionDetailCard,
    CanPayLoader
  },

  methods: {
    clickTransaction(transactionId) {
      let self = this;
      self.isLoading =true;
      var header = {
        "Content-type": "application/json",
      };
      var request = {
        transaction_id: transactionId,
      };
      api
        .getparticularTransaction(request)
        .then((response) => {
          if (response.code == 200) {
            self.transactiondetails = response.data;
            if (Number(self.transactiondetails.tip_amount) > 0) {
              self.finalTipamount = self.transactiondetails.tip_amount;
              self.showTipTransaction = true;
              self.finaltransactionAmount = (
                parseFloat(Number(self.transactiondetails.amount)) +
                parseFloat(Number(self.transactiondetails.tip_amount))
              ).toFixed(2);
            } else {
              self.finaltransactionAmount = Number(
                self.transactiondetails.amount
              ).toFixed(2);
            }
            self.transactionAmount = Number(self.transactiondetails.amount).toFixed(2);
            self.transactionID = self.transactiondetails.transaction_id;
            self.tipamountFor15 = ((self.transactionAmount * 15) / 100).toFixed(2);
            self.tipamountFor20 = ((self.transactionAmount * 20) / 100).toFixed(2);
            self.tipamountFor25 = ((self.transactionAmount * 25) / 100).toFixed(2);

            //changing the text
            self.approve_label =
            self.showTipTransaction != true
                ? "Payment Approved"
                : "Purchase Approved";

            self.$intercom.update({
              hide_default_launcher: true
            });
            self.showloader = false;
            self.isLoading = false;
            self.showModal();
            self.$forceUpdate();
          } else {
            self.showloader = false;
            self.isLoading = false;
            self.showTransactionErrorModal(response.message);
          }
        })
        .catch(function (err) {
          self.showloader = false;
          self.isLoading = false;
          self.showTipErrorModal(err.response.data.message);
        });
    },
    transactionamount(data) {
      if (parseFloat(this.terminalTypes[data].tip_amount) !== 0.0 && this.terminalTypes[data].tip_amount != null  ) {
        this.tip_amount = (
          parseFloat(this.terminalTypes[data].amount) +
          parseFloat(this.terminalTypes[data].tip_amount)
        ).toFixed(2);
        console.log(this.terminalTypes[data].tip_amount);
        return this.tip_amount;
      } else {
        this.tip_amount = parseFloat(this.terminalTypes[data].amount).toFixed(
          2
        );
        
        return this.tip_amount;
      }
    },
    transactiontime(data) {
      return moment
        .utc(this.terminalTypes[data].transaction_time)
        .local()
        .format(" DD MMM, YYYY | hh:m A");
    },
    transactionHistory: function () {
      this.showloader = true;
      var self = this;
      api
        .transactionHistory()
        .then(function (response) {
          if (response.code == 200) {
            self.terminalTypes = response.data;
            self.showloader = false;
          } else {
            if(response.message){
              alert(response.message);
            }
            self.showloader = false;
          }
        })
        .catch(function (error) {
          self.showloader = false;
        });
    },
    searchedTransactionHistory(data) {
      let self = this;
      var header = {
        "Content-type": "application/json",
      };
      var request = {
        search_keyword: data,
      };
      api
        .searchtransactionHistory(request)
        .then((response) => {
          if (response.code == 200) {
            self.terminalTypes = response.data;
            self.$forceUpdate();
            self.showloader = false;
          } else {
            self.showloader = false;
          }
        })
        .catch(function (error) {
          self.showloader = false;
        });
    },
    transactionExport() {
      this.showloader = true;
      var self = this;
      console.log(self.terminalTypes);
      var request = {
        report: self.terminalTypes,
      };
      api
        .exportTransaction(request)
        .then((response) => {
          var FileSaver = require("file-saver");
          var blob = new Blob([response], {
            type: "application/xlsx",
          });
          FileSaver.saveAs(
            blob,
            moment().format("MM/DD/YYYY") +
              "_Transaction__Details.xlsx"
          );
          self.loading = false;
        })
        .catch(function (error) {
          self.showloader = false;
        });
    },
    viewHistoricalTransaction() {
      this.$router.push("/historicaltransactiondetails").catch((err) => {});
    },
    showModal() {
      this.$refs["my-modal"].show();
    },
    hideModal() {
      this.$refs["my-modal"].hide();
    },
    clickADD() {
      this.payTip();
    },
    payTip() {
      if (this.tipamount.length > 0) {
        this.makeTransactionForTip();
      }
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      // first number should't be dot
      if(this.customTipAmount.length == 0 && charCode == 46){
        this.customTipAmount = 0.00;
      }
      // only allow positive number
      if ((charCode < 48 || charCode > 57) && (charCode !== 46 || this.customTipAmount.indexOf('.') !== -1)) { // 46 is dot
          evt.preventDefault();
      }
      // restrict to 2 decimal place
      else if (this.customTipAmount !== null && this.customTipAmount.indexOf('.') > -1 && (this.customTipAmount.split('.')[1].length > 1)) {
        evt.preventDefault();
      }
      else {
        return true;
      }
    },
    selectTip(amount) {
      switch (amount) {
        case 15:
          this.selected = 1;
          this.tipamount = this.tipamountFor15;
          this.payTip();
          break;
        case 20:
          this.selected = 2;
          this.tipamount = this.tipamountFor20;
          this.payTip();
          break;
        case 25:
          this.selected = 3;
          this.tipamount = this.tipamountFor25;
          this.payTip();
          break;
      }
    },
    makeTransactionForTip() {
      let self = this;
      var header = {
        "Content-type": "application/json",
      };
      var request = {
        transaction_id: this.transactionID,
        tip_amount: this.tipamount,
      };
      pay
        .insertTip(request)
        .then((response) => {
          if (response.code == 200) {
            self.showTransactionErrorModal(response.message);
            this.hideModal();
            this.transactionHistory();
            this.finaltransactionAmount = (
              parseFloat(response.data.amount) +
              parseFloat(response.data.tip_amount)
            ).toFixed(2);
            this.finalTipamount = response.data.tip_amount;
            this.showTipTransaction = true;
            this.enableTip = 0;
          } else {
            self.showTransactionErrorModal(response.message);
          }
        })
        .catch((err) => {
          self.showTipErrorModal(err.response.data.message);
        });
    },
    showTransactionErrorModal(msg) {
      this.error_message = msg;
      this.$refs["transaction-error-modal"].show();
    },
    hideTransactionErrorModal() {
      this.error_message = "";
      this.$refs["transaction-error-modal"].hide();
    },
    showTipErrorModal(msg) {
      this.tip_error_message = msg;
      this.hideModal();
      this.$refs["tip-not-allowed-modal"].show();
    },
    hideTipErrorModal() {
      this.tip_error_message = "";
      this.$refs["tip-not-allowed-modal"].hide();
      location.reload();
    },
  },
  mounted() {
    var self = this;
    var element = document.getElementsByClassName("content-wrap");
    if (element[0]) {
      element[0].style.setProperty("background-color", "#149240");
    }
    self.$root.$on("search-keyword", function (data) {
      self.showloader = true;
      if (data == "") {
        self.showloader = true;
        setTimeout(function () {
          self.transactionHistory();
        }, 3000);
      } else {
        setTimeout(function () {
          self.searchedTransactionHistory(data);
        }, 3000);
      }
    });

    self.$root.$emit("changeWhiteBackground", [
      false,
      false,
      "TransactionHistoryHeader",
    ]);

    
  },
  beforeDestroy() {
    this.$root.$off("search-keyword");
  },
};
</script>
<style>
  #tip-not-allowed-modal___BV_modal_body_ {
    background-color: #ffffff;
    border-radius: 12px;
    margin: 10px;
  }

.min-h{
    min-height: calc(100vh - 115px)!important;
}

.tran-card-link, .tran-card-link:hover{
    text-decoration: none;
    color: unset;
}
.tran-card{
    background: white;
    border-radius: 7px;
    text-align: left;
}

.tran-card-header{
    position: relative;
    padding: 15px 18px;
    border-radius: 7px;
}
.tran-store-logo{
    position: absolute;
    width: 80px;
    top: 10px;
    right: 10px;
}
.tran-title{
    font-weight: 600;
    font-size: 25px;
}
.tran-store-name{
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 15px;
}

.tran-point-card{
    padding: 7px 0;
}
.tran-point-card .title{
    margin: 0!important;
    padding: 0;
}
.tran-point-card .title span{
   font-size: 22px;
  font-weight: 800;
}
.tran-point-card .value{
  font-size: 14px;
  font-weight: 800;
}

.tran-timing{
    list-style: none;
    margin: 0;
    padding: 0;
    color: #9b9595;
    font-size: 13px;
    font-weight: 600;
}
.tran-timing li{
    display: flex;
    align-items: center;
}

.tran-card-body{
  padding: 10px 15px;
}

.tran-detail{
    align-items: center;
    margin: 0;
}
.tran-detail .title{
    font-weight: 700;
    font-size: 17px;
    margin: 0;
}
.tran-detail .value{
    font-weight: 700;
    font-size: 18px;
    margin: 0;
}

.tran-detail-point{
    align-items: center;
    margin: 0;
    color: #149240;
}
.tran-detail-point .title{
    font-weight: 700;
    font-size: 16px;
    margin: 0;
}
.tran-detail-point .value{
    font-weight: 700;
    font-size: 18px;
    margin: 0;
}

.tran-detail-total{
    align-items: center;
    background: #e3e3e3;
    padding: 13px 15px;
}
.tran-detail-total .title{
  font-weight: 700;
    font-size: 22px;
    margin: 0;
}
.tran-detail-total .value{
    font-weight: 700;
    font-size: 23px;
    margin: 0;
}
.tran-detail-icon-avatar{
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-color: #118037 !important;
    display: flex;
    justify-content: center;
    align-items: center;
}
.tran-detail-store{
  font-size: 13px;
    font-weight: 600;
}
.active-bg-card{
    background-color: #e3e3e3 !important;
    border-radius: 7px 7px 0 0;
}
</style>
