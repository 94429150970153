<template>
  <div class="container">
    <div>
      <div>
        <div class="row white-row1" style="margin-top: 25px">
          <div class="col-8 align-self-center">
            <label class="Store-title float-left">
              <b>{{ storeDetails.retailer }}</b>
            </label>
          </div>
          <div class="col-4 text-center">
            <a
              v-on:click="clickONfav(storeDetails.id, 'all')"
            >
              <svg
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 35 43.8"
                style="float: right !important; margin-top: 15px !important"
                xml:space="preserve"
                height="30"
                width="30"
                v-if="!storeDetails.is_favourite"
              >
                <g>
                  <path
                    d="M24.5,3.4c-2.8,0-5.4,1.2-7,3.3c-1.7-2.1-4.3-3.3-7-3.3c-4.9,0-9,4-9,9c0,6.1,4.5,7.3,15.3,19c0.2,0.2,0.5,0.3,0.7,0.3
		c0.3,0,0.5-0.1,0.7-0.3c10.6-11.4,15.4-12.9,15.3-19C33.5,7.4,29.5,3.4,24.5,3.4z M17.5,29.2C7,18,3.5,17.1,3.5,12.4
		c0-3.9,3.2-7,7-7c2.6,0,4.9,1.4,6.1,3.6c0.2,0.3,0.5,0.5,0.9,0.5c0.4,0,0.7-0.2,0.9-0.5c1.1-2.2,3.5-3.6,6.1-3.6c3.8,0,7,3.1,7,7
		C31.5,17.2,28,18,17.5,29.2z"
                  />
                </g>
              </svg>
            </a>
            <a
              v-on:click="clickONfav(storeDetails.id, 'all')"
            >
              <svg
                viewBox="0 -28 512.00002 512"
                xmlns="http://www.w3.org/2000/svg"
                fill="#1b9142"
                v-if="storeDetails.is_favourite"
                height="30"
                width="30"
                style="float: right !important; margin-top: 15px !important"
              >
                <path
                  d="m471.382812 44.578125c-26.503906-28.746094-62.871093-44.578125-102.410156-44.578125-29.554687 0-56.621094 9.34375-80.449218 27.769531-12.023438 9.300781-22.917969 20.679688-32.523438 33.960938-9.601562-13.277344-20.5-24.660157-32.527344-33.960938-23.824218-18.425781-50.890625-27.769531-80.445312-27.769531-39.539063 0-75.910156 15.832031-102.414063 44.578125-26.1875 28.410156-40.613281 67.222656-40.613281 109.292969 0 43.300781 16.136719 82.9375 50.78125 124.742187 30.992188 37.394531 75.535156 75.355469 127.117188 119.3125 17.613281 15.011719 37.578124 32.027344 58.308593 50.152344 5.476563 4.796875 12.503907 7.4375 19.792969 7.4375 7.285156 0 14.316406-2.640625 19.785156-7.429687 20.730469-18.128907 40.707032-35.152344 58.328125-50.171876 51.574219-43.949218 96.117188-81.90625 127.109375-119.304687 34.644532-41.800781 50.777344-81.4375 50.777344-124.742187 0-42.066407-14.425781-80.878907-40.617188-109.289063zm0 0"
                />
              </svg>
            </a>
          </div>
        </div>
        <!-- <div class="row white-row1">
          <div class="col-md-6">
            <label class="store-form Store-title">
              <b>{{ storeDetails.retailer }}</b>
            </label>
          </div>
        </div> -->
        <div class="row white-row1">
          <div class="col-md-6">
            <svg
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 24 30"
              style="enable-background: new 0 0 24 30; float: left"
              xml:space="preserve"
              height="20"
              width="20"
            >
              <path
                class="st0"
                d="M12,24C5.4,24,0,18.6,0,12S5.4,0,12,0s12,5.4,12,12S18.6,24,12,24z M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10
	s10-4.5,10-10S17.5,2,12,2z M14.2,15.7l-2.9-2.9C11.1,12.6,11,12.3,11,12V7c0-0.6,0.4-1,1-1s1,0.4,1,1v4.5l2.7,2.7
	c0.4,0.4,0.4,1.1,0,1.5C15.3,16.1,14.6,16.1,14.2,15.7L14.2,15.7z"
              />
            </svg>
            <label class="Open-time-text-style">{{
              opening_hours.text1
            }}</label>
            <label class="Open-style">{{ opening_hours.text2 }}</label>
          </div>
          <div class="hr-line"></div>
        </div>
      </div>
      <br />

      <!------------   ADDRESS  --------------------->
      <div>
        <div class="row white-row1">
          <div class="col-8 col-md-10">
            <label class="store-address float-left">
              <a v-on:click="openGoogleMap(storeDetails)">{{
                storeAddress(storeDetails)
              }}</a>
            </label>
          </div>
          <div class="col-4 col-md-2">
            <a v-on:click="openGoogleMap(storeDetails)">
              <svg
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 100 125"
                style="
                  enable-background: new 0 0 100 125;
                  margin-top: 30px;
                  float: right;
                "
                xml:space="preserve"
                height="30"
                width="30"
              >
                <path
                  d="M87.9,23.5L54,3.9c-2.4-1.4-5.6-1.4-8,0L12.1,23.5c-2.5,1.4-4,4.1-4,6.9v39.1c0,2.8,1.5,5.5,4,6.9L46,96.1
	c1.2,0.7,2.6,1.1,4,1.1s2.8-0.4,4-1.1l33.9-19.6c2.5-1.4,4-4.1,4-6.9V30.4C91.9,27.6,90.4,24.9,87.9,23.5z M87.9,69.6
	c0,1.4-0.8,2.8-2,3.5L52,92.6c-1.2,0.7-2.8,0.7-4,0L14.1,73c-1.2-0.7-2-2-2-3.5V30.4c0-1.4,0.8-2.8,2-3.5L48,7.4
	c0.6-0.3,1.3-0.5,2-0.5s1.4,0.2,2,0.5L85.9,27c1.2,0.7,2,2,2,3.5V69.6z"
                />
                <polygon
                  points="73,50 57,66 54,63 65,52 27,52 27,48 65,48 54,37 57,34 "
                />
              </svg>
            </a>
          </div>
        </div>

        <div class="row white-row1">
          <div class="col-md-6">
            <svg
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 307 495"
              style="enable-background: new 0 0 307 495; float: left !important"
              xml:space="preserve"
              height="20"
              width="20"
            >
              <g>
                <path
                  d="M153,0c85,0,154,69,154,153c0,88-90,191-154,243C90,344,0,241,0,153C0,69,69,0,153,0z M153,27C84,27,27,84,27,153
		c0,71,76,164,126,208c51-44,127-137,127-208C280,84,223,27,153,27z"
                />
                <path
                  d="M153,88c36,0,66,30,66,65c0,36-30,66-66,66c-35,0-65-30-65-66C88,118,118,88,153,88z M153,115c-21,0-38,17-38,38
		c0,22,17,38,38,38c22,0,38-16,38-38C191,132,175,115,153,115z"
                />
              </g>
            </svg>

            <label class="Open-style">{{ store_distance }}</label>
          </div>
          <div class="hr-line"></div>
        </div>
      </div>

      <!--------------------------- CONTACT NUMBER--------------->
      <div>
        <div class="row white-row1">
          <div class="col-8 col-md-10">
            <label class="store-form store-contact">
              <a
                :href="`tel:+${storeDetails.contact_no}`"
                style="color: #000000"
                >{{ storeDetails.contact_no }}</a
              >
            </label>
          </div>
          <div class="col-4 col-md-2">
            <a :href="`tel:+${storeDetails.contact_no}`" style="color: #000000">
              <svg
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 100 125"
                style="
                  enable-background: new 0 0 100 125;
                  float: right;
                  margin-top: 15px;
                "
                xml:space="preserve"
                height="30"
                width="30"
              >
                <path
                  d="M92.8,70.6L81.4,59.1c-2-2.1-4.3-3.1-6.8-3.1s-4.9,1.1-6.9,3.1L61,65.8c-0.6-0.3-1.2-0.6-1.8-0.9c-0.8-0.4-1.5-0.7-2.1-1.1
	c-6.2-3.9-11.8-9.1-17.2-15.7c-2.7-3.4-4.5-6.2-5.8-9.2c1.8-1.6,3.4-3.3,5-4.9c0.6-0.6,1.2-1.2,1.8-1.8c2.1-2.1,3.2-4.5,3.2-7
	s-1.1-4.9-3.2-7l-5.7-5.7c-0.7-0.7-1.3-1.3-1.9-2c-1.3-1.3-2.6-2.6-3.9-3.8c-2-2-4.3-3-6.8-3s-4.8,1-6.9,3L8.6,14
	c-2.6,2.6-4.1,5.8-4.4,9.5c-0.4,4.6,0.5,9.4,2.7,15.3c3.3,9.1,8.4,17.5,15.9,26.5c9.1,10.8,20,19.4,32.5,25.4
	c4.8,2.3,11.1,4.9,18.2,5.4c0.4,0,0.9,0,1.3,0c4.7,0,8.7-1.7,11.8-5.1l0.1-0.1c1.1-1.3,2.3-2.5,3.7-3.8c0.9-0.9,1.8-1.8,2.7-2.7
	C97,80.2,97,74.7,92.8,70.6z M89.2,81.1c-0.8,0.9-1.6,1.7-2.5,2.5c-1.3,1.3-2.7,2.6-4,4.1c-2.2,2.3-4.7,3.4-8.1,3.4
	c-0.3,0-0.7,0-1,0c-6.2-0.4-12-2.8-16.4-4.9C45.4,80.5,35,72.4,26.4,62.1c-7.1-8.5-11.9-16.5-15-25C9.5,31.9,8.7,27.8,9,23.9
	c0.2-2.5,1.2-4.6,3-6.4l7.1-7.1c1.1-1,2.3-1.6,3.4-1.6s2.3,0.5,3.3,1.6c1.3,1.2,2.5,2.4,3.8,3.7c0.7,0.7,1.3,1.3,2,2l5.7,5.7
	c1.2,1.2,1.7,2.3,1.7,3.4s-0.6,2.3-1.7,3.4c-0.6,0.6-1.2,1.2-1.8,1.8c-1.8,1.8-3.4,3.5-5.2,5.1l-0.1,0.1c-1.6,1.6-1.4,3.2-1,4.4
	c0,0.1,0,0.1,0.1,0.2c1.5,3.5,3.5,6.9,6.7,10.9c5.7,7.1,11.8,12.5,18.4,16.8c0.8,0.5,1.7,1,2.5,1.4s1.5,0.7,2.1,1.1
	c0.1,0,0.1,0.1,0.2,0.1c0.7,0.3,1.3,0.5,1.9,0.5c1.6,0,2.6-1,2.9-1.4l7.1-7.1c1.1-1.1,2.2-1.6,3.4-1.6c1.4,0,2.5,0.9,3.3,1.6
	L89.3,74C92.1,76.9,90.8,79.4,89.2,81.1z"
                />
              </svg>
            </a>
          </div>
          <div class="hr-line"></div>
        </div>
      </div>

      <!----------------------------------- HOURS--------------------->
      <div>
        <div class="row white-row1">
          <div class="col-8 col-md-10">
            <label class="store-form store-contact">Hours</label>
          </div>
          <div class="col-4 col-md-2">
            <svg
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 24 30"
              style="
                enable-background: new 0 0 24 30;
                float: right;
                margin-top: 15px;
              "
              xml:space="preserve"
              height="30"
              width="30"
            >
              <path
                class="st0"
                d="M12,24C5.4,24,0,18.6,0,12S5.4,0,12,0s12,5.4,12,12S18.6,24,12,24z M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10
	s10-4.5,10-10S17.5,2,12,2z M14.2,15.7l-2.9-2.9C11.1,12.6,11,12.3,11,12V7c0-0.6,0.4-1,1-1s1,0.4,1,1v4.5l2.7,2.7
	c0.4,0.4,0.4,1.1,0,1.5C15.3,16.1,14.6,16.1,14.2,15.7L14.2,15.7z"
              />
            </svg>
          </div>
        </div>
      </div>

      <!------------------------ OPEN HOURS------------------->
      <div v-for="(day, index) in weekdays_text" :key="index">
        <div class="row white-row1" style="height: 35px !important">
          <div class="col-6 col-md-6">
            <label class="store-form">{{ day.name }}</label>
          </div>
          <div class="col-6 col-md-6">
            <label class="Open-time">{{ day.timing_text }}</label>
          </div>
        </div>
      </div>
      <br />
      <br />
    </div>
    <div>
      <br />
    </div>
  </div>
</template>

<script>
import { DefaultLoader } from "vue-spinners-css";
import moment from "moment";
import api from "../../api/participantmerchant.js";

export default {
  name: "MerchantDetails",
  data() {
    return {
      opening_hours: {},
      weekdays_text: [],
      showloader: false,
      showEnterTip: false,
      transactiondetails: {},
      storeDetails: {},
      terminalTypes: [],
      is_favourite: true,
      currentLat: "",
      currentLong: "",
      store_distance: "",
    };
  },
  created() {
    this.storeDetails = localStorage.getItem("details")
      ? JSON.parse(localStorage.getItem("details"))
      : null;
    this.$root.$emit("loginapp", ["MerchantDetailsHeader"]);
    this.$root.$emit("changeWhiteBackground", [
      true,
      false,
      "MerchantDetailsHeader",
    ]);
  },
  components: {
    DefaultLoader,
  },

  methods: {
    storeAddress(storeDetails) {
      var full_address = "";
      full_address =
        full_address +
        (storeDetails.address != null ? storeDetails.address + " " : "");
      full_address =
        full_address +
        (storeDetails.city != null ? storeDetails.city + " " : "");
      full_address =
        full_address +
        (storeDetails.county != null ? storeDetails.county + " " : "");
      full_address =
        full_address +
        (storeDetails.state != null ? storeDetails.state + " " : "");
      full_address =
        full_address + (storeDetails.zip != null ? storeDetails.zip + " " : "");
      return full_address;
    },
    openGoogleMap(item) {
      const urlSuffix =
        item.lat +
        "?" +
        item.long +
        ":" +
        item.address +
        "," +
        item.city +
        "," +
        item.zip;

      window.open(
        "https://www.google.com/maps/search/?api=1&query=" + urlSuffix,
        "_blank"
      );
    },

    distance(lat1, lon1, lat2, lon2, unit) {
      if (lat1 == lat2 && lon1 == lon2) {
        return 0;
      } else {
        var radlat1 = (Math.PI * lat1) / 180;
        var radlat2 = (Math.PI * lat2) / 180;
        var theta = lon1 - lon2;
        var radtheta = (Math.PI * theta) / 180;
        var dist =
          Math.sin(radlat1) * Math.sin(radlat2) +
          Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
        if (dist > 1) {
          dist = 1;
        }
        dist = Math.acos(dist);
        dist = (dist * 180) / Math.PI;
        dist = dist * 60 * 1.1515;
        if (unit == "K") {
          dist = dist * 1.609344;
        }
        if (unit == "N") {
          dist = dist * 0.8684;
        }
        return dist.toFixed(1) + " mi";
      }
    },

    clickONfav(data, type) {
      this.makeFavourite(data, type);
    },
    makeFavourite(storeid, type) {
      var request = {
        store_id: storeid,
      };
      if (this.storeDetails.is_favourite == true) {
        this.storeDetails.is_favourite = false;
      } else {
        this.storeDetails.is_favourite = true;
      }
      api
        .makefav(request)
        .then((response) => {
          if (response.code == 200) {
            console.log("updated");
          }
        })
        .catch(function (error) {});
    },
  },
  mounted() {
    var self = this;
    self.currentLat = localStorage.getItem("current_lat");
    self.currentLong = localStorage.getItem("current_long");
    self.storeDetails = localStorage.getItem("details")
      ? JSON.parse(localStorage.getItem("details"))
      : null;
    self.store_distance = self.distance(
      self.storeDetails.lat,
      self.storeDetails.long,
      self.currentLat,
      self.currentLong,
      ""
    );
    self.opening_hours.text1 = "Not Available";
    self.opening_hours.text2 = "";
    if (!self.storeDetails.status) {
      var temp = {};
      self.opening_hours.text2 = "- Not Available";
      temp.name = "Not Available";
      temp.timing_text = "Not Available";
      self.weekdays_text.push(temp);
    } else {
      self.opening_hours.text1 = self.storeDetails.status.split('-')[0];
      self.opening_hours.text2 = '- '+ self.storeDetails.status.split('-')[1];
      self.weekdays_text = self.storeDetails.timing_arr;
      self.$root.$emit("loginapp", ["MerchantDetailsHeader"]);
      self.$root.$emit("changeWhiteBackground", [
        true,
        false,
        "MerchantDetailsHeader",
      ]);
    }
  },
};
</script>
