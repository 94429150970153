<template>
  <div class="container">
    <div class="row p-3">
      <div class="col-md-12">
        <div class="onboarding-terms-and-condition-header">
          <label>CanPay Terms of Service</label>
        </div>
      </div>
    </div>
    <div class="row p-3">
      <div class="col-md-12">
        <div class="onboarding-terms-and-condition-pragrapgh">
          <label>Please read these Terms of Service (the <strong> “Terms” </strong>) and our <a class="onboarding-terms-and-condition-ancher" v-on:click="redirectToPrivacyPolicy" target="_blank">Privacy Policy</a> carefully because they govern your use of the website located at <a class="onboarding-terms-and-condition-ancher" href="https://www.canpaydebit.com/" target="_blank"> https://www.canpaydebit.com/</a> (the “ <strong>Site</strong>”) and our ability to support your ability to make purchases with supported merchants via the Site and corresponding mobile application located at www.canpayapp.com (“ <strong>App </strong>”) offered by Trusted Debit, Inc. d/b/a CanPay (“ <strong>CanPay </strong>”). To make these Terms easier to read, the Site, our services and App are collectively called the “ <strong>Services</strong>.” </label>
        </div>
      </div>
    </div>

        <div class="row p-3">
      <div class="col-md-12">
    <div class="onboarding-terms-and-condition-sub-two">
      <label
        >IMPORTANT NOTICE REGARDING ARBITRATION: WHEN YOU AGREE TO THESE TERMS YOU ARE AGREEING
(WITH LIMITED EXCEPTION) TO RESOLVE ANY DISPUTE BETWEEN YOU AND CANPAY THROUGH BINDING,
INDIVIDUAL ARBITRATION RATHER THAN IN COURT. PLEASE REVIEW CAREFULLY SECTION 22 “DISPUTE
RESOLUTION” BELOW FOR DETAILS REGARDING ARBITRATION.
</br>
    </label>
        <label>
THESE TERMS INCLUDE, AMONG OTHER THINGS, YOUR AUTHORIZATION FOR DEBITS FROM AND TO YOUR
BANK ACCOUNT (AS DEFINED BELOW) VIA THE AUTOMATED CLEARINGHOUSE NETWORK (“ACH”).
      </label>
      
    </div>

</div>
</div>
    </br>
    <div class="row p-3">
      <div class="col-md-12">
        <ol class="onboarding-terms-and-condition-ol">
          <li class="onboarding-terms-and-condition-sub-two"> Agreement to Terms. </li>
        </ol>
        <div class="onboarding-terms-and-condition-pragrapgh">
          <label>Using our Services constitutes your express and unequivocal consent and agreement to the Terms. Each time you access and/or use the Services, you agree to be bound by these Terms. If you don’t agree to be bound by these Terms, do not use the Services.</label>
        </div>
      </div>
    </div>

    <div class="row p-3">
      <div class="col-md-12">
        <ol start="2" class="onboarding-terms-and-condition-ol">
          <li class="onboarding-terms-and-condition-sub-two"> Privacy Policy. </li>
        </ol>
        <div class="onboarding-terms-and-condition-pragrapgh">
          <label>Please review our Privacy Policy, which also governs your use of the Services, for information on how we collect, use and share your information. </label>
        </div>
      </div>
    </div>

    <div class="row p-3">
      <div class="col-md-12">
        <ol start="3" class="onboarding-terms-and-condition-ol">
          <li class="onboarding-terms-and-condition-sub-two"> Changes to these Terms or the Services. </li>
        </ol>
        <div class="onboarding-terms-and-condition-pragrapgh">
          <label>We may update the Terms from time to time in our sole discretion. If we do, we’ll let you know by posting the updated Terms on the Site, to the App and/or may also send other communications. It’s important that you review the Terms whenever we update them or when you use the Services. If you continue to use the Services after we have posted updated Terms it means that you accept and agree to the changes. If you don’t agree to be bound by the changes, you may not use the Services anymore. Because our Services are evolving over time we may change or discontinue all or any part of the Services, at any time and without notice, at our sole discretion.</label>
        </div>
      </div>
    </div>


    <div class="row p-3">
      <div class="col-md-12">
        <ol start="4" class="onboarding-terms-and-condition-ol">
          <li class="onboarding-terms-and-condition-sub-two"> Who May Use the Services? </li>
        </ol>
        <div class="onboarding-terms-and-condition-pragrapgh">
          <div class="col-md-12">
            <label>
              <strong>(a) &nbsp; </strong>
              <u>Eligibility</u>.&nbsp; You must be permitted to open a bank account to use our Services. You may use the Services only if you can form a binding contract with CanPay. You agree to use the Services in compliance with these Terms and all applicable local, state, national, and international laws, rules and regulations. Any use or access to the Services by anyone under 18  years of age is strictly prohibited. The Services are not available to any Customers of the Services previously removed from the Services by CanPay, or who are otherwise ineligible to use the Services under applicable law. </label>
            <label>
              <strong>(b)&nbsp; </strong>
              <u> Registration and Customer ID</u>. &nbsp;If you want to use certain features of the Services, you’ll have to create an account (“ <strong>Account </strong>”). You can do this via the Site or App. Upon registration, you will create an account by validating your phone number and creating an access code to login to your Account (“ <strong>Customer ID </strong>”). You are the only person authorized to use your Customer ID and access code and for maintaining the confidentiality of your Customer ID and access code. You shall not permit or allow other persons to have access to or use your Customer ID and access code. You are responsible for the use of the Services under your Customer ID. You are responsible for protecting the information on your computer such as by installing anti-virus software, updating your software, password protecting your files, and not permitting third party physical or electronic access to your computer or bank account. </label>
            <label>
              <strong>(c) &nbsp; </strong>
              <u>Accuracy of Account Information </u>.&nbsp; It’s important that you provide us with accurate, complete and up-to-date information for your Account and you agree to update such information to keep it accurate, complete and up-to-date. If you don’t, we might have to suspend or terminate your Account. You agree that you won’t disclose your Account access code to anyone and you’ll notify us immediately of any unauthorized use of your Account. You’re responsible for all activities that occur under your Account, whether or not you know about them. </label>
            <label>
              <strong>(d)&nbsp; </strong>
              <u> Compliance Screening and Inquiries</u>.&nbsp; We may also ask questions and verify information ourselves or through trusted entities we work with for verification and compliance purposes. You agree that, consistent with our Privacy Policy, we may use the information you provide and/or other information to verify your identity. This may include looking up available public information and asking other entities, like banks or credit unions, for information about you. You acknowledge and agree that, consistent with our Privacy Policy,  we may make any inquiries that we consider necessary, either directly or through third parties, concerning your identity and creditworthiness including, without limitation, requiring you to take steps to confirm ownership of your email address, phone number, or financial information, or verifying information against third party databases or through other sources. </label>
          </div>
        </div>
      </div>
    </div>
 <div class="row p-3">
      <div class="col-md-12">
      <ol start="5" class= "onboarding-terms-and-condition-ol">
    <li class="onboarding-terms-and-condition-sub-two">
Feedback.</li>
</ol>
  <div class="onboarding-terms-and-condition-pragrapgh">
    <label>  We appreciate feedback, comments, ideas, proposals and suggestions for improvements to
the Services (“<strong>Feedback </strong>”). If you choose to submit Feedback, you agree that we are free to use it without any
restriction or compensation to you. You grant to us a non-exclusive, transferable, worldwide, perpetual,
irrevocable, fully-paid, royalty-free license, with the right to sublicense, under any and all intellectual property
rights that you own or control to use, copy, modify, create derivative works based upon and otherwise exploit
the Feedback for any purpose.
</label>
       </div>
       </div>
       </div>
       
        <div class="row p-3">
      <div class="col-md-12">
    <ol start="6" class= "onboarding-terms-and-condition-ol">
    <li class="onboarding-terms-and-condition-sub-two" id="warranty-info">
CanPay Services.
</li>
</ol>
  <div class="onboarding-terms-and-condition-pragrapgh">
  <label>
 Subject to the terms and conditions of these Terms, you are hereby granted a non-
exclusive, limited, non-transferable, freely revocable license to use the Services as permitted by the features of
the Services. CanPay reserves all rights not expressly granted herein in the Services and the CanPay Content (as
defined below).
</label>
  <div class="col-md-12">
  </br>
  <label>
  <strong>(a) &nbsp;</strong><u> Non-commercial Use </u>.&nbsp; You may only use the Services for your own personal, non-commercial
use and not on behalf of or for the benefit of any third party.
</label>
 <label>
  <strong>(b) &nbsp;</strong><u> General</u>.&nbsp;When you make a purchase via the App (a “<strong>Transaction</strong>”), you expressly authorize us
to charge you for such Transaction. In addition to your Account information, we may request
additional information to process your Transaction including information about your Bank Account as
defined below, address, or other information necessary to initiate a Transaction (such information,
“<strong>Transaction Information</strong>”). You represent and warrant that you have the legal right to use all
payment method(s) represented by any such Transaction Information. You agree to be responsible for
paying any applicable sales and use taxes, and any applicable shipping and handling fees that may
apply to your purchase.
</label>

 <label>
  <strong>(c) &nbsp;</strong><u> Returns and Maintaining Sufficient Funds.</u>&nbsp;When using the Services, you understand and agree that it may take more than 60 days for CanPay to receive notice of the return or reversal of an ACH debit and/or to exercise any rights granted or reserved under these Terms. You agree at all times to maintain sufficient funds to satisfy all obligations in connection with your Account or your use of the Services, including payments, returns, disputes, and associated fees, and to add funds immediately if CanPay notifies you that your funds are insufficient. CanPay is not responsible for overdraft or other fees that your financial institution may charge you for canceled, declined, or returned transactions or otherwise.
</label>
 <label>
  <strong>(d) &nbsp;</strong><u> Declined Payments and Collections.</u>&nbsp;During your access and/or use of the Services, you may encounter a declined payment. A declined payment may be the result of insufficient funds in your Bank Account, a closed Bank Account, inability to locate your Bank Account, a dispute, or a reversal or denial for any reason by your financial institution. If your payment is declined for any reason, you will be notified via email and/or phone call; and, provided CanPay has not identified that you have engaged in prohibited conduct, CanPay will (i) disable your account and (ii) attempt to process the Transaction again (a “Representment”) using any and all payment options that have been connected to your CanPay account. A successful Representment will resolve the declined payment. An unsuccessful Representment will result in your account to remain disabled until the payment is resolved. In that instance, CanPay may, in its sole and absolute discretion, also take one or more of the following actions: (i) refuse to perform further Services, (ii) initiate another Representment(s), (iii)  charge you a penalty fee for each occurrence, (iv) report this information to any and all credit agencies and/or financial institutions, (v) immediately terminate this Agreement and your ability to use the Services; and/or (vi) engage a collection agency to pursue owed funds. If you request to remove your account while you have an outstanding declined payment(s), CanPay will not honor the request until the declined payment(s) is resolved.
</label>

 <label>
  <strong>(e) &nbsp;</strong><u> Merchant Purchases</u>.&nbsp;We facilitate your ability to make payments to third party merchants. We
do not hold, transfer, disperse, or otherwise process funds on your behalf.
</label>

 <label>
  <strong>(f) &nbsp;</strong><u> Refunds and Reversals</u>.&nbsp;ALL REFUNDS ARE PROCESSED BY MERCHANTS UNLESS OTHERWISE
REQUIRED BY LAW. CANPAY IS NOT RESPONSIBLE FOR MAKING ANY REFUNDS OR REVERSING ANY
PAYMENTS. YOU UNDERSTAND THAT ONCE A TRANSACTION IS PROCESSED, WE ARE NOT ABLE TO
REVERSE THE TRANSACTION THROUGH OUR SERVICES OR OTHERWISE PROVIDE A REFUND IN ANY
WAY FOR THE TRANSACTION. If an ACH debit from your bank account to fund a payment requested by
you through the Services is rejected or reversed for any reason, YOU AUTHORIZE CANPAY TO REVERSE
THE CORRESPONDING PAYMENT TO YOU VIA DEBIT FROM YOUR PAYMENT ACCOUNT, OR IF THERE
ARE INSUFFICIENT FUNDS IN YOUR PAYMENT ACCOUNT AT THE TIME OF DEBIT, YOU AGREE TO PAY
THE AMOUNT OF THE PAYMENT BACK TO CANPAY WITHIN 5 BUSINESS DAYS.You represent and
warrant that CanPay may resubmit a rejected or reversed funding debit to the Customer’s account, in
CanPay’s sole discretion. You also agree to pay all penalties, interest charges, late payment fees and
service fees related to any rejected, reversed or returned funding ACH debit.
</label>
  </div>
  </div>
   </div>
   </div>
    <div class="row p-3">
      <div class="col-md-12">
       <ol start="7" class= "onboarding-terms-and-condition-ol">
    <li class="onboarding-terms-and-condition-sub-two">
ACH Credit/Debit Authorization.
</li>
</ol>

  <div class="onboarding-terms-and-condition-pragrapgh">
  <label>
 By agreeing to these Terms, you authorize CanPay to electronically
debit your designated deposit account at your designated depository financial institution (your “Bank
Account”) via ACH and, if ever applicable, to correct erroneous debits.
</label>
  <div class="col-md-12">
   <label>
  (a) &nbsp;You acknowledge and agree that: (a) Transactions will go through ACH, (b) all Transactions are
governed by ACH rules, and (c) your ACH transactions will comply with U.S. law. You can learn more
about the ACH at<a
          class="onboarding-terms-and-condition-ancher"
          href="https://www.nacha.org/"
          target="_blank"
        > www.nacha.org.</a>
</label>
   <label>
  (b) &nbsp;By using the Service, you authorize us to initiate electronic withdrawals from your bank
account to fund purchases from designated merchants (“<strong>Transactions </strong>”) through electronic funding
methods (“<strong>Debits </strong>”). Debits will also be initiated to pay for any sales, use or other taxes payable on
merchant transactions, and for adjustments to these various amounts. You authorize us to direct your
designated financial institution (“<strong>Bank Account</strong>”) to: (a) charge each Transaction and/or Debit to Bank
Account and (b) respond to our inquiries regarding Bank Account. You agree that Bank Account is
located in the United States, and that all Transactions will be to accounts located in the United States.
</label>
<label>
  (c) &nbsp;Your Agreement and Representations. For purposes of ACH debits and credits, and all
electronic payments made or received through the Services, you: (i) authorize CanPay to initiate and
process Debits in accordance with your payment instructions and as provided under these Terms; and
(ii) agree to be subject to and comply with this Agreement, the Nacha Rules, and all other applicable
laws, rules and regulations.
</label>

<label>
  (d) &nbsp;In addition to any of your other representations and warranties in these Terms, you represent
that: (a) your browser is equipped with at least 128-bit security encryption; (b) you are capable of
printing, storing, or otherwise saving a copy of this electronic authorization for your records; and (c)
the ACH transactions you hereby authorize comply with applicable law.
</label>

<label>
  (e) &nbsp;<strong> Bank Verification.</strong> Prior to processing any Transaction, we may verify Bank Account
information. The verification process may include sending you texts, voice calls, or automated/pre-
recorded voice calls. If you provided your mobile phone number to us, you agree we may send such
communications to that number. You agree that as part of the verification process we may: (i) verify
Bank Account information by debiting between $0.01 and $1.00 from Bank Account, then crediting an
amount equal to or more than the same amount back to Bank Account, and requesting you to verify
the amount debited and credited, and/or (ii) verify Bank Account using your login credentials to your
financial institution and we may also ask you questions pertaining to your bank balance and/ or recent
debit transactions. We will only use this verification process to screen for fraud and will not otherwise
debit Bank Account, except for your use of Services. You hereby grant us a limited power of attorney
to initiate the actions in this Section as part of the bank verification process.
</label>

<label>
  (f) &nbsp;You further authorize us to: (i) initiate transactions with Bank Account to collect Transactions,
reinitiate, or initiate a new Debit to Bank Account if any Debit is returned for insufficient funds or
uncollected funds, (ii) credit Bank Account when necessary, at our sole discretion, for any refund
amount due to you, and/or (iii) to send Transactions, electronically or by any other commercially
accepted method, to the appropriate financial institution(s). If CanPay tells you that an account
number or other information concerning your Transactions has changed, you must use this corrected
information in the future to initiate Transactions.
</label>

<label>
  (g) &nbsp;We may: (i) establish security limits on Transactions, such as a maximum number or dollar
amount, (ii) change security limits from time to time without disclosing such changes, and (iii) refuse to
process your Transactions if we reasonably believe Bank Account balance is insufficient to cover the
amounts due or for any other reason we deem reasonable. We may in our sole discretion process any
Transactions that have not been settled after these Terms are terminated.
</label>

<label>
  (h) &nbsp;This ACH authorization will remain in full force and effect until we receive your written
notification of termination in such time and manner as to afford CanPay a reasonable opportunity to
act on your termination notice.
</label>

<label>
  (i) &nbsp;If any amount payable by you is dishonored or returned for any reason, such as, but not
limited to, non-sufficient funds, account closed, inability to locate account, or reversal by you and/or
your bank, CanPay may: (i) reverse any corresponding credit issued to a merchant without liability to
us, (ii) reverse a Transaction, (iii) refuse to perform further Services, (iv) initiate another debit in
substitution for the dishonored debit until the debit is honored and to initiate separate debits to cover
any NSF fee or dishonored Transaction, (v) charge you a one-time insufficient funds penalty fee for
each occurrence, (vi) report this information to any and all credit agencies and/or financial institutions,
and/or (viii) immediately terminate this Agreement.
</label>
  </div>
  </div>
</div>
</div>
   <div class="row p-3">
      <div class="col-md-12">

         <ol start="8" class= "onboarding-terms-and-condition-ol">
    <li class="onboarding-terms-and-condition-sub-two">
Transaction History.
</li>
</ol>
  <div class="onboarding-terms-and-condition-pragrapgh">
CanPay will maintain a record of your Transaction(s) made using the Services for
the previous two (2) years or such time as required by applicable law. You may access and view your
Transactions History in the CanPay App or by logging on to your Account via CanPayDebit.com.
  </div>
</div>
</div>

   <div class="row p-3">
      <div class="col-md-12">

         <ol start="9" class= "onboarding-terms-and-condition-ol">
    <li class="onboarding-terms-and-condition-sub-two">
Text Messaging.
</li>
</ol>
  <div class="onboarding-terms-and-condition-pragrapgh">
When a merchant that you interact with provides us your phone number, 
we will use that number to send you a text message to register your CanPay account.  
You agree that we may send you text messages about your use of the Services 
(such as order confirmations, purchase receipts, verification codes, and similar transactional messages); 
standard messaging and data rates apply.
  </div>
</div>
</div>

   <div class="row p-3">
      <div class="col-md-12">
         <ol start="10" class= "onboarding-terms-and-condition-ol">
    <li class="onboarding-terms-and-condition-sub-two">
Your Content.
</li>
</ol>
 <div class="onboarding-terms-and-condition-pragrapgh">
   <div class="col-md-12">
 <label>
 (a) &nbsp;<u> Posting Content.</u>&nbsp;Our Services may allow you to store or share content such as text (in posts or
communications with others), files, documents, graphics, images, music, software, audio and video.
Anything (other than Feedback) that you post or otherwise make available through the Services is
referred to as “<strong>User Content </strong>”. CanPay does not claim any ownership rights in any User Content and
nothing in these Terms will be deemed to restrict any rights that you may have to your User Content.
</label>

 <label>
 (b) &nbsp;<u> Permissions to Your User Content</u>.&nbsp;By making any User Content available through the Services
you hereby grant to CanPay a non-exclusive, transferable, worldwide, royalty-free license, with the
right to sublicense, to use, copy, modify, create derivative works based upon, distribute, publicly
display, and publicly perform your User Content in connection with operating and providing the
Services.
</label>
 <label>
 (c) &nbsp;<u> Your Responsibility for User Content</u>.&nbsp;You are solely responsible for all your User Content. You
represent and warrant that you have (and will have) all rights that are necessary to grant us the license
rights in your User Content under these Terms. You represent and warrant that neither your User
Content, nor your use and provision of your User Content to be made available through the Services,
nor any use of your User Content by CanPay on or through the Services will infringe, misappropriate or
violate a third party’s intellectual property rights, or rights of publicity or privacy, or result in the
violation of any applicable law or regulation.
</label>

 <label>
 (d) &nbsp;<u> Removal of User Content</u>.&nbsp;You can remove your User Content by specifically deleting it. You
should know that in certain instances, some of your User Content (such as posts or comments you
make) may not be completely removed and copies of your User Content may continue to exist on the
Services. To the maximum extent permitted by law, we are not responsible or liable for the removal or
deletion of (or the failure to remove or delete) any of your User Content.
</label>

 <label>
 (e) &nbsp;<u> CanPay’s Intellectual Property</u>.&nbsp;We may make available through the Services content that is
subject to intellectual property rights. We retain all rights to that content.
</label>
  </div>
  </div>
</div>
</div>

<div class="row p-3">
  <div class="col-md-12">
    <ol start="11" class="onboarding-terms-and-condition-ol">
      <li class="onboarding-terms-and-condition-sub-two"> Rights and Terms for Apps. </li>
    </ol>
    <div class="onboarding-terms-and-condition-pragrapgh">
      <div class="col-md-12">
        <label> (a) &nbsp; <u> App License</u>.&nbsp;If you comply with these Terms, CanPay grants to you a limited non-exclusive, non-transferable license, with no right to sublicense, to download and install the App on your personal computers, mobile handsets, tablets, wearable devices, and/or other devices and to run the App solely for your own personal non-commercial purposes. Except as expressly permitted in these Terms, you may not: (i) copy, modify or create derivative works based on the App; (ii) distribute, transfer, sublicense, lease, lend or rent the App to any third party; (iii) reverse engineer, decompile or disassemble the App (unless applicable law permits, despite this limitation); or (iv) make the functionality of the App available to multiple users through any means. </label>
        <label><u> <strong>Rules of Use</strong></u>.&nbsp;You must act responsibly and reasonably at all times when accessing and/or using the Services. You may only access and/or use the Services and its contents for lawful purposes and in accordance with all applicable law. You are prohibited from accessing and/or using the Services and its contents for any unlawful purposes and/or in contravention of any applicable law.  You are prohibited from uploading, storing, distributing, and/or transmitting any unlawful or inappropriate material to or through the Services. You may not upload, collect, store, distribute, or transmit personal information regarding other individuals to or through the Services. You recognize that uploading, storing, distributing, or transmitting unlawful or inappropriate material to or through the Services could expose you to criminal and/or civil liability. You agree that if a third-party claims that material you have uploaded, collected, stored, distributed, transmitted, or contributed to or through the Services is unlawful or inappropriate, you are solely responsible for that material and you will bear the burden of establishing that it is lawful and/or appropriate. You understand and agree that all material uploaded, stored, distributed, or transmitted to or through the Services is the sole responsibility of you, not CanPay, and that you are responsible for all material you upload, store, distribute, or otherwise transmit to or through the Services. </label>
        <label>Put simply, and in addition to any and all other limitations on damages, waivers, or releases – you agree that CANPAY IS NOT IN ANY WAY RESPONSIBLE OR LIABLE FOR ANY UNLAWFUL OR INAPPROPRIATE MATERIAL UPLOADED, STORED, DISRIBUTED, OR TRANSMITTED TO OR THROUGH THE SERVICES BY OR ON BEHALF OF ANY OTHER INDIVIDUAL OR ENTITY. Furthermore, you voluntarily, knowingly, and unconditionally forever, permanently, fully, completely, and finally release, acquit, remise, and discharge CanPay, its current, past and future parents, subsidiaries, affiliates, predecessors, and successors, as well as each of their members, directors, officers, employees, agents, representatives, licensors, suppliers, service providers, shareholders, attorneys, and insurers, as applicable (the “CanPay Parties”), from any and all past, present or future actions, assessments, awards,  causes of action, claims, complaints, controversies, counter-claims, cross-claims, damages (including, but not limited to, actual, statutory, compensatory, consequential, punitive, or other damages), demands, injuries (including, but not limited to bodily and personal injuries, including death), judgments, liabilities, liens, losses, penalties, property damage, sanctions, suits, and verdicts of any kind, character, or manner whatsoever, regardless of applicable law, in law (state or federal, statutory or common-law) or equity, contract, tort, or otherwise, whether contingent or fixed, direct or indirect, or derivative (collectively, “Claims”), in any way, directly or indirectly, relating to, arising from, resulting from, concerning, or caused by any (alleged) illegal or inappropriate material uploaded, stored, distributed, or transmitted to or through the Services by or on behalf of any other individual or entity.</label>
        <label>Your access and/or use of the Services is also contingent on your explicit and unconditional agreement not to engage in any of the following prohibited conduct: </label>
      </div>
    </div>
  </div>
</div>

<div class="row p-3">
      <div class="col-md-12">
         <ol start="12" class= "onboarding-terms-and-condition-ol">
    <li class="onboarding-terms-and-condition-sub-two">
Prohibitions and CanPay’s Enforcement Rights. <span>You agree not to do any of the following:</span>
</li>
</ol>
 <div class="onboarding-terms-and-condition-pragrapgh">
  <div class="col-md-12">
   <label>
 (a) &nbsp;Post, upload, publish, submit or transmit any User Content that: (i) infringes, misappropriates
or violates a third party’s patent, copyright, trademark, trade secret, moral rights or other intellectual
property rights, or rights of publicity or privacy; (ii) violates, or encourages any conduct that would
violate, any applicable law or regulation or would give rise to civil liability; (iii) is fraudulent, false,
misleading or deceptive; (iv) is defamatory, obscene, pornographic, vulgar or offensive; (v) promotes
discrimination, bigotry, racism, hatred, harassment or harm against any individual or group; (vi) is
violent or threatening or promotes violence or actions that are threatening to any person or entity; or
(vii) promotes illegal or harmful activities or substances;
</label>

   <label>
 (b) &nbsp;Use, display, mirror or frame the Services or any individual element within the Services,
CanPay’s name, any CanPay trademark, logo or other proprietary information, or the layout and design
of any page or form contained on a page, without CanPay’s express written consent;
</label>

   <label>
 (c) &nbsp;Access, tamper with, or use non-public areas of the Services, CanPay’s computer systems, or
the technical delivery systems of CanPay’s providers;
</label>

   <label>
 (d) &nbsp;Attempt to probe, scan or test the vulnerability of any CanPay system or network or breach
any security or authentication measures;
</label>

 <label>
 (e) &nbsp;Avoid, bypass, remove, deactivate, impair, descramble or otherwise circumvent any
technological measure implemented by CanPay or any of CanPay’s providers or any other third party
(including another user) to protect the Services;
</label>


 <label>
 (f) &nbsp;Attempt to access or search the Services or download content from the Services using any
engine, software, tool, agent, device or mechanism (including spiders, robots, crawlers, data mining
tools or the like) other than the software and/or search agents provided by CanPay or other generally
available third-party web browsers;
</label>

 <label>
 (g) &nbsp;Send any unsolicited or unauthorized advertising, promotional materials, email, junk mail,
spam, chain letters or other form of solicitation;
</label>

 <label>
 (h) &nbsp;Use any meta tags or other hidden text or metadata utilizing a CanPay trademark, logo URL or
product name without CanPay’s express written consent;
</label>
 <label>
 (i) &nbsp;Use the Services, or any portion thereof, for any commercial purpose or for the benefit of any third party or in any manner not permitted by these Terms; 
</label>

 <label>
 (j) &nbsp;Forge any TCP/IP packet header or any part of the header information in any email or
newsgroup posting, or in any way use the Services to send altered, deceptive or false source-
identifying information;
</label>

 <label>
 (k) &nbsp;Attempt to decipher, decompile, disassemble or reverse engineer any of the software used to
provide the Services;
</label>

 <label>
 (I) &nbsp;Interfere with, or attempt to interfere with, the access of any user, host or network, including,
without limitation, sending a virus, overloading, flooding, spamming, or mail-bombing the Services;
</label>

 <label>
 (m) &nbsp;Collect or store any personally identifiable information from the Services from other users of
the Services without their express permission;
</label>

 <label>
 (n) &nbsp;Impersonate or misrepresent your affiliation with any person or entity;
</label>

 <label>
 (o) &nbsp;Violate any applicable law or regulation; or
</label>


 <label>
 (p) &nbsp;Encourage or enable any other individual to do any of the foregoing.
</label>
  </div>
  <label>
  CanPay is not obligated to monitor access to or use of the Services or to review or edit any content. However,
we have the right to do so for the purpose of operating the Services, to ensure compliance with these Terms
and to comply with applicable law or other legal requirements. We reserve the right, but are not obligated, to
remove or disable access to any content, including User Content, at any time and without notice, including, but
not limited to, if we, at our sole discretion, consider it objectionable or in violation of these Terms. We have
the right to investigate violations of these Terms or conduct that affects the Services. We may also consult and
cooperate with law enforcement authorities to prosecute users who violate the law.
  </label>
</div>
</div>
</div>

<div class="row p-3">
    <div class="col-md-12">
        <ol start="13" class="onboarding-terms-and-condition-ol">
            <li class="onboarding-terms-and-condition-sub-two"> CanPay Spin to Win Sweepstakes Official Rules. </li>
        </ol>
        <div class="onboarding-terms-and-condition-pragrapgh">
            <label> Sponsored by Trusted Debit, LLC dba “CanPay” (“Sponsor”). NO ENTRY FEE. NO PURCHASE OR OBLIGATION NECESSARY TO ENTER OR WIN. VOID WHERE PROHIBITED. THIS PROMOTION IS IN NO WAY SPONSORED, ENDORSED OR ADMINISTERED BY, OR ASSOCIATED WITH FACEBOOK, INSTAGRAM, TWITTER OR ANY OTHER SOCIAL MEDIA SITES WHERE THIS SWEEPSTAKES MAY BE COMMUNICATED. </label>
            <label> The CanPay Spin to Win (the “Sweepstakes”) begins on March 1, 2023, at 12:00AM Pacific Time (“PT”) and will continue until such time that Sponsor terminates the Spin to Win Sweepstakes or otherwise modifies it (the “Sweepstakes Period”). Sponsor’s clock will be the official timekeeper for the Sweepstakes. </label>
            <div class="col-md-12">
                <label> 
                  1. Agreement to Official Rules. By entering or participating in the Sweepstakes, you [(and, if you are a Minor (as defined below) your parent or legal guardian)] accept and agree to be bound by these “Official Rules,” the CanPay Terms of Service, which can be found at here (the “CanPay Terms”), and all other terms associated with the CanPay Terms (both of which are herein incorporated by reference). All capitalized terms used but not defined herein shall have the respective meaning given to them in CanPay Terms. The CanPay Terms apply only to a winner’s use of the Prize after the Prize has been fulfilled by Sponsor
                </label>
                <label>
                  2. Eligibility and How to Enter. Log into your CanPay account during the Sweepstakes Period and make an Eligible Transaction. You will then have the option to click “Spin” to enter into the Sweepstakes. You will receive one instant win entry opportunity per Eligible Transaction, and will be immediately notified of a winning outcome, subject to verification of eligibility as defined in Section 7. To participate, you must agree to the CanPay Terms of Service located at here and Privacy Policy located at here.
                </label>
                <label>
                  All entries become property of Sponsor, and none will be acknowledged or returned. Entries generated by script, macro or other automated or mechanical means or with the intent to subvert the entry process are void. Entrants submitting multiple entries or violating any of these Official Rules may be disqualified. Proof of submission of an entry does not constitute proof of receipt.
                </label>
                <label>
                  3. Prize Drawing. All Sweepstakes entries meeting the requirements of these Official Rules will be immediately entered into the Sweepstakes drawing in effect at the time the entry is received as described in Section 2. The prize drawing for each Spin is provided to you immediately following the Spin. The drawing will be conducted by a computer program that randomly chooses the potential prize per Spin and all eligible entries received and the random selection is final and binding. Each winner is considered a potential winner pending verification of his/her eligibility and compliance with these Official Rules. If a Prize is won by a Minor, it will be awarded to a parent or guardian on the Minor’s behalf.
                </label>
                <label> 
                  4. Prizes and Odds. The value of Prizes are presented to you at the time of Spin or in such manner as Sponsor determines, and odds of winning are variable and provided to you at the time of the Spin in such manner as solely determined by Sponsor. All Prizes are non-transferable, and no cash or other substitution of Prizes is permitted, except at the sole discretion of Sponsor for a prize of equal or greater value. Sponsor will not replace any lost or stolen Prizes. Winners are solely responsible for any and all federal, state, provincial and local taxes, if any, that apply to Prizes. You (and, if you are a Minor, your parent or legal guardian) agree to provide Sponsor with any additional information and complete any required tax or other forms relating to your receipt of a Prize.
                </label>
                <label> 
                  5. Notification of Winners. Potential winners will be notified immediately via the CanPay account following each Spin, and as a condition of being awarded a Prize. Sponsor will attempt to notify winners as provided above, however, without limiting any other terms of these Official Rules, Sponsor assumes no responsibility for undeliverable prize notifications. If a potential winner (and if entrant is a Minor, potential winner’s parent or legal guardian, as applicable) cannot be contacted, is ineligible, fails to claim a Prize, fails to complete any required affidavit of eligibility or publicity/liability release within the days specified in the notification or thirty (30) days after such notification, whichever comes first, and/or fails to comply with any of the requirements, a Prize may be forfeited and an alternate potential winner may be randomly selected from remaining valid, eligible entries received.  
                </label>
                <label> 
                  6. Delivery of Prizes. Each winner (and, if a winner is a Minor, such winner’s parent or legal guardian) is responsible for maintain a CanPay account in good standing to ensure proper delivery of a Prize. Sponsor, without limiting any other terms of these Official Rules, assumes no responsibility for failure to deliver or otherwise make available any Prize. Prizes are delivered once the underlying CanPay enabled transaction and/or purchase with the third-party merchant is considered cleared by us (e.g., typically 5 business days) (“Eligible Transaction”). For avoidance of doubt, Eligible Transactions do not include any CanPay enabled purchase/transaction that was reversed or otherwise failed to settle using CanPay’s banking partners. If a winner does not maintain a CanPay account in good standing or does not have an Eligible Transaction, the winner will forfeit the Prize. Prizes will be transferred to the winner’s CanPay account immediately upon winner verification. Upon Prize forfeiture, or inability to use a Prize or portion thereof by an entrant, no compensation will be given, and Sponsor will have no further obligation to such entrant.
                </label>
                <label> 
                  7. Disclaimers and Limitations on Liability.
                </label>
                <div class="col-md-12">
                  <label> 
                    (a) Sponsor is not responsible for (i) late, lost, delayed, damaged, incomplete, incorrect, illegible, misdirected or undeliverable entries, responses, or other correspondence, whether by email or otherwise; (ii) theft, destruction, unauthorized access to or alterations of entry materials; or (iii) phone, electrical, network, computer, hardware, software program or transmission malfunctions, failures or difficulties or any technical hardware or software failures of any kind, which may limit a person’s ability to participate in the Sweepstakes.
                  </label>
                  <label> 
                    (b) THE PRIZES ARE PROVIDED “AS IS” WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT. IN NO EVENT WILL SPONSOR BE LIABLE TO YOU FOR ANY DIRECT, SPECIAL, INCIDENTAL, EXEMPLARY, PUNITIVE OR CONSEQUENTIAL DAMAGES (INCLUDING LOSS OF USE, DATA, BUSINESS OR PROFITS) ARISING OUT OF OR IN CONNECTION WITH YOUR PARTICIPATION IN THE SWEEPSTAKES, WHETHER SUCH LIABILITY ARISES FROM ANY CLAIM BASED UPON CONTRACT, WARRANTY, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY OR OTHERWISE, AND WHETHER OR NOT SPONSOR HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE. Some jurisdictions do not allow the limitation or exclusion of liability for incidental or consequential damages, so the above limitation or exclusion may not apply to you.
                  </label>
                </div>
                <label> 
                  8. Indemnification. You [(and, if you are a Minor, your parent or legal guardian)] agree to release, indemnify, defend and hold Sponsor and its parent, affiliates, subsidiaries, directors, officers, employees, sponsors and agents, including advertising and promotion agencies, and assigns, and any other organizations related to the Sweepstakes, harmless, from any and all claims, injuries, damages, expenses or losses to person or property and/or liabilities of any nature that in any way arise from participation in this Sweepstakes or acceptance or use of a Prize or parts thereof, including, without limitation, (i) any condition caused by events beyond Sponsor’s control that may cause the Sweepstakes to be disrupted or corrupted; (ii) a Prize, or acceptance, possession, or use of a Prize, or from participation in the Sweepstakes; and (iii) any printing or typographical errors in any materials associated with the Sweepstakes.
                </label>
                <label> 
                  9. Publicity. With your affirmative consent, we may use of your name, photo and/or likeness, biographical information, entry and statements attributed to you (if true) for advertising and promotional purposes, including without limitation, inclusion in Sponsor’s newsletters, homepages located at here , and social media channels without additional compensation. We will not otherwise use the above stated personal information to advertise or otherwise broadcast your participation in the Sweepstakes unless otherwise obligated by applicable law.
                </label>
                <label> 
                  10. Additional Conditions. Sponsor reserves the right, in its sole discretion, to terminate, modify or suspend the Sweepstakes in whole or in part, if in Sponsor’s opinion (a) the Sweepstakes is not capable of running as planned by reason of the occurrence of any event beyond its control including, but not limited to, fire, flood, epidemic, pandemic, earthquake, explosion, labor dispute or strike, act of God or public enemy, communications, equipment failure, utility or service interruptions, riot or civil disturbance, terrorist threat or activity, war (declared or undeclared), interference with the Sweepstakes by any party, or any federal, state, local or provincial government law, order, or regulation, order of any court or jurisdiction, or other cause not reasonably within Sponsor’s control, (b) any other factors including as a result of a unintentional error in design or execution of the Sweepstakes or any features thereof, and (c) beyond Sponsor’s reasonable control corrupt or affect the administration, security, fairness, integrity, or proper conduct of the Sweepstakes, in all instances without liability to the entrants. Sponsor also reserves the right to disqualify any entrant or winner, as determined by Sponsor, in its sole discretion.
                </label>
                <label> 
                  11. Governing Law and Choice of Forum. These Official Rules are governed by the laws of the State of Washington, in the United States of America without reference to rules governing choice of laws. Any action, suit or case arising out of, or in connection with, this Sweepstakes or these Official Rules must be brought in either the federal or state courts located in Washington, or the state courts located in King County, Washington.
                </label>
                <label> 
                  12. Personal Information. Sponsor may use and collect personal data about entrants in accordance with its Privacy Policy and as may be more specifically set forth in these Official Rules, including without limitation, administering and promoting the Sweepstakes for marketing purposes. Please review these Official Rules and Sponsor’s Privacy Policy here.
                </label>
                <label> 
                  Sponsor’s Email Address: Legal@canpaydebit.com
                </label>
            </div>
        </div>
    </div>
</div>
<div class="row p-3">
  <div class="col-md-12">
    <ol start="14" class="onboarding-terms-and-condition-ol">
      <li class="onboarding-terms-and-condition-sub-two"> Links to Third Party Websites or Resources. </li>
    </ol>
    <div class="onboarding-terms-and-condition-pragrapgh">
      <label> The Services (including the App) may allow you to access third-party websites or other resources. We provide access only as a convenience and are not responsible for the content, products or services on or available from those resources or links displayed on such websites. You acknowledge sole responsibility for and assume all risk arising from, your use of any third-party resources. </label>
    </div>
  </div>
</div>
<div class="row p-3">
  <div class="col-md-12">
    <ol start="15" class="onboarding-terms-and-condition-ol">
      <li class="onboarding-terms-and-condition-sub-two"> Automated Decision-Making. </li>
    </ol>
    <div class="onboarding-terms-and-condition-pragrapgh">
      <label> Refers to a decision which is taken solely on the basis of automated processing of your personal data. This means processing using, for example, software code or an algorithm, which does not require human intervention. </label>
      <label> Subject to applicable law, and as further addressed in the Privacy Policy, we use artificial intelligence and/or machine learning software that assesses your perceived financial ability to pay to help us determine your eligibility to access and/or use the Services and what limits to place on your ability to transact on the Services. This information may negatively affect, restrict, or prevent you from using the Services. Depending on where you live, you may have certain rights to opt out of such automated decision-making processing.  For more details on your rights, please see our Privacy Policy. </label>
    </div>
  </div>
</div>
<div class="row p-3">
  <div class="col-md-12">
    <ol start="16" class="onboarding-terms-and-condition-ol">
      <li class="onboarding-terms-and-condition-sub-two"> No Financial, Investment, Legal, Accounting, or Tax Advice. </li>
    </ol>
    <div class="onboarding-terms-and-condition-pragrapgh">
      <label> The Services does not and should not be construed to constitute or provide any financial, investment, legal, accounting, tax, or other advice. To the contrary, CanPay does not provide you any financial, legal, accounting, tax, or other advice. You shall not rely upon CanPay or the Services, or otherwise access and/or use the Services, in connection with making or evaluating any financial, investment, legal, accounting, tax, or other decisions. We urge you to consult an experienced financial, investment, legal, accounting, or tax advisor with respect to any such issues. In addition, nothing available through the Services is an offer to sell, or a solicitation of any offer to buy, any financial products. Likewise, no content of the Services should be used as the basis of any investment or other financial decision. </label>
    </div>
  </div>
</div>
<div class="row p-3">
  <div class="col-md-12">
    <ol start="17" class="onboarding-terms-and-condition-ol">
      <li class="onboarding-terms-and-condition-sub-two"> Investigations. </li>
    </ol>
    <div class="onboarding-terms-and-condition-pragrapgh">
      <label> CanPay reserves the right to investigate suspected violations of the Terms, including, without limitation, any suspected violation arising from any submission, posting, or e-mails you make or send. CanPay may seek to gather information from any user who is suspected of violating the Terms, and from any other individuals or entities as it alone deems necessary and appropriate.  CanPay in its sole and absolute discretion may suspend any user whose conduct and/or postings are under investigation and may remove such material from its servers as it, in its sole and absolute discretion, deems appropriate and without notice. </label>
      <label> If CanPay believes, in its sole and absolute discretion, that a violation of  the Terms has occurred, it may edit or modify any submission, posting, or e-mails, remove the material permanently from the Services, cancel postings, warn users, suspend users and/or passwords, disable or terminate accounts, and/or take other corrective action, including referral to law enforcement, that it, in its sole and absolute discretion, deems appropriate. As more fully addressed in the Privacy Policy, CanPay will fully cooperate with any law enforcement, regulatory, or other government authorities or court order requesting or directing CanPay to disclose the identity of anyone posting any e-mails, or publishing or otherwise making available any materials that are believed to violate the Terms, or for any other reason. </label>
      <label> By accessing and/or using the Services and accepting the Terms,  you voluntarily, knowingly, and unconditionally forever, permanently, fully, completely, and finally release, acquit, remise, and discharge the CanPay Parties, from any and all Claims in any way, directly or indirectly, relating to, arising from, resulting from, concerning, or caused by any (alleged) investigation and/or any actions taken as a consequence of any investigation. </label>
    </div>
  </div>
</div>

<div class="row p-3">
  <div class="col-md-12">
    <ol start="18" class="onboarding-terms-and-condition-ol">
      <li class="onboarding-terms-and-condition-sub-two"> Termination. </li>
    </ol>
    <div class="onboarding-terms-and-condition-pragrapgh">
      <label> We may suspend or terminate your access to and use of the Services, including suspending access to or terminating your account, at our sole discretion, at any time and without notice to you. You may cancel your account at any time by logging into your Account and doing it yourself (via the App) or by sending us an email at <a class="onboarding-terms-and-condition-ancher" href="mailto:canpay@canpaydebit.com">canpay@canpaydebit.com</a>.]. Upon any termination, discontinuation or cancellation of the Services or your account, the following Sections will survive: </label>
    </div>
  </div>
</div>

<div class="row p-3">
  <div class="col-md-12">
    <ol start="19" class="onboarding-terms-and-condition-ol">
      <li class="onboarding-terms-and-condition-sub-two"> Warranty Disclaimers. </li>
    </ol>
    <div class="onboarding-terms-and-condition-pragrapgh">
      <label> THE SERVICES AND SERVICES ARE MADE AVAILABLE TO YOU ON AN “AS IS,” “WHERE IS,” AND “WHERE AVAILABLE” BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, WHETHER EXPRESS, IMPLIED, OR  STATUTORY. WITHOUT LIMITING THE FOREGOING, CANPAY EXPLICITLY DISCLAIMS ALL WARRANTIES WITH RESPECT TO THE SERVICES, INCLUDING THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, AND TITLE. ALL DISCLAIMERS OF ANY KIND ARE MADE FOR THE BENEFIT OF THE CANPAY PARTIES. WHILE WE TRY TO MAINTAIN THE INTEGRITY AND SECURITY OF THE SERVICES, WE DO NOT GUARANTEE THE SERVICES IS OR WILL REMAIN COMPLETE, CORRECT, OR SECURE, OR THAT ACCESS TO THE SERVICES WILL BE UNINTERRUPTED. </label>
      <label> Put simply, and in addition to any and all other limitations on damages, waivers, or releases, you agree that CANPAY IS NOT IN ANY WAY RESPONSIBLE OR ANY FAILURE TO MAINATIN THE INTEGRITY AND SECURITY OF THE SERVICES, OR TO KEEP THE SERVICES COMPLETE, CORRECT, OR SECURE, OR TO ENSURE UNINTERRUPTED ACCESS TO THE SERVICES. Furthermore, you voluntarily, knowingly, and unconditionally forever, permanently, fully, completely, and finally release, acquit, remise, and discharge the CanPay Parties from any and all Claims in any way, directly or indirectly, relating to, arising from, resulting from, concerning, or caused by any (alleged) failure (i) to maintain the integrity and/or security of the Services; (ii) to keep the Services complete, correct, or secure; and/or (iii) to ensure uninterrupted access to the Services. </label>
    </div>
  </div>
</div>


<div class="row p-3">
  <div class="col-md-12">
    <ol start="20" class="onboarding-terms-and-condition-ol">
      <li class="onboarding-terms-and-condition-sub-two"> Indemnity. </li>
    </ol>
    <div class="onboarding-terms-and-condition-pragrapgh">
      <label> You will indemnify and hold CanPay and its officers, directors, employees and agents, harmless from and against any claims, disputes, demands, liabilities, damages, losses, and costs and expenses, including, without limitation, reasonable legal and accounting fees arising out of or in any way connected with (a) your access to or use of the Services, (b) your User Content, or (c) your violation of these Terms. </label>
    </div>
  </div>
</div>

<div class="row p-3">
  <div class="col-md-12">
    <ol start="21" class="onboarding-terms-and-condition-ol">
      <li class="onboarding-terms-and-condition-sub-two"> Limitation of Liability. </li>
    </ol>
    <div class="onboarding-terms-and-condition-pragrapgh">
      <div class="col-md-12">
        <label> (a) &nbsp; <u>Limitation of Liability.</u>&nbsp;IN NO EVENT SHALL CANPAY BE LIABLE TO YOU, YOUR ORGANIZATION OR INDIVIDUAL, ANY USER, OR ANY THIRD PARTY IN CONNECTION WITH THIS AGREEMENT OR THE SERVICE, FOR ANY INCIDENTAL, SPECIAL, EXEMPLARY OR CONSEQUENTIAL DAMAGES, INCLUDING LOSS OF INCOME, DATA, PROFITS, REVENUE OR BUSINESS INTERRUPTION, OR COST OF SUBSTITUTE SERVICES, OR OTHER ECONOMIC LOSS, WHETHER OR NOT CANPAY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, AND WHETHER ANY CLAIM FOR RECOVERY IS BASED ON THEORIES OF CONTRACT, WARRANTY, TORT (INCLUDING NEGLIGENCE AND STRICT LIABILITY) OR OTHERWISE.  CANPAY WILL NOT BE LIABLE FOR ATTORNEYS’ FEES, EXCEPT AS REQUIRED BY LAW. </label>
        <label> NOTWITHSTANDING ANY OTHER PROVISIONS OF THIS AGREEMENT, IN NO EVENT SHALL CANPAY’S AGGREGATE LIABILITY TO YOU, YOUR ORGANIZATION OR INDIVIDUAL, ANY USER, OR ANY THIRD PARTY IN CONNECTION WITH THIS AGREEMENT OR THE SERVICE EXCEED THE TOTAL FEES PAID BY YOU OR YOUR ORGANIZATION OR INDIVIDUAL TO CANPAY IN THE TWELVE MONTH PERIOD PRECEDING THE EVENT(S) THAT GAVE RISE TO SUCH LIABILITY, OR ONE HUNDRED USD ($100.00), WHICHEVER IS GREATER, REGARDLESS OF THE FORM OR THEORY OF THE CLAIM OR ACTION. </label>
        <label> Each provision of this Agreement that provides for a limitation of liability, disclaimer of warranties, or exclusion of damages is intended to and does allocate the risks between the parties under this Agreement. Each of these provisions is severable and independent of all other provisions of this Agreement. All limitations of liability, disclaimers of warranties, and exclusions of consequential damages or other damages or remedies will remain fully valid, effective and enforceable in accordance with their respective terms, even under circumstances that cause an exclusive remedy to fail of its essential purpose.  SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF DAMAGES, LIABILITY OR CERTAIN WARRANTIES IN CERTAIN CIRCUMSTANCES. ACCORDINGLY, IN THOSE JURISDICTIONS, SOME OF THESE EXCLUSIONS OR LIMITATIONS MAY NOT APPLY. </label>
      </div>
    </div>
  </div>
</div>

<div class="row p-3">
  <div class="col-md-12">
    <ol start="22" class="onboarding-terms-and-condition-ol">
      <li class="onboarding-terms-and-condition-sub-two"> Governing Law and Forum Choice. </li>
    </ol>
    <div class="onboarding-terms-and-condition-pragrapgh">
      <label> These Terms and any action related thereto will be governed by the Federal Arbitration Act, federal arbitration law, and the laws of the State of Washington, without regard to its conflict of laws provisions. Except as otherwise expressly set forth in Section 22 “Dispute Resolution,” the exclusive jurisdiction for all Disputes (defined below) that you and CanPay are not required to arbitrate will be the state and federal courts located in the United States, and you and CanPay each waive any objection to jurisdiction and venue in such courts. </label>
    </div>
  </div>
</div>

<div class="row p-3">
    <div class="col-md-12">
      <ol start="23" class="onboarding-terms-and-condition-ol">
        <li class="onboarding-terms-and-condition-sub-two"> Dispute Resolution. </li>
      </ol>
      <div class="onboarding-terms-and-condition-pragrapgh">
        <div class="col-md-12">
          <label> (a) &nbsp; <u> Mandatory Arbitration of Disputes.</u>&nbsp;We each agree that any dispute, claim or controversy arising out of or relating to these Terms or the breach, termination, enforcement, interpretation or validity thereof or the use of the Services (collectively, “<strong>Disputes</strong>”) will be resolved <strong> solely by binding, individual arbitration and not in a class, representative or consolidated action or proceeding.</strong> You and CanPay agree that the U.S. Federal Arbitration Act governs the interpretation and enforcement of these Terms, and that you and CanPay are each waiving the right to a trial by jury or to participate in a class action. This arbitration provision shall survive termination of these Terms. </label>
          <label> (b) &nbsp; <u> Exceptions.</u>&nbsp;As limited exceptions to Section 22(a) above: (i) either party may seek to resolve a Dispute in small claims court if it qualifies; and (ii) we each retain the right to seek injunctive or other equitable relief from a court to prevent (or enjoin) the infringement or misappropriation of our intellectual property rights. </label>
          <label> (c) &nbsp; <u> Conducting Arbitration and Arbitration Rules</u>.&nbsp;The arbitration will be conducted by the American Arbitration Association (“ <strong>AAA</strong>”) under its Consumer Arbitration Rules (the “AAA Rules”) then in effect, except as modified by these Terms. The AAA Rules are available at <a class="onboarding-terms-and-condition-ancher" href="https://www.adr.org" target="_blank">www.adr.org</a>. or by calling 1-800-778-7879. A party who wishes to start arbitration must submit a written Demand for Arbitration to AAA and give notice to the other party as specified in the AAA Rules. The AAA provides a form Demand for Arbitration at <a class="onboarding-terms-and-condition-ancher" href="https://www.adr.org" target="_blank">www.adr.org</a>. </label>
          <label> Any arbitration hearings will take place in the county (or parish) where you live, unless we both agree to a different location. The parties agree that the arbitrator shall have exclusive authority to decide all issues relating to the interpretation, applicability, enforceability and scope of this arbitration agreement. </label>
          <label> (d) &nbsp; <u> Arbitration Costs</u>.&nbsp;Transaction of all filing, administration and arbitrator fees will be governed by the AAA Rules, and we won’t seek to recover the administration and arbitrator fees we are responsible for paying, unless the arbitrator finds your Dispute frivolous. If we prevail in arbitration, we’ll pay all of our attorneys’ fees and costs and won’t seek to recover them from you. If you prevail in arbitration you will be entitled to an award of attorneys’ fees and expenses to the extent provided under applicable law. </label>
          <label> (e) &nbsp; <u> Injunctive and Declaratory Relief</u>.&nbsp;Except as provided in Section 22(b) above, the arbitrator shall determine all issues of liability on the merits of any claim asserted by either party and may award declaratory or injunctive relief only in favor of the individual party seeking relief and only to the extent necessary to provide relief warranted by that party&#39;s individual claim. To the extent that you or we prevail on a claim and seek public injunctive relief (that is, injunctive relief that has the primary purpose and effect of prohibiting unlawful acts that threaten future injury to the public), the entitlement to and extent of such relief must be litigated in a civil court of competent jurisdiction and not in arbitration. The parties agree that litigation of any issues of public injunctive relief shall be stayed pending the outcome of the merits of any individual claims in arbitration. </label>
          <label> (f) &nbsp; <u> Class Action Waiver</u>.&nbsp; <strong>YOU AND CANPAY AGREE THAT EACH MAY BRING CLAIMS AGAINST THE OTHER ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING.</strong> Further, if the parties’ dispute is resolved through arbitration, the arbitrator may not consolidate another person&#39;s claims with your claims, and may not otherwise preside over any form of a representative or class proceeding. If this specific provision is found to be unenforceable, then the entirety of this Dispute Resolution section shall be null and void. </label>
          <label> (g) &nbsp; <u> Severability.</u>.&nbsp;>With the exception of any of the provisions in Section 22(e) of these Terms (&quot;Class <strong>Action Waiver </strong>&quot;), if an arbitrator or court of competent jurisdiction decides that any part of these Terms is invalid or unenforceable, the other parts of these Terms will still apply. </label>
        </div>
      </div>
    </div>
</div>



<div class="row p-3">
    <div class="col-md-12">
      <ol start="24" class="onboarding-terms-and-condition-ol">
        <li class="onboarding-terms-and-condition-sub-two"> General Terms. </li>
      </ol>
      <div class="onboarding-terms-and-condition-pragrapgh">
        <div class="col-md-12">
          <label> (a) &nbsp; <u> Reservation of Rights.</u>&nbsp;CanPay and its licensors exclusively own all right, title and interest in and to the Services, including all associated intellectual property rights. You acknowledge that the Services are protected by copyright, trademark, and other laws of the United States and foreign countries. You agree not to remove, alter or obscure any copyright, trademark, service mark or other proprietary rights notices incorporated in or accompanying the Services. </label>
          <label> (b) &nbsp; <u> Entire Agreement.</u>&nbsp;These Terms constitute the entire and exclusive understanding and agreement between CanPay and you regarding the Services, and these Terms supersede and replace all prior oral or written understandings or agreements between CanPay and you regarding the Services. If any provision of these Terms is held invalid or unenforceable by an arbitrator or a court of competent jurisdiction, that provision will be enforced to the maximum extent permissible and the other provisions of these Terms will remain in full force and effect. You may not assign or transfer these Terms, by operation of law or otherwise, without CanPay’s prior written consent. Any attempt by you to assign or transfer these Terms, without such consent, will be null. CanPay may freely assign or transfer these Terms without restriction. Subject to the foregoing, these Terms will bind and inure to the benefit of the parties, their successors and permitted assigns. </label>
          <label> (c) &nbsp; <u> Notices.</u>&nbsp;Any notices or other communications provided by CanPay under these Terms will be given: (i) via email; or (ii) by posting to the Services. For notices made by email, the date of receipt will be deemed the date on which such notice is transmitted. </label>
          <label> (d) &nbsp; <u> Waiver of Rights.</u>&nbsp;CanPay’s failure to enforce any right or provision of these Terms will not be considered a waiver of such right or provision. The waiver of any such right or provision will be effective only if in writing and signed by a duly authorized representative of CanPay. Except as expressly set forth in these Terms, the exercise by either party of any of its remedies under these Terms will be without prejudice to its other remedies under these Terms or otherwise. </label>
        </div>
      </div>
    </div>
</div>


<div class="row p-3">
  <div class="col-md-12">
    <ol start="25" class="onboarding-terms-and-condition-ol">
      <li class="onboarding-terms-and-condition-sub-two"> Contact Information. </li>
    </ol>
    <div class="onboarding-terms-and-condition-pragrapgh">
      <label> If you have any questions about these Terms or the Services, please contact CanPay at <a class="onboarding-terms-and-condition-ancher" href="mailto:legal@canpaydebit.com">legal@canpaydebit.com </a> or 1-877-564-5174 or 3040 78th Ave SE, #856, Mercer Island WA 98040. </label>
    </div>
  </div>
</div>

</br>
</br>
  </div>
</template>
<script>
export default {
  name: "termsandconditions",
  data() {
    return {};
  },
  methods: {
    redirectToPrivacyPolicy() {
      this.$router.push("/privacypolicy");
    }
  },
  mounted() {
    document
      .getElementsByClassName("content-wrap")[0]
      .style.setProperty("background-color", "#ffffff");
    this.$root.$emit("loginapp", [""]);
    this.$root.$emit("changeWhiteBackground", [
      true,
      false,
      "TermsandConditionHeader"
    ]);
  }
};
</script>