<template>
    <div class="pb-2">
        <div >
            <p style="padding:  14px 17px 10px 17px;" :id="'range_text_'+ownedPoints" class="cp-point-convertion-text text-left mb-0"><span>Up to <strong class="text-white value">${{amountNumberFormatter(value*exchangeRate)}}</strong> will be paid with {{parseInt(selectedPoints) > 1 ? 'points' : 'points'}} </span></p>

            <div style="padding: 10px 17px 7px 17px;" class="row justify-content-between mx-2 margin-bottom-reduce">
              <span class="cp-range-text ml-4">0</span>
              <span class="cp-range-text mr-3">{{pointNumberFormatter(ownedPoints)}}</span>
            </div>


            <div v-if="parseInt(ownedPoints) >= parseInt(minimumRedeemPoints)" style="padding: 0px 17px;" class="d-flex align-items-center justify-content-between">
              <div style="margin-left: -7px;" @click="decreasePoint()" v-longclick="() => longPressDecreasePoint()" class="point-navigate-btn">
                <svg width="32" height="6" viewBox="0 0 32 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect y="6" width="6" height="32" rx="3" transform="rotate(-90 0 6)" fill="white"/>
                </svg>
              </div>
              <div class="cp-range-slider">
                <CustomSlider
                v-model="value"
                :total-points="parseInt(ownedPoints)"
                :merchant-points="parseInt(merchantPoints)"
                :generic-points="parseInt(genericPoints)"
                :minimum-redeem-points="parseInt(minimumRedeemPoints)"
                :is-merchant-available="parseInt(merchantPoints) >= parseInt(minimumRedeemPoints) ? true : false"
                @input="selectPrice"
                @change="slideChange($event)"  
                />
              </div>
              <div style="margin-right: -7px;" @click="increasePoint()" v-longclick="() => longPressIncreasePoint()" class="point-navigate-btn">
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="13" width="6" height="32" rx="3" fill="white"/>
                <rect y="19" width="6" height="32" rx="3" transform="rotate(-90 0 19)" fill="white"/>
                </svg>
              </div>
            </div>

            <div v-else style="padding: 0px 17px;" class="d-flex align-items-center justify-content-between mt-2">
              <div class="point-navigate-btn disabled">
                <svg width="32" height="6" viewBox="0 0 32 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect y="6" width="6" height="32" rx="3" transform="rotate(-90 0 6)" fill="white"/>
                </svg>
              </div>
              <div class="cp-range-slider-disabled">
                <div class="cp-slider-disabled"></div>
                <div class="slider-range-disabled-1"></div>
                <div class="slider-range-disabled-2"></div>
                <div class="slider-range-disabled-3"></div>
                <div class="slider-range-disabled-4"></div>
              </div>
              <div class="point-navigate-btn disabled">
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="13" width="6" height="32" rx="3" fill="white"/>
                <rect y="19" width="6" height="32" rx="3" transform="rotate(-90 0 19)" fill="white"/>
                </svg>
              </div>
            </div>
            <!-- NEED LATER  -->
            <!-- <div style="padding: 10px 17px 5px 17px;" class="rw-point-checkbox">
              <div class="rw-point-card merchant">
                <div :class="parseInt(ownedPoints) >= parseInt(minimumRedeemPoints) ? 'rw-point mr-2' : 'rw-point disabled mr-2'"></div>
                <div>Alternative Compassion Center Points: <strong>{{ merchantPoints }}</strong></div>
              </div>
            </div> -->

            <div style="padding: 5px 17px 10px 17px;" class="rw-point-checkbox mt-2">
              <div class="rw-point-card generic">
                <div :class="parseInt(ownedPoints) >= parseInt(minimumRedeemPoints) ? 'rw-point mr-2' : 'rw-point disabled mr-2'"></div>
                <span>CanPay Points: <strong>{{pointNumberFormatter(genericPoints)}}</strong></span>
              </div>
            </div>
        </div> 
    </div>
</template>

<script>
import Vue from 'vue'

import CustomSlider from './CustomSlider.vue';

export default {
    props:{
        value: Number,
        ownedPoints: {
            type: Number,
            default: 0
        },
        disabled:{
          type: Boolean,
          default: false
        },
        exchangeRate: {
            type: Number,
            default: 0
        },
        minimumRedeemPoints: {
            type: Number,
            default: 0
        },
        genericPoints: {
            type: Number,
            default: 0
        },
        merchantPoints: {
            type: Number,
            default: 0
        },
    },
    components: {
      CustomSlider
    },
    directives:{
      'longclick': {
        bind: function (el, binding, vNode) {
          if (typeof binding.value !== 'function') {
            const compName = vNode.context.name
            let warn = `[longclick:] provided expression '${binding.expression}' is not a function, but has to be`
            if (compName) { warn += `Found in component '${compName}' ` }
            console.warn(warn) // eslint-disable-line
          }

          let pressTimer = null
          let pressInterval = null

          const start = (e) => {

            if (e.type === 'click' && e.button !== 0) {
              return
            }

            if (pressTimer === null) {
              pressTimer = setTimeout(() => {
                if (50 && 50 > 0) {
                  pressInterval = setInterval(() => {
                    handler()
                  }, 40)
                }
                handler()
              }, 400)
            }
          }

          // Cancel Timeout
          const cancel = () => {
            if (pressTimer !== null) {
              clearTimeout(pressTimer)
              pressTimer = null
            }
            if (pressInterval) {
              clearInterval(pressInterval)
              pressInterval = null
              vNode.context.onReleaseLongPress()
            }
          }
          // Run Function
          const handler = (e) => {
            binding.value(e)
          }

          ;['mousedown', 'touchstart'].forEach(e => el.addEventListener(e, start))
          ;['click', 'touchend', 'touchcancel'].forEach(e => el.addEventListener(e, cancel))
        }
      }
    },  
    data(){
        return{
            selectedPoints: 0,
            minimumSpendPoints: null,
            minimumRedeemValue: 0,
            selectedAmount: 0,
            totalSelectedPoint: 0,
            pressingTimeInseconds: 0,
            maxSelectionPoint: 0,
        }
    },
    mounted(){
        var self = this;
        self.minimumSpendPoints = self.value
        self.selectedPoints = self.value
        self.minimumRedeemValue = self.minimumRedeemPoints

        self.maxSelectionPoint = parseInt(self.ownedPoints/self.minimumRedeemPoints) * self.minimumRedeemPoints
    },
    methods:{
        decreasePoint(){
          this.totalSelectedPoint = 0
          this.totalSelectedPoint = parseFloat(this.value) - parseFloat(this.minimumRedeemPoints)
          if(this.totalSelectedPoint < 0){
            this.$emit('input', 0);
            this.$emit('change', parseInt(0));
            return
          }
          this.selectedPoints = this.totalSelectedPoint
          this.$emit('input', this.totalSelectedPoint);
          this.$emit('change', parseInt(this.totalSelectedPoint));
        },
        longPressDecreasePoint(){

          this.pressingTimeInseconds += 1

          this.totalSelectedPoint = 0
          if(this.pressingTimeInseconds < 30){
            this.totalSelectedPoint = parseFloat(this.value) - parseFloat(this.minimumRedeemPoints)
          }else if(this.pressingTimeInseconds >= 30 && this.pressingTimeInseconds < 80){
            this.totalSelectedPoint = parseFloat(this.value) - (parseFloat(this.minimumRedeemPoints)*50)
          }else if(this.pressingTimeInseconds >= 80 && this.pressingTimeInseconds < 130){
            this.totalSelectedPoint = parseFloat(this.value) - (parseFloat(this.minimumRedeemPoints)*100)
          }else if(this.pressingTimeInseconds >= 130){
            this.totalSelectedPoint = parseFloat(this.value) - (parseFloat(this.minimumRedeemPoints)*150)
          }
          
          if(this.totalSelectedPoint < 0){
            this.$emit('input', 0);
            this.totalSelectedPoint = 0
            return
          }
          this.selectedPoints = this.totalSelectedPoint
          this.$emit('input', this.totalSelectedPoint);
        },
        increasePoint(){
          this.totalSelectedPoint = 0
          this.totalSelectedPoint = parseFloat(this.value) + parseFloat(this.minimumRedeemPoints)
          if(this.totalSelectedPoint > this.ownedPoints){
            this.totalSelectedPoint = parseFloat(this.totalSelectedPoint) - parseFloat(this.minimumRedeemPoints)
            return
          }
          this.selectedPoints = this.totalSelectedPoint
          this.$emit('input', parseInt(this.totalSelectedPoint));
          this.$emit('change', parseInt(this.totalSelectedPoint));
        },
        longPressIncreasePoint(){

          this.pressingTimeInseconds += 1

          this.totalSelectedPoint = 0
          if(this.pressingTimeInseconds < 30){
            this.totalSelectedPoint = parseFloat(this.value) + parseFloat(this.minimumRedeemPoints)
          }else if(this.pressingTimeInseconds >= 30 && this.pressingTimeInseconds < 80){
            this.totalSelectedPoint = parseFloat(this.value) + (parseFloat(this.minimumRedeemPoints)*50)
          }else if(this.pressingTimeInseconds >= 80 && this.pressingTimeInseconds < 130){
            this.totalSelectedPoint = parseFloat(this.value) + (parseFloat(this.minimumRedeemPoints)*100)
          }else if(this.pressingTimeInseconds >= 130){
            this.totalSelectedPoint = parseFloat(this.value) + (parseFloat(this.minimumRedeemPoints)*150)
          }else{
            this.totalSelectedPoint = parseFloat(this.value) + parseFloat(this.minimumRedeemPoints)
          }

          if(this.totalSelectedPoint > this.ownedPoints){
            this.totalSelectedPoint = parseFloat(this.maxSelectionPoint)
            this.$emit('input', parseInt(this.totalSelectedPoint));
            this.selectedPoints = this.totalSelectedPoint
            return
          }
          this.selectedPoints = this.totalSelectedPoint
          this.$emit('input', parseInt(this.totalSelectedPoint));
        },
        selectPrice(event){
          var self = this;
          self.selectedPoints = event
          self.$emit('input', parseInt(event));
          self.selectedAmount = event*self.exchangeRate
        },
        slideChange: function(event) {
            var self = this;
            self.$emit('input', parseInt(event));
            self.$emit('change', parseInt(event));
            self.selectedAmount = event*self.exchangeRate
        },
        onReleaseLongPress() {
          this.pressingTimeInseconds = 0
          this.$emit('change', parseInt(this.totalSelectedPoint));
        }
    }
}
</script>


<style lang="scss">
.cp-range-slider{
  width: 94%;
  display: flex;
  align-items: center;
  position: relative;
}

.cp-slider-disabled{
  -webkit-appearance: none;
  width: 100%;
  height: 20px;
  background: linear-gradient(180.01deg, rgba(0, 0, 0, 0.29) 0.01%, rgba(0, 126, 229, 0) 96.86%)!important;
  background-color: #EFEFEF!important;
  outline: none;
  -webkit-transition: .2s;
  transition: opacity .2s;
  border: 1px solid #000;
  border-radius: 50px;
  cursor: no-drop;
}
.cp-range-slider-disabled{
  width: 90%;
  display: flex;
  align-items: center;
  position: relative;
}
.slider-range-disabled-1{
  width: 2px;
  height: 8px;
  background-color: black;
  left: 20%;
  position: absolute;
  bottom: 0;
}
.slider-range-disabled-2{
  width: 2px;
  height: 8px;
  background-color: black;
  left: 40%;
  position: absolute;
  bottom: 0;
}
.slider-range-disabled-3{
  width: 2px;
  height: 8px;
  background-color: black;
  left: 60%;
  position: absolute;
  bottom: 0;
}
.slider-range-disabled-4{
  width: 2px;
  height: 8px;
  background-color: black;
  left: 80%;
  position: absolute;
  bottom: 0;
}



.cp-range-text{
  color: #ffffff;
  font-size: 13px !important;
  font-weight: 600;
}
.point-navigate-btn{
  background-color: #0e5e2d;
  border: 0;
  width: 25px;
  height: 25px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px;
  cursor: pointer;
}
.point-navigate-btn.disabled{
  cursor: no-drop;
}
.rw-point-checkbox{
  display: flex;
  justify-content: center;
}
.rw-point-card{
  width: 92%;
  display: flex;
}
.rw-point-card.generic{
  font-size: 13px;
  display: flex;
  align-items: center;
}
.rw-point-card.generic .rw-point{
  width: 20px;
  height: 20px;
  border-radius: 6px;
  background-color: #007ee5;
  border: 1px solid #000;
}
.rw-point-card.merchant{
  font-size: 13px;
  display: flex;
  align-items: center;
}
.rw-point-card.merchant .rw-point{
  width: 20px;
  height: 20px;
  border-radius: 6px;
  background-color: #fff;
  border: 1px solid #000;
}
.rw-point.disabled{
  width: 20px;
  height: 20px;
  border-radius: 6px;
  background-color: #C7D0D7!important;
  border: 1px solid #000;
}
.cp-range-disabled-input{
  background-color: #ACACAC;
  height: 20px;
  width: 100%;
  border: 1px solid #000;
  border-radius: 5px;
  cursor: no-drop;
}
.margin-bottom-reduce{
  margin-bottom: -5px;
}
.merchant-points-range{
  width: 20%;
  height: 20px;
  position: absolute;
  background: white;
  z-index: 1;
  border-radius: 5px 0 0 5px;
  border-left: 1px solid #000;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
}
@media only screen and ( min-width:280px) and ( max-width:700px) {
  .cp-range-slider {
    width: 80%;
  }
  .cp-range-slider-disabled{
    width: 80%;
  }
  .rw-point-card{
    width: 80%;
  }
  .rw-point-card.generic{
    font-size: 8px;
  }
  .rw-point-card.merchant{
    font-size: 8px;
  }
}
@media only screen and ( min-width:320px) and ( max-width:700px) {
  .cp-range-slider {
    width: 80%;
  }
  .cp-range-slider-disabled{
    width: 80%;
  }
  .rw-point-card{
    width: 80%;
  }
  .rw-point-card.generic{
    font-size: 9px;
  }
  .rw-point-card.merchant{
    font-size: 9px;
  }
}
@media only screen and ( min-width:376px) and ( max-width:800px) {
  .cp-range-slider {
    width: 84%;
  }
  .cp-range-slider-disabled{
    width: 80%;
  }
  .rw-point-card{
    width: 84%;
  }
  .rw-point-card.generic{
    font-size: 11px;
  }
  .rw-point-card.merchant{
    font-size: 11px;
  }
}
</style>